* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.register_logistic-officer {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register_logistic-officer > .header {
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.register_logistic-officer > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.register_logistic-officer > .header > .icon {
  cursor: pointer;
}

.register_logistic-officer > .form {
  width: 98%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px;
}

.register_logistic-officer > .form > label {
  display: flex;
  flex-direction: column;
  width: 320px;
  gap: 10px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.register_logistic-officer > .form > label > input {
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}

.register_logistic-officer > .form > label > .phone-input {
  display: flex;
}
.register_logistic-officer > .form > label > .phone-input > input {
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  gap: 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #e0e0e0;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}

.register_logistic-officer > .form > label > .phone-input > select {
  display: flex;
  width: 70px;
  height: 42px;
  border: 1px solid #e0e0e0;
  font-family: Montserrat;
}

.register_logistic-officer > .form > label > select {
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}

.register_logistic-officer > .form > button {
  width: 320px;
  display: inline-flex;
  height: 43px;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 66px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  cursor: pointer;
}

.register_logistic-officer > .form > p {
  font-family: Montserrat;
  color: #d0300e;
}

.register_logistic-officer > .form > span {
  font-family: Montserrat;
  color: green;
}
