* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.user-dropdown-card {
  width: 232px;
  top: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  right: 10%;
  position: absolute;
  padding: 15px 0px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.user-dropdown-card > a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid #e0e0e0;
}

.user-dropdown-card > a > h3 {
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffcd00;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.user-dropdown-card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 0px 10px;
}

.user-dropdown-card > span > a {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat;
  font-weight: 400;
  gap: 10px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.user-dropdown-card > button {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  color: #d0300e;
  font-family: Montserrat;
  font-weight: 600;
  cursor: pointer;
  border-top: 1px solid #e0e0e0;
}

.user-profile-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 90px 200px 20px 200px;
}

.user-profile-con {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.user-profile-con > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.user-profile-con > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  margin-top: 30px;
}

.user-profile-con > .wrapper > .card-1 {
  width: 100%;
  height: 214px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  gap: 15px;
  padding: 10px 0px;
}

.user-profile-con > .wrapper > .card-1 > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-left: 15px;
}


.user-profile-con > .wrapper > .card-1 > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.user-profile-con > .wrapper > .card-1 > h3 {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px 10px 15px;
  border-bottom:  1px solid #e0e0e0;
}


.user-profile-con > .wrapper > .card-1 > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
}


.user-profile-con > .wrapper > .card-2 {
  width: 100%;
  height: 214px;  
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 10px 0px;
}

.user-profile-con > .wrapper > .card-2 > h3 {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px 10px 15px;
  border-bottom:  1px solid #e0e0e0;
}

.user-profile-con > .wrapper > .card-2 > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
}

.user-profile-con > .wrapper > .card-2 > span > h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.user-profile-con > .wrapper > .card-2 > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.user-profile-con > .wrapper > .card-2 > span > a {
  text-decoration: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffcd00;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}


@media screen and (max-width:768px) {
  .user-profile-container {
    padding: 160px 10px 10px 10px;
  }

  .user-profile-con  {
    width: 100%;
    padding: 15px;
  }
  .user-profile-con > .wrapper {
    flex-direction: column;
  }

  .user-profile-con > .wrapper > .card-1 {
    width: 100%;
  }
}
@media screen and (max-width:650px) {
  .user-profile-container {
    padding: 160px 10px 10px 10px;
  }

  .user-profile-con  {
    width: 100%;
    padding: 15px;
  }

  .user-profile-con > .wrapper {
    flex-direction: column;
  }

  .user-profile-con > .wrapper > .card-1 {
    width: 100%;
  }
}

.customer-order {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  background-color: whitesmoke;
  padding: 90px 200px 20px 200px;
}

.customer-order-con {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.customer-order-con > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.customer-order-switch {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 20px  0px;
}

.customer-order-switch > button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.customer-order-switch > button:focus {
color: #d0300e;
}

.customer-order-con > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  padding: 15px;
}

.customer-order-con > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.customer-order-con > .wrapper > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.customer-order-con > .wrapper > a {
  text-decoration: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #ffcd00;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.customer-order-con > .wrapper > .order-circle {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: whitesmoke;
}

@media screen and (max-width:768px) {
  .customer-order {
    padding:160px 10px 10px 10px;
  }

  .customer-order-con {
    width: 100%;
  }

  .customer-order-switch > button {
    width: 50%;
    font-size: 14px;
  }
  .customer-order-con > .wrapper {
   margin-top: 15px;
  }
  .customer-order-con > .wrapper > p {
    text-align: center;
  }
}
@media screen and (max-width:650px) {
  .customer-order {
    padding:160px 10px 10px 10px;
  }

  .customer-order-con {
    width: 100%;
  }

  .customer-order-switch > button {
    width: 50%;
    font-size: 11px;
  }
  .customer-order-con > .wrapper {
   margin-top: 15px;
  }
  .customer-order-con > .wrapper > p {
    text-align: center;
  }
}

.customer-message-inbox {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 90px 200px 20px 200px;
}

.customer-message-inbox-con {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.customer-message-inbox-con > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.customer-message-inbox-con > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  padding: 15px;
}

.customer-message-inbox-con > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.customer-message-inbox-con > .wrapper > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 1.5rem;
}

.customer-message-inbox-con > .wrapper > .order-circle {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: whitesmoke;
}

@media screen and (max-width:768px) {
  .customer-message-inbox {
    padding: 160px 10px 10px 10px;
  }

  .customer-message-inbox-con {
    width: 100%;
  }

  .customer-message-inbox-con > .wrapper > p {
    text-align: center;
    line-height: 1.5rem;

  }
}
@media screen and (max-width:650px) {
  .customer-message-inbox {
    padding: 160px 10px 10px 10px;
  }

  .customer-message-inbox-con {
    width: 100%;
  }


  .customer-message-inbox-con > .wrapper > p {
    text-align: center;
    line-height: 1.5rem;

  }
}
.customer-save-item {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 90px 200px 20px 200px;
}
.customer-save-item-con {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.customer-save-item-con > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.customer-save-item-con > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  padding: 15px;
}

.customer-save-item-con > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.customer-save-item-con > .wrapper > p {
  width: 47%;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 1.5rem;
}

.customer-save-item-con > .wrapper > .order-circle {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: whitesmoke;
}


.customer-save-item-con > .wrapper > a {
  text-decoration: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #ffcd00;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);

}


@media screen and (max-width:768px) {
  .customer-save-item {
    padding: 160px 10px 10px 10px;
  }

  .customer-save-item-con {
    width: 100%;
  }

  .customer-save-item-con > .wrapper {
    margin-top: 15px;
  }
  .customer-save-item-con > .wrapper > p {
    text-align: center;
    line-height: 1.5rem;
    width: 100%;
  }
}
@media screen and (max-width:650px) {
  .customer-save-item {
    padding: 160px 10px 10px 10px;
  }

  .customer-save-item-con {
    width: 100%;
  }

  .customer-save-item-con > .wrapper {
    margin-top: 15px;
  }
  .customer-save-item-con > .wrapper > p {
    text-align: center;
    line-height: 1.5rem;
    width: 100%;
  }
}

.customer-address-book {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 90px 200px 20px 200px;
}
.customer-address-book-con {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.customer-address-book-con > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.customer-address-book-con > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  padding: 15px;
}

.customer-address-book-con > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.customer-address-book-con > .wrapper > p {
  width: 57%;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 1.5rem;
}

.customer-address-book-con > .wrapper > .order-circle {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: whitesmoke;
}


.customer-address-book-con > .wrapper > a {
  text-decoration: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #ffcd00;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);

}

@media screen and (max-width:768px) {
  .customer-address-book {
    padding: 160px 10px 10px 10px;
  }

  .customer-address-book-con {
    width: 100%;
  }

  .customer-address-book-con > .wrapper {
    margin-top: 15px;
  }
  .customer-address-book-con > .wrapper > p {
    text-align: center;
    line-height: 1.5rem;
    width: 100%;
  }
}
@media screen and (max-width:650px) {
  .customer-address-book {
    padding: 160px 10px 10px 10px;
  }

  .customer-address-book-con {
    width: 100%;
  }

  .customer-address-book-con > .wrapper {
    margin-top: 15px;
  }
  .customer-address-book-con > .wrapper > p {
    text-align: center;
    line-height: 1.5rem;
    width: 100%;
  }
}


.pending-review {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 90px 200px 20px 200px;
}
.pending-review-con {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.pending-review-con > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.pending-review-con > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  padding: 15px;
}

.pending-review-con > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.pending-review-con > .wrapper > p {
  width: 60%;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 1.5rem;
}
.pending-review-con > .wrapper > .order-circle {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: whitesmoke;
}


.pending-review-con > .wrapper > a {
  text-decoration: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #ffcd00;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);

}

@media screen and (max-width:768px) {
  .pending-review {
    padding: 160px 10px 10px 10px;
  }

  .pending-review-con {
    width: 100%;
  }

  .pending-review-con > .wrapper {
    margin-top: 15px;
  }
  .pending-review-con > .wrapper > p {
    text-align: center;
    line-height: 1.5rem;
    width: 100%;
  }
}

@media screen and (max-width:650px) {
  .pending-review {
    padding: 160px 10px 10px 10px;
  }

  .pending-review-con {
    width: 100%;
  }

  .pending-review-con > .wrapper {
    margin-top: 15px;
  }
  .pending-review-con > .wrapper > p {
    text-align: center;
    line-height: 1.5rem;
    width: 100%;
  }
}


.customers-account-managements {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 90px 200px 20px 200px;
}

.customers-account-managements > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.customers-password-forms {
  width: 60%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.customers-password-forms > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  gap: 20px;
  padding: 20px;
  background: #f9f9f9;
}

.customers-password-forms > form > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.customers-password-forms > form > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.customers-password-forms > form span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.customers-password-forms > form span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.customers-password-forms > form > span > .pass {
  width: 100%;
  height: 50px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.customers-password-forms > form > button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  border-radius: 38px;
  background: #d0300e;
  cursor: pointer;
  border: none;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width:768px) {
  .customers-account-managements {
    padding: 160px 10px 10px 10px;
  }
  .customers-password-forms {
    width: 100%;
  }

  .customers-account-managements > h3 {
    text-align: center;
    font-size: 14px;
  }
}
@media screen and (max-width:650px) {
  .customers-account-managements {
    padding: 160px 10px 10px 10px;
  }
  .customers-password-forms {
    width: 100%;
  }

  .customers-account-managements > h3 {
    text-align: center;
    font-size: 14px;
  }
}

.recent-view-product {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 90px 180px 20px 180px;
}

.recent-view-product > h3 {
  font-family: Montserrat;
  color: #202020;
}

@media screen and (max-width:896px) {
  
.recent-view-product {
  padding: 160px 10px 20px 10px;
}
}

.user-navbar {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.user-navbar > a {
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #202020;
  gap: 20px;
  font-family: Montserrat;
}

.user-navbar > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.user-navbar > span > button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #202020;
  gap: 20px;
  font-family: Montserrat;
  border: none;
  background: transparent;
  outline: none;
  font-size: 16px;

}



.user-navbar > .btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-navbar > .btn > button {
border: none;
outline: none;
background: transparent;
font-family: Montserrat;
color:  #d0300e;
font-size: 16px;
}

@media screen and (max-width:896px) {
  .user-navbar {
    display: none;
  }
}





