* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super_admin_settings_container {
  padding-top: 100px;
  width: 81.9%;
  height: 100vh;
  margin-left: 260px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
}

.super_admin_settings_container > .header {
  display: flex;
  width: 100%;
  height: 70px;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.super_admin_settings_container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.super_admin_settings_container > .setting {
  width: 885px;
  height: 407px;
  border-radius: 20px;
  background: #fff;
  margin-top: 20px;
  margin-left: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.super_admin_settings_container > .setting > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.super_admin_settings_container > .setting > span > h3,
.super_admin_settings_container > .setting > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.super_admin_settings_container > .setting > span > button {
  color: #3156db;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  cursor: pointer;
  border: none;
  outline: none;
  background: #f9f9f9;
}

.super_admin_settings_container > .setting > span > .log-out {
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
  letter-spacing: 0.2px;
  background-color: #ab0808;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 3px;
  text-decoration-line: none;
}

.super_admin_settings_container > .setting > span > .log-out:hover {
  background-color: #e74343;
}

@media screen and (max-width:768px) {
  .super_admin_settings_container {
    margin: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }

  .super_admin_settings_container > .setting {
    width: 100%;
  }
}
