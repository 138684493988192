* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  
  .markplace-customer-container {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f9f9f9;
  }
  
  .markplace-customer-container > .header {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .markplace-customer-container > .header > h1 {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }



  .markplace-customer-container > .header  > span {
    display: flex;
    width: 280px;
    height: 44px;
    padding: 11px;
    align-items: center;
    border-radius: 30px;
    gap: 10px;
    border: 1px solid rgba(32, 32, 32, 0.6);
  }
  
  .markplace-customer-container > .header  > span input {
    width: 100%;
    border: none;
    outline: none;
    background: #f9f9f9;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
  }


  .markplace-customer-container > .customer-table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
