* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.pkg-details-container {
  padding: 100px 0px 30px 0px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.pkg-details-container > .header {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  display: flex;
  gap: 10px;
}

.pkg-details-container > .header > h1 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.pkg-details-container > .header > .icon {
  cursor: pointer;
}

.pkg-details-container > .card {
  width: 98%;
  padding-bottom: 1px;
  align-items: center;
  gap: 27px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin-top: 20px;
}

.pkg-details-container > .card > .header {
  width: 100%;
  display: flex;
  height: 66px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.pkg-details-container > .card > .header > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.updates-btn {
  width: 100%;
  height: 40px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0px;
}

.updates-btn > button:nth-child(1) {
  display: flex;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 5px;
  background: #189b48;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.updates-btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 5px;
  background: #d0310e;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
}

.delete-pkg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(18, 18, 18, 0.5);
  z-index: 100;
}

.delete-pkg > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.delete-pkg > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.delete-pkg > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
  width: 170px;
  line-height: 15px;
}

.delete-pkg > .card > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.delete-pkg > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.delete-pkg > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.update-pkg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(18, 18, 18, 0.5);
  z-index: 100;
}

.update-pkg > .card {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 15px 20px;
  padding: 25px 50px;
  border-radius: 10px;
  background: #fff;
}

.update-pkg > .card > .form-group > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0px;
}

.update-pkg > .card > .form-group > input {
  display: flex;
  width: 320px;
  height: 42px;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.update-pkg > .card > .form-groups {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.update-pkg > .card > .form-groups > button:nth-child(1){
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.update-pkg > .card > .form-groups > button:nth-child(2){
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pkg-details-container > .card > .sender {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 20px;
}

.pkg-details-container > .card > .sender > p {
  color: rgba(32, 32, 32, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}

.pkg-details-container > .card > .sender-info {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
}

.pkg-details-container > .card > .sender-info > .card {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.pkg-details-container > .card > .sender-info > .card > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.pkg-details-container > .card > .sender-info > .card > span {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}
