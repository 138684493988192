* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.faq-banner {
  width: 100;
  height: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 105px 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/w6FbhMk/Downloader-5-3x.png");
}

.faq-banner > h3 {
  width: 35%;
  color: #fff;
  font-family: Strike;
  font-size: 36px;
  font-weight: 400;
  line-height: 143%;
}

.faq-mother-card {
  width: 100%;
  padding: 40px 100px;
}

.faq-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
}

.question-container {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0px;
  gap: 32px;
  transition: 0.3s ease-out;
  border-bottom: 1px solid lightgray;
}

.question-container > span {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.question-container > span > h3 {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
}

.question-container > span > .fa-solid {
  color: rgba(32, 32, 32, 0.8);
  font-size: 26px;
}

.answer-container {
  width: 100%;
  padding: 10px 35px;
  transition: 0.3s ease-out;
}

.answer-container > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.8rem;
}


@media screen and (max-width: 896px) {
  
  .faq-banner {
    width: 100%;
    background-image: url('https://i.ibb.co/j54tJ1j/Downloader-5.png') ;
    background-position: center;
    background-repeat: no repeat;
    background-size: cover;
    padding: 10px;
  }

  .faq-mother-card {
    padding: 0px;
  }

  .faq-banner > h3 {
    width: 70%;
    font-size: 20px;
    font-weight: 400;
    line-height: 123%;
  }

  .faq-container {
    width: 100%;
    padding: 10px;
  }

  .question-container {
    gap: 10px;
  }

  .question-container > span > h3 {
    color: rgba(32, 32, 32, 0.8);
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 400;
    line-height: normal;
  }

  .answer-container{
    padding:0px 20px;
  }


  
  .answer-container > p {
    color: rgba(32, 32, 32, 0.8);
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5rem;
  }

}
