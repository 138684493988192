* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}




.freight-overview{
  width: 83.4%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 20px;
  flex-direction: column;
  margin-left: 260px;
  background-color: whitesmoke;
}

.freight-overview > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}
.freight-overview > .wrapper > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}


.freight-overview-mother {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}

.freight-overview-mother > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.freight-overview-mother > .wrapper > .card {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.50);
}

.freight-overview-mother > .wrapper > .card > p { 
font-family: Montserrat;
text-align: center;
}

.freight-overview-mother > .wrapper > .card > h3 { 
font-family: Montserrat;
text-align: center;
}

.freight-overview-mother > .revenue {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.freight-overview-mother > .revenue > h3 {
  font-family: Montserrat;
  font-size: 16px;
}




.freight-charters {
  width: 81.9%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 20px;
  flex-direction: column;
  margin-left: 260px;
  background-color: whitesmoke;
}

.freight-charters > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.freight-charters > .wrapper > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.freight-charters > .wrapper > form {
  width: 280px;
  height: 44px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  gap: 5px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.freight-charters > .wrapper > form > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-align: start;
  color: rgba(32, 32, 32, 0.7);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  background: transparent;
}

.freight-charters > .wrapper > form > button {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
}

.freight-charter-mothers {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}

.freight-charter-mothers > table {
  width: 100%;
  border-collapse: collapse;
  text-align: start;
}

th {
  border-bottom: 1px solid #e0e0e0;
  background: #f5f5f5;
  font-family: Montserrat;
  color: #202020;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  padding: 15px;
}

td {
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding: 15px;
  text-align: start;
}

td > span {
  color: #ffcd00;
}
td > .card {
  color: green;
}
td > .start {
  color: #284697;
}

td > button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #284697;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.freight-truck-detail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.5);
  padding: 209px 100px 100px 100px;
  position: fixed;
  overflow-y: scroll;
  left: 0%;
  top: 0%;
  z-index: 100;
}

.freight-truck-detail > h3 {
  font-family: Montserrat;
}

.freight-truck-detail > .wrapper {
  width: 81.9%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  margin-left: 260px;
  gap: 20px;
}

.freight-truck-detail > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.freight-truck-detail > .wrapper > span > h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.freight-truck-detail > .wrapper > span > button {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: 20px;
}

.freight-span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
}

.freight-span > .card-1 {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.freight-span > .card-1 > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.freight-span > .card-1 > .wrapper > .image-card {
  width: 30%;
  height: 150px;
}
.freight-span > .card-1 > .wrapper > .image-card > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.freight-span > .card-1 > .wrapper > .truck-card {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.freight-span > .card-1 > .wrapper > .truck-card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 800;
}

.freight-span > .card-1 > .wrapper > .truck-card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.freight-span > .card-1 > .wrapper > .truck-card > span > p {
  color: rgba(32, 32, 32, 0.8);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.freight-span > .card-1 > .wrapper > .truck-card > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
}

.freight-span > .card-1 > .descriptions {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  border-bottom: rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
}
.freight-span > .card-1 > .descriptions > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}
.freight-span > .card-1 > .descriptions > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 132.5%;
  margin-top: 20px;
}

.freight-span > .card-1 > .assign {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
}

.freight-span > .card-1 > .assign > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.freight-span > .card-1 > .assign > button {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background: #284697;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.freight-span > .card-2 {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.freight-span > .card-2 > .freight-shipping-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;

}

.freight-span > .card-2 > .freight-shipping-info > .card {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    padding: 0px 15px;

}

.freight-span > .card-2 > .freight-shipping-info > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0px;
  width: 100%;
}

.freight-span > .card-2 > .freight-shipping-info > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 23px;
  padding: 10px 15px;
}

.freight-span > .card-2 > .freight-shipping-info > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.freight-span > .card-2 > .freight-shipping-info > .wrapper > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}
.freight-span > .card-2 > .freight-shipping-info > .wrapper > span > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;;
    font-weight: 400;
}

.freight-admin-order {
  width:  81.9% ;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 260px;
  padding: 100px 20px;
  
  }
  
  .freight-admin-order > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }

  .freight-admin-order > .wrapper > h3 {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
  }
  
  .freight-admin-order > .freight-admin-mother {
    width: 100%;
    margin-top: 20px;
  }
  
  td > .truck-image {
    width: 50px;
    height: 5 0px;
    object-fit: cover;
  }

  .admin-truck-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
    padding: 50px 100px 100px 100px;
    position:fixed;
    overflow-y: scroll;
    left: 0%;
    top: 0%;
    z-index: 100;
  }
  
  .admin-truck-overlay > .wrapper {
    width: 81.9%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 5px;
    background: #fff;
    padding: 20px;
    margin-left: 260px;
    gap: 20px;
  }
  
  .admin-truck-overlay > .wrapper > span {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  
  .admin-truck-overlay > .wrapper > span > button {
    border: none;
    background: transparent;
    outline: none;
  }
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  }
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail > img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail > .admin-truck-span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  
  }
  
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail > .admin-truck-span > .card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail > .admin-truck-span > .card > h3 {
  font-family: Montserrat;
  font-weight: 400;
  }
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail > .admin-truck-span > .card > h4 {
  font-family: Montserrat;
  }
  
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail > .admin-truck-span > .card > p {
  font-family: Montserrat;
  }
  
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail  > p {
  font-family: Montserrat;
  line-height: 1.3rem;
  }
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail  > h3 {
  font-family: Montserrat;
  margin-top: 20px;
  font-weight: 400;
  }
  .admin-truck-overlay > .wrapper > .admin-truck-detail  > button {
  font-family: Montserrat;
  margin-top:10px;
  border: none;
  background: #d0310e;
  color: white;
  padding:5px  10px;
  border-radius: 5px;
  }
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail > .admin-truck-span > .card > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
  
  .admin-truck-overlay > .wrapper > .admin-truck-detail > .admin-truck-span > .card > span > p {
    font-family: Montserrat;
  }

  .freight-admin-order-overlay {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px 100px 100px 100px;
    position: fixed;
    overflow-y: scroll;
    left: 0%;
    top: 0%;
    z-index: 101;
  }
  
  .freight-admin-order-overlay > span {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  
  .freight-admin-order-overlay  > span > button {
    border: none;
    background: transparent;
    outline: none;
    color: white;
  }
  
  .freight-admin-order-overlay> .wrapper {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    margin: auto;
    border-radius: 20px;
  }
  
  .freight-admin-order-overlay > .wrapper > form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  .freight-admin-order-overlay > .wrapper > form > h2 {
    font-family: Montserrat;
  }
  
  .freight-admin-order-overlay > .wrapper > form > .freight-combination {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  } 
  
  .freight-admin-order-overlay > .wrapper > form > .freight-combination > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .freight-admin-order-overlay > .wrapper > form > .freight-combination> span > select {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .freight-admin-order-overlay > .wrapper > form > .freight-combination > span > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  .freight-admin-order-overlay > .wrapper > form > .freight-combination > span > input {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding-left: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .freight-admin-order-overlay > .wrapper > form > button {
    display: flex;
    width: 250px;
    height: 43px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    background: #d0300e;
    border-radius: 5px;
  }
  
  .log-in-form > form > button:disabled {
    background-color: #B5B5B5;
    cursor: not-allowed;
  }

  .freight-hire-detail {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
    padding: 209px 100px 100px 100px;
    position: absolute;
    overflow-y: scroll;
    left: 0%;
    top: 0%;
    z-index: 100;
  }

  .freight-hire-detail > .wrapper {
    width: 81.9%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 5px;
    background: #fff;
    padding: 20px;
    margin-left: 260px;
    gap: 20px;
  }

  .freight-hire-detail > .wrapper > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(32, 32, 32, 0.3);
    background: #fff;
    padding-bottom: 20px;
  }
  
  .freight-hire-detail > .wrapper > span > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
  }
  
  .freight-hire-detail > .wrapper > span > button {
    border: none;
    background: transparent;
    outline: none;
  }

  .freight-hire-detail-span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }

  .freight-hire-detail-span > .card-1 {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .freight-hire-detail-span > .card-1 > .wrapper { 
    width: 100%;
    display: flex;
    gap: 20px;
  }

  .freight-hire-detail-span > .card-1 > .wrapper > img {
    width: 50%;
    height: 150px;
    object-fit: cover;
  }

  .freight-hire-detail-span > .card-1 > .wrapper > .inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  .freight-hire-detail-span > .card-1 > .wrapper > .inner > h3 {
    font-family: Montserrat;
    color: #202020;
    font-size: 16px;
    font-weight: 400;
  }
  .freight-hire-detail-span > .card-1 > .wrapper > .inner > h4 {
    font-family: Montserrat;
    color: #202020;
    font-size: 16px;
    font-weight: 700;
  }

  .freight-hire-detail-span > .card-1 > .wrapper > .inner > span {
    width: 100%;
    display: flex;
    gap: 5px;
  }

  .freight-hire-detail-span > .card-1 > .wrapper > .inner > span > p {
    font-family: Montserrat;
    color: #202020;
    font-size: 16px;
    font-weight: 400;
  }

  .freight-hire-detail-span > .card-1 > .freight-hire-description {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .freight-hire-detail-span > .card-1 > .freight-hire-description > h3 {
    font-family: Montserrat;
    color: #202020;
    font-size: 16px;
    font-weight: 700
  }
  .freight-hire-detail-span > .card-1 > .freight-hire-description > p {
    font-family: Montserrat;
    color: #202020;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3rem;
  }

  .freight-hire-detail-span > .card-1 > .btn {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  .freight-hire-detail-span > .card-1 > .btn > button:nth-child(1) {
    width: 250px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    font-family: Montserrat;
    font-size: 14px;
    cursor: pointer;
    font-weight: 700;
    margin-top: 20px;
  }
  .freight-hire-detail-span > .card-1 > .btn > button:nth-child(2) {
    width: 250px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border-radius: 5px;
    background: #189b48;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    border: none;
    cursor: pointer;
    font-weight: 700;
    margin-top: 20px;
  }

  .freight-hire-detail-span > .card-2 {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .freight-hire-detail-span > .card-2 > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
  }

  .freight-hire-detail-span > .card-2 > .wrapper > h3 {
    width: 100%;
    color: #202020;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
  }
  .freight-hire-detail-span > .card-2 > .wrapper > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .freight-hire-detail-span > .card-2 > .wrapper > span > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
  }
  .freight-hire-detail-span > .card-2 > .wrapper > span > h4 {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
  }

  

  




