* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.request-code-form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0px;
}

.request-code-form > h3 {
  color: #1f4798;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 700;
}

.request-code-form > p {
    color: #121212; 
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
}

.request-code-form > form {
  width: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.request-code-form > form > span {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.request-code-form > form > span > label {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.request-code-form > form > span > input {
  width: 100%;
  height: 50px;
  outline: none;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.request-code-form > form > .wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

.request-code-form > form > .wrapper > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}
.request-code-form > form > .wrapper > button {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  background: transparent;
  border: none;
}

@media screen and (max-width:650px) {
    .request-code-form {
        padding: 10px;
    }

    .request-code-form > form {
        width: 100%;
    }
}
