* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.assignment-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 70px;
}

.assignment-details > .ass-details {
  width: 100%;
  border: 1px solid #e0e0e0;
}

.assignment-details > .ass-details > .card {
  width: 100%;
}

.assignment-details > .ass-details > .card > .header {
  width: 100%;
  height: 54px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

.assignment-details > .ass-details > .card > .header > span {
  display: flex;
  gap: 20px;
  align-items: center;
}

.assignment-details > .ass-details > .card > .header > button {
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  display: flex;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}

.ass-details > .card > .header > span > h2 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.ass-details > .card > .receiver-card {
  width: 100%;
  padding: 20px;
}

.ass-details > .card > .receiver-card > p {
  color: rgba(32, 32, 32, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0px;
  margin: 20px 0px;
}

.ass-details > .card > .receiver-card > .receiver,
.ass-details > .card > .receiver-card > .package-items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px 50px;
}

.ass-details > .card > .receiver-card > .receiver > span,
.ass-details > .card > .receiver-card > .package-items > span {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ass-details > .card > .receiver-card > .receiver > span > p,
.ass-details > .card > .receiver-card > .package-items > span > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.ass-details > .card > .receiver-card > .receiver > span > h3,
.ass-details > .card > .receiver-card > .package-items > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.updatepkg-over {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  background: rgba(32, 32, 32, 0.5);
  position: fixed;
  top: 0px;
  padding-top:100px ;
}

.updatepkg-over > .card {
  width: 572px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 50px;
}

.updatepkg-over > .card > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.updatepkg-over > .card > form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px;
}

.updatepkg-over > .card > form > select {
  width: 100%;
  height: 43px;
  border-radius: 40px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px;
}

.updatepkg-over > .card > form > input {
  width: 100%;
  height: 43px;
  border-radius: 40px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px;
}

.updatepkg-over > .card > form > .btn {
  display: flex;
  align-items: flex-end;
  height: 40px;
}

.updatepkg-over > .card > form > .btn > button:nth-child(1) {
  display: flex;
  width: 100px;
  height: 38px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #fff;
  color: #d0300e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  outline: none;
  cursor: pointer;
}

.updatepkg-over > .card > form > .btn > button:nth-child(2) {
  display: flex;
  width: 100px;
  height: 38px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #d0300e;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  cursor: pointer;
  border: none;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 896px) {
  .assignment-details > .ass-details > .card > .header{
    padding: 0px 10px;
  }
  .assignment-details {
    padding: 90px 10px 10px 10px;
  }

  .updatepkg-over > .card {
    width: 97%;
  }

  .updatepkg-over > .card > form {
    grid-template-columns: repeat(1, 1fr);
  }
}
