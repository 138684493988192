* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.vehicle-collection {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 100px;
}

.vehicle-collection > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
}

.vehicle-collection > span > a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
}
.vehicle-collection > span > h3 {
  color:#D0300E;
  font-family: Montserrat;
  font-size: 14px;
}

.vehicle-collection > h3 {
  font-family: Montserrat;
  margin-top: 20px;
  color: #284697;
}

.vehicle-collection > .wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}

.vehicle-collection > .wrapper > .vehicle-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
}

.vehicle-collection > .wrapper > .vehicle-list > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.vehicle-collection > .wrapper > .vehicle-list > h4 {
 font-family: Montserrat;
}

.vehicle-collection > .wrapper > .vehicle-list > p {
 font-family: Montserrat;
}

.vehicle-collection > .wrapper > .vehicle-list > h3 {
 font-family: Montserrat;
 font-weight: 600;
 font-size: 16px;
 display: flex;
 gap: 5px;
}
.vehicle-collection > .wrapper > .vehicle-list > h3 > p {
 font-family: Montserrat;
 font-weight: 400;
 font-size: 14px;
}

.vehicle-collection > .wrapper > .vehicle-list > P > a {
 font-family: Montserrat;
 text-decoration: none;
 color:  #D0300E;
}

.vehicle-collection-pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.vehicle-collection-pagination > span {
  font-family: Montserrat;
}

.vehicle-collection-pagination > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


.vehicle-collection-pagination > .btn > button {
  background: transparent;
  border: 1px solid black;
  outline: none;
  cursor: pointer;
  font-family: Montserrat;
  padding: 5px;
  border-radius: 3px;
}

.error {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat;
  padding: 10px 0px;
}

@media screen and (max-width:896px) {
  .vehicle-collection {
    padding: 10px;
  }

  .vehicle-collection > .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }

  .error {
    text-align: center;
  }
}
