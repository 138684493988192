* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  .freight-truck {
    width: 81.9%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 120px 20px 40px 20px;
    flex-direction: column;
    margin-left: 260px;
    background-color: whitesmoke;
  }
  
  .freight-truck > .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  
  .freight-truck > .wrapper > h3 {
    color:#284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
  }
  
  .freight-truck > .wrapper > span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
  
  .freight-truck > .wrapper > span > a {
    text-decoration: none;
    color: #fff;
    display: flex;
    gap: 3px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    height: 43px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: #d0300e;
  }
  
  .freight-truck > .wrapper > span > form {
    width: 280px;
    height: 44px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    gap: 5px;
    border: 1px solid rgba(32, 32, 32, 0.6);
  }
  
  .freight-truck > .wrapper > span > form > input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    text-align: start;
    color: rgba(32, 32, 32, 0.7);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    background: transparent;
  }
  
  .freight-truck > .wrapper > span > form > button {
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    background: transparent;
  }

  .freight-truck-mother {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    background: #fff;
  }

  .freight-truck-mother > .truck-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    padding: 10px;
  }
  .freight-truck-mother > .truck-card > h3  {
    color: #202020;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .freight-truck-mother > .truck-card > img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .freight-truck-mother > .truck-card  > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  
  
  .freight-truck-mother > .truck-card  > .wrapper > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  
  .freight-truck-mother > .truck-card  > .wrapper > span > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  
 
  
  .freight-truck-mother > .truck-card  > .wrapper > h4 {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }

  .freight-truck-mother > .truck-card  > .wrapper > .truck-span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:10px ;
  }
  .freight-truck-mother > .truck-card  > .wrapper > .truck-span > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
  }

  .freight-truck-mother > .truck-card  > .wrapper > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .freight-truck-mother > .truck-card  > .wrapper > .truck-span > a {
    text-decoration: none;
    color: #202020;
  }



  .add-truck {
    width: 81.9%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 120px 20px;
    flex-direction: column;
    margin-left: 260px;
    background-color: whitesmoke;
  }
  
  .add-truck > h3 {
    width: 100%;
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }

  .add-truck > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    margin-top: 20px;
    padding: 15px;
    gap: 30px;
  }
  
  .add-truck > .wrapper > form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .add-truck > .wrapper > form > .card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(32, 32, 32, 0.3);
    padding-bottom: 15px;
  }
  
  .add-truck > .wrapper > form > .card > span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  
  .add-truck > .wrapper > form > .card > span > h3 {
    color: rgba(32, 32, 32, 0.9);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }
  
  .add-truck > .wrapper > form > .card > span > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgba(32, 32, 32, 0.9);
    font-family: Montserrat;
    gap: 10px;
  }

  .truck-image {
    width: 100%;
    display: flex;
    padding: 10px 0px;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .truck-image > h3 {
    width: 100%;
    color: rgba(32, 32, 32, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0px;
    border-bottom: 1px solid #e0e0e0;
  }


  .truck-image-span {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px 0px;
  }


.truck-image-card {
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid rgba(45, 69, 150, 0.8);
    background: rgba(233, 244, 255, 0.7);
  }

  .truck-image-card > svg {
    font-size: 40px;
  }
  
  .truck-image-card > label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .truck-image-card > label > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 400;
  }
  
  .truck-image-span > img {
    width: 150px;
    height: 100%;
    object-fit: contain;
  }
  
  .truck-image-span > button {
    display: flex;
    height: 43px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    background: #d0300e;
  }
  

  .truck-input-detail {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .truck-input-detail > h3 {
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.4px;
    padding-bottom: 15px;
    width: 100%;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }

  .truck-input-detail > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    margin: 20px 0px;
  }

  .truck-input-detail > .wrapper > span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
  }
  
  .truck-input-detail > .wrapper > span > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .truck-input-detail > .wrapper > span > select {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  .truck-input-detail > .wrapper > span > input {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }

  .truck-description-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  
  .truck-description-card> h3 {
      width: 100%;
      color: rgba(32, 32, 32, 0.5);
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.4px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e0e0e0;
  }
  
  .truck-description-card > textarea {
      width: 100%;
      height: 90px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      background: #fff;
      outline: none;
      padding: 10px;
      color: rgba(32, 32, 32, 0.7);
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
  }

  .truck-price-perday {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }


  .truck-price-perday > h3 {
    width: 100%;
    color: rgba(32, 32, 32, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.4px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
}


.truck-price-perday > span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;

}
  
.truck-price-perday > span > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }

  .truck-price-perday > span > input {
    width: 32%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding-left: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }

  .add-truck > .wrapper > form > .card > button {
    width: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #d0310e;
    border: none;
    outline: none;
    font-family: Montserrat;
    color: white;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
  }

