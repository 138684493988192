* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  .banner-slider-container {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .banner-slide {
    width: 100%;
    height: 100%;
  }

  .banner-slider {
    width: 100%;
    height: 400px;
    border-radius: 10px;
  }

  .banner-slider > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


.banner-slider-container:hover .slick-prev,
.banner-slider-container:hover .slick-next {
  opacity: 1;
}

.slick-prev,
.slick-next {
  color:#000; 
  background-color:rgba(255, 253, 253, 0.8); 
  border-radius: 50%; 
  width: 35px; 
  height: 35px; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  z-index: 1; 
  cursor: pointer;
  padding: 10px;
  box-shadow: 0px 0px 4px lightgray;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.slick-prev {
  left: 20px; 
}

.slick-next {
  right: 20px; 
} 

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(246, 242, 242, 0.8);
  color: #d0300e;
  opacity: 1;
}


.slick-dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.slick-dots li {
  margin: 0 2px;
  display: flex;
  flex-direction: row;
}

.slick-dots li button {
  border: 1px solid gray; 
  background-color: white; 
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 5px;
  height: 5px;
  margin: auto;
}


.slick-dots li.slick-active button {
  background-color: #202020; 
}

@media screen and (max-width:650px) {
  .slick-prev,
.slick-next {
  width: 25px; 
  height: 25px; 
}

.slick-prev {
  left: 15px; 
}

.slick-next {
  right: 15px; 
} 


  .banner-slider-container {
    height: fit-content;
  }

 .banner-slider {
  height: fit-content;
 }


 .banner-slider > img {
  object-fit: contain;
 }

}



