* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.market-shop-nav {
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  position: fixed;
  z-index: 100;
}

.market-shop-nav > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.logo-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logo-menu > .react-menu {
  display: none;
}

.logo-menu > a {
  text-decoration: none;
  height: 80%;
}

.logo-menu > a > img {
  width: 150px;
  height: 100%;
  object-fit: cover;
}

.user-cart {
  display: none;
}

.help-center-drop {
  display: flex;
  justify-content: center;
  height: 100%;
  /* background-color: #284697; */
}

.help-center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.help-center-box > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.help-quick-link {
  width: 200px;
  top: 78px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  padding: 10px;
  background: #fff;
  z-index: 100;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.help-quick-link > a {
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-family: Montserrat;
  font-size: 14px;
  color: #121212;
}

.help-quick-link > .card {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.help-quick-link > .card > a {
  text-decoration: none;
  width: 100%;
  color: white;
  font-family: Montserrat;
  font-size: 13.5px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  gap: 5px;
  background: #ffcd00;
}

.category-menu > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  border-bottom: 0.5px solid rgba(32, 32, 32, 0.4);
  padding: 15px 0px;
}

.category-menu > .wrapper > .mobile-category {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.category-menu > .wrapper > .mobile-category > a {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  font-family: Montserrat;
  gap: 15px;
  color: #000;
}
.category-menu > .wrapper > a {
  text-decoration: none;
  font-family: Montserrat;
  color: #000;
}

.category-menu > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-menu > .wrapper > span > h3 {
  font-family: Montserrat;
  font-weight: 400;
}

.category-menu > .wrapper > span > a {
  text-decoration: none;
  font-family: Montserrat;
  color: #d0300e;
}

@media screen and (max-width: 650px) {
  .help-quick-link {
    left: 10px;
    top: 226px;
  }

  .logo-menu > .react-menu {
    display: block;
    font-size: 20px;
  }

  .category-menu {
    width: 85%;
    height: 100%;
    background-color: white;
    padding: 90px 15px 15px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    overflow-y: scroll;
    padding-bottom: 100px !important;
  }

  .category-menu > a {
    font-family: Montserrat;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #202020;
    gap: 4px;
  }

  .category-menu > .wrapper > span > h3 {
    font-size: 12px;
  }

  .drop-down-wrapper {
    position: fixed;
    top: 80px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    transform: translateX(-100%);
    display: flex;
    border-top: 1px solid #616161;
    transition: 0.3s ease-in-out;
  }

  .drop-down-wrapper.mobile.active {
    transform: translateX(0%);
    transition: 0.3s ease-in-out;
  }

  .user-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}


.user-circle {
  display: flex;
  justify-content:center;
  align-items: center;
  gap: 8px;
  padding: 10px;
  width: 30%;
}

.user-circle > p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.user-circle > .user-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}


.user-circle > .user-name > p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}


.market-combination {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.market-shop-nav > span > .search-input {
  width: 70%;
  display: flex;
  align-items: flex-start;
}

.market-shop-nav > span > .search-input > form {
  width: 60%;
  height: 50px;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fefefe;
}

.market-shop-nav > span > .search-input > form > input {
  width: 100%;
  height: 98%;
  border: none;
  outline: none;
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.market-shop-nav > span > .search-input > form > button {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #d0300e;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .market-shop-nav {
    padding: 10px;
  }

  .market-combination {
    width: 80%;
    display: flex;
    justify-content: flex-start;
  }

  .market-shop-nav > .search-input > form {
    width: 100%;
    height: 40px;
    padding: 10px;
  }

  .logo-menu > .react-menu {
    display: block;
    font-size: 20px;
  }

  .category-menu {
    width: 85%;
    height: 100%;
    background-color: white;
    padding: 90px 15px 15px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  .category-menu > a {
    font-family: Montserrat;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #202020;
    gap: 4px;
  }

  .drop-down-wrapper {
    position: fixed;
    top: 80px;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    transform: translateX(-100%);
    display: flex;
    border-top: 1px solid #616161;
    transition: 0.3s ease-in-out;
  }

  .drop-down-wrapper.mobile.active {
    transform: translateX(0%);
    transition: 0.3s ease-in-out;
  }

  .user-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .market-shop-nav {
    width: 100%;
    height: fit-content;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    position: fixed;
  }

  .market-shop-nav > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .market-combination {
    width: 100%;
    display: none;
  }

  .market-shop-nav > span > .search-input {
    width: 100%;
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .market-shop-nav {
    width: 100%;
    height: fit-content;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    position: fixed;
  }

  .market-shop-nav > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .market-combination {
    width: 100%;
    display: none;
  }

  .market-shop-nav > span > .search-input {
    width: 100%;
    display: none;
  }

  .market-shop-nav > .search-input > form {
    width: 100%;
    height: 60px;
    padding: 10px;
  }
}

.market-mother-cards {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 80px 20px 20px 20px;
}

.market-con-1 {
  width: 15%;
  min-height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 5px #e0e0e0;
}

.market-con-1 > a {
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-family: Montserrat;
  font-size: 14px;
  color: #121212;
}
.market-con-1 > a:hover {
  color: #d0300e;
}

.market-con-2 {
  width: 55%;
}

.market-con-3 {
  width: 30%;
  height: 400px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 20px;
}

.market-con-3 > .card-1 {
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 0px 10px;
  box-shadow: 0px 0px 5px #e0e0e0;
}

.market-con-3 > .card-1 > h3 {
  color: #000;
  font-family: Strike;
  font-size: 13px;
  font-weight: 700;
}
.market-con-3 > .card-1 > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.market-con-3 > .card-1 > a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  background: #ffcd00;
}

.market-con-3 > .card-2 {
  width: 100%;
  height: 190px;
  background-image: url("https://www-konga-com-res.cloudinary.com/w_400,f_auto,fl_lossy,dpr_3.0,q_auto/media/catalog/product/O/W/154509_1623809419.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}

.market-con-3 > .card-2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.market-con-3 > .card-3 {
  width: 100%;
  height: 190px;
  background-image: url("https://cdn.thewirecutter.com/wp-content/media/2021/05/mensjeans-2048px-3991.jpg?auto=webp&quality=75&crop=1.91:1&width=1200");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}

.market-con-3 > .card-3 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.market-con-3 > .card-4 {
  width: 100%;
  height: 190px;
  background-image: url("https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/83/6985262/1.jpg?1983");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}

.market-con-3 > .card-4 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.errors-msg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
}

.overlay > a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  width: 100px;
  background: #ffcd00;
}

@media screen and (max-width: 768px) {
  .market-mother-cards {
    padding: 160px 10px 10px 10px;
    flex-direction: column;
  }

  .market-con-1 {
    width: 100%;
    display: none;
  }
  .market-con-2 {
    width: 100%;
    height: fit-content;
  }
  .market-con-3 {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .market-mother-cards {
    padding: 160px 10px 10px 10px;
    flex-direction: column;
  }

  .market-con-1 {
    width: 100%;
    display: none;
  }
  .market-con-2 {
    width: 100%;
    height: fit-content;
  }
  .market-con-3 {
    width: 100%;
  }

  .market-con-3 > .card-1 > a {
    display: none;
  }
}

.product-heading {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 100px;
}

.product-heading > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
}

.product-card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  column-gap: 20px;
  row-gap: 20px;
  padding: 0px 100px;
  margin-bottom: 50px;
}

.product-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.product-info > a {
  width: 100%;
  height: 100px;
  text-decoration: none;
}

.product-info > a > img {
  width: 100%;
  height: 100%;
}

.product-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 3px;
}

.product-wrapper > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.product-wrapper > p {
  color: rgba(0, 0, 0, 0.7);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.product-info > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #284697;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .market-mother-card {
    padding: 10px;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .market-card-1 {
    width: 100%;
  }

  .market-card-1 > h3 {
    width: 100%;
    font-size: 1.5rem;
  }

  .market-card-2 {
    width: 100%;
  }

  .market-card-2 > img {
    border-radius: 0px;
  }

  .product-card-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
  }

  .product-info > a > img {
    height: 200px;
  }

  .product-heading {
    padding: 10px;
  }
}

@media screen and (max-width: 650px) {
  .market-mother-card {
    padding: 10px;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .market-card-1 {
    width: 100%;
  }

  .market-card-1 > h3 {
    width: 100%;
    font-size: 1.5rem;
  }

  .market-card-1 > p {
    width: 100%;
    font-size: 1rem;
  }

  .market-card-1 > a {
    width: 100%;
  }

  .market-card-2 {
    width: 100%;
  }

  .market-card-2 > img {
    border-radius: 0px;
  }

  .product-heading {
    padding: 10px;
  }

  .product-card-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
  }

  .product-info > a > img {
    height: 200px;
  }
}

.product-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px 100px;
  gap: 40px;
}

.product-detail-card-1 {
  width: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.product-detail-card-1 > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-detail-card-1 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-detail-card-1 > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-detail-card-2 {
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

.product-detail-card-2 > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 44px;
  font-weight: 600;
}

.product-detail-card-2 > h4 {
  color: #202020;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 700;
}

.product-detail-card-2 > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 700;
}
.product-detail-card-2 > span {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.product-return {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}

.product-return > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
}

.product-detail-card-2 > button {
  width: 433px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: #d0300e;
  border: none;
  color: #fff;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
}

.product-detail-card-2 > a {
  width: 433px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid rgba(32, 32, 32, 0.4);
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.product-span {
  width: 100%;
}

.product-span > a {
  border: 1px solid rgba(32, 32, 32, 0.4);
  text-decoration: none;
  font-family: Montserrat;
  color: #000;
  padding: 5px 10px;
  border-radius: 2px;
}

.product-span > a:hover {
  background-color: #d0300e;
  color: white;
}

@media screen and (max-width: 768px) {
  .product-detail {
    padding: 10px;
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 650px) {
  .product-detail {
    padding: 10px;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .product-detail-card-1 {
    width: 100%;
  }
  .product-detail-card-2 {
    width: 100%;
  }
  .product-detail-card-2 > h3 {
    font-size: 31px;
  }
  .product-detail-card-2 > p {
    font-size: 20 px;
  }
  .product-detail-card-2 > a {
    width: 100%;
  }
  .product-detail-card-2 > button {
    width: 100%;
  }
}

.recommended {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  padding: 30px 100px;
}

.recommended > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
}

.recommended > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.recommended-box {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid rgba(32, 32, 32, 0.4);
}

@media screen and (max-width: 768px) {
  .recommended {
    padding: 10px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 650px) {
  .recommended {
    padding: 10px;
    margin-bottom: 40px;
  }

  .recommended > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
  }

  .recommended-box {
    min-width: 87%;
  }
}

.merchant-form-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 30px 100px;
}

.merchant-form-container > span {
  width: 38%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 100px;
  margin: 30px 0px;
}

.merchant-form-container > span > a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #1f4798;
}

.merchant-form-container > span > h3 {
  color: #1f4798;
  font-family: Strike;
  font-size: 26px;
  font-weight: 700;
}

.merchant-form-container > form {
  width: 38%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.merchant-form-container > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.merchant-form-container > form > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.merchant-form-container > form > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
}

.input-combination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.input-combination > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.input-combination > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-combination > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
}

::placeholder {
  color: lightgray;
  font-style: italic;
}

.merchant-form-container > form > button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin: 5px 0px;
  border-radius: 80px;
  background: #d0300e;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 700;
}

.merchant-form-container > form > button:disabled {
  background-color: #f36c4d;
  cursor: not-allowed;
}

.success-message {
  width: 100%;
  margin-top: 10px;
  color: green;
}

@media screen and (max-width: 768px) {
  .merchant-form-container {
    padding: 10px;
  }

  .merchant-form-container > form {
    width: 100%;
  }

  .merchant-form-container > span {
    width: 100%;
    gap: 50px;
  }
}

@media screen and (max-width: 650px) {
  .merchant-form-container {
    padding: 10px;
  }

  .merchant-form-container > form {
    width: 100%;
  }

  .input-combination {
    gap: 10px;
  }

  .merchant-form-container > span {
    width: 100%;
    gap: 50px;
  }
}

.cart-count {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
  background-color: #ffcd00;
  border-radius: 5px;
  text-decoration: none;
  color: #000;
}

.cart-count > span {
  font-family: Montserrat;
  color: #284697;
  font-weight: 600;
  font-size: 12px;
}

.cart-count > p {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  background-color: white;
  padding: 5px;
  font-size: 12px;
  color: #d0300e;
  border-radius: 100%;
}

.cart-count > a {
  text-decoration: none;
  color: white;
}
.empty-cart-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 90px 100px 20px 100px;
  gap: 20px;
}

.empty-cart-container > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
}

.empty-cart-card {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid rgba(32, 32, 32, 0.8);
  background: #fff;
}

.bag {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.empty-bag {
  width: 500px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.empty-bag > h3 {
  color: #202020;
  font-family: Montserrat Alternates;
  font-size: 24px;
  font-weight: 600;
}

.empty-bag > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.empty-bag > a {
  width: 212px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  background: #d0300e;
}

@media screen and (max-width: 768px) {
  .empty-cart-container {
    height: fit-content;
    padding: 10px;
  }
}

@media screen and (max-width: 650px) {
  .empty-cart-container {
    padding:160px 10px 10px 10px;
    height: fit-content;
  }

  .empty-cart-card {
    height: fit-content;
  }

  .empty-cart-container > h3 {
    font-size: 16px;
  }

  .empty-bag {
    width: 100%;
  }

  .empty-bag > a {
    width: 90%;
  }

  .empty-bag > P {
    font-size: 11px;
  }
}

.cart-list-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 80px 170px 20px 170px;
  background-color: whitesmoke;
}


.cart-card-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
  background: #fff;
  box-shadow: 0px 9px 27px 0px rgba(0, 0, 0, 0.05); 
}

.cart-check {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  border-top: 1px solid #e0e0e0;
  padding: 10px;
}

.cart-con-1 {
  width: 50%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.cart-span-1 {
  width: 34%;
  height: 100%;
}

.cart-span-1 > img {
  width: 70%;
  height: 100%;
  object-fit: cover;
}

.cart-span-2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.cart-span-2 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
}
.cart-span-2 > h4 {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}

.cart-span-2 > h4 > span {
  color: #d0300e ;
}

.cart-span-2 > p {
  color: #202020;
  font-family: Montserrat ;
  font-size: 18px;
  font-weight: 600;
}

.cart-con-2 {
  width: 50%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 80px;
}

.cart-con-2 > span {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cart-con-2 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.add-remove-button {
  width: 132px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
}

.add-remove-button > button {
  display: flex;
  width: 44px;
  height: 42px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: none;
}

.add-remove-button > p {
  display: flex;
  width: 44px;
  height: 42px;
  justify-content: center;
  align-items: center;
  border: 1px solid #eef1f0;
  background: #eef1f0;
  border: none;
}

.cart-con-2 > button {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
}

.order-summary {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px;
  box-shadow: 0px 9px 27px 0px rgba(0, 0, 0, 0.05); 
  background-color: white;
}

.order-summary-card {
  width: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.order-summary-card > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 114%;
}

.order-summary-card > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.order-summary-card > span > P {
  color: #000;
  font-family: Montserrat ;
  font-size: 18px;
  font-weight: 800;
}
.order-summary-card > span > h3 {
  color: #000;
  font-family: Montserrat ;
  font-size: 18px;
  font-weight: 400;
}

.order-summary-card > button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  border-radius: 50px;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  background: #d0300e;
}

.order-summary-card > button:hover {
  background-color: #cf5439;
}

.removal-overlay {
  width:  100% ;
  height: 100%;
  left: 0%;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.2);
}

.removal-overlay > .removing-card {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #fff;
  gap: 10px;
  padding: 10px;
}

.removal-overlay > .removing-card > p {
  width: 100%;
  color:#000;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 400;
  text-align: center;
}
.removal-overlay > .removing-card > h3 {
  color:#000;
  font-size: 20px;
  font-family: Montserrat;
  font-weight:600;
}

.removal-overlay > .removing-card > .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  gap: 10px;
}



.removal-overlay > .removing-card > .btn > button:nth-child(1) {
  width: 120px;
  height: 40px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #284697;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.removal-overlay > .removing-card > .btn > button:nth-child(2) {
  width: 120px;
  height: 40px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.removal-overlay > .removing-card > .btn > button:disabled:nth-child(2) {
  background-color: #ee866f;
  cursor: not-allowed;
}


@media screen and (max-width: 768px) {
  .cart-list-container {
    height: fit-content;
    padding:140px 10px 20px 10px;
    border: none;
 
  }
  
  .cart-con-1 {
    width: 60%;
    height: 150px;
    gap: 10px;
  }
  
  .cart-span-1 > img {
    width: 100%;
    height: 100%;
  }
  .cart-span-2 {
    width: 65%;
    height: 100px;
    gap: 25px;
  }
  
  .cart-span-2 > h3 {
    font-size: 13px;
    font-weight: 500;
  }
}

@media screen and (max-width: 650px) {
  .cart-list-container {
    height: fit-content;
    padding:140px 5px 20px 5px;
    border: none;

  }

  .cart-card-container {
    padding: 10px 5px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background: transparent;
    box-shadow: none;
  }

  .cart-check {
    width: 100%;
    gap: 10px;
    padding:10px  0px;
  }

  .cart-con-1 {
    width: 60%;
    height: 100px;
    gap: 10px;
  }

  .cart-span-1 {
    width: 40%;
    height: 100px;
  }

  .cart-span-1 > img {
    width: 100%;
    height: 100%;
  }
  .cart-span-2 {
    width: 65%;
    height: 100px;
    gap: 25px;
  }

  .cart-span-2 > h3 {
    font-size: 13px;
    font-weight: 400;
  }

  .cart-span-2 > p {
    font-size: 10px;
    font-weight: 800;
  }

  .cart-con-2 {
    width: 40%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }

  .cart-con-2 > span {
    width: 50%;
    gap: 20px;
  }

  .add-remove-button {
    width: 100%;
    height: 32px;
  }

  .add-remove-button > button {
    height: 30px;
  }
  .add-remove-button > p {
    height: 30px;
  }

  .order-sumarry {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    background: transparent;
    padding: 10px;
  }

  .order-summary-card {
    width: 100%;
  }

  .removal-overlay {
    padding: 10px 20px;
  }
}

.shipping-detail-icon {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 0px 100px;
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
}

.shipping-detail-icon > p {
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}
.shipping-detail-icon > h3 {
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}

.shipping-detail-form {
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

.shipping-detail-form > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.shipping-detail-form > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  padding: 30px 20px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.shipping-detail-form > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.shipping-detail-form > form > span > input {
  width: 100%;
  height: 70px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
}

.shipping-detail-form > form > span > label {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}

.input-combination > span > select {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .shipping-detail-wrapper {
    padding: 10px;
  }

  .shipping-detail-form > form {
    width: 100%;
    padding: 10px;
  }

  .shipping-span {
    width: 100%;
  }

  .shipping-detail-icon {
    padding: 10px;
  }
}

@media screen and (max-width: 650px) {
  .shipping-detail-wrapper {
    padding: 10px;
  }

  .shipping-detail-form > form {
    width: 100%;
    padding: 10px;
  }
}

.special-product-con {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.special-product-con > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.special-product-con > span > h3 {
  font-family: Montserrat;
  font-size: 14px;
}

.special-product-con > span > a {
  text-decoration: none;
  color: #d0300e;
  font-family: Montserrat;
  font-size: 12px;
}

.special-product-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
}

.special-product-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  text-decoration: none;
  box-shadow: 0px 0px 2px lightgray;
  background-color: white;
  padding: 15px;
  transition: 0.3s ease-in-out;
}

.special-product-info:hover {
  transform: translateY(-3px);
  box-shadow: 0px 3px 3px lightgray;
  transition: 0.3s ease-in-out;
}

.special-product-info > p {
  font-family: Montserrat;
  color: #000;
}

.special-product-info > h3 {
  font-family: Montserrat;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.special-product-info > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .special-product-card {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 650px) {
  .special-product-card {
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow-x: scroll;
  }
}

.rear-search-input {
  width: 70%;
  display: none;
}

.rear-search-input > form {
  width: 70%;
  height: 50px;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fefefe;
}

.rear-search-input > form > input {
  width: 100%;
  height: 98%;
  border: none;
  outline: none;
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.rear-search-input > form > button {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #d0300e;
  border: none;
  cursor: pointer;
}


.search-suggestions {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding:20px 220px 65px 220px;
  position: fixed;
  flex-direction: column;
  z-index: 100;
  left: 0%;
  top: 68px;
  background: rgba(18, 18, 18, 0.50);
}

.search-suggestion-card {
  width: 56%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  gap: 10px;
  padding: 10px 20px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
  background-color: white;
}

.search-suggestion-card > a {
  text-decoration: none;
}
.search-suggestion-card > a > p {
  font-family: Montserrat;
  color: #000;
}

.search-suggestion-card > span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.search-suggestion-card > span > button {
  background: transparent;
  color: #d0300e;
  border: none;
  outline: none;
} 

.toast {
  width: 28%;
}


@media screen and (max-width: 768px) {
  .rear-search-input {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px;
  }

  .rear-search-input > form {
    width: 100%;
  }

  .search-suggestions {
    padding: 0px;
    top: 150px;
  }

  .search-suggestion-card {
    width: 100%;
  }

.toast {
  width: 100%;
}

.user-mobile-drops {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  top: 70px;
  left: 0%;
  position: absolute;
  padding: 20px 0px;
  background-color:white;
}

.user-mobile-dropdown-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.user-mobile-dropdown-con {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffcd00;
}

.user-mobile-dropdown-con > p {
  font-family: Montserrat;
}

.user-mobile-dropdown-con > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.user-mobile-dropdown-con > span > h3 {
  font-family: Montserrat;
  font-weight: 600;
}



.user-mobile-dropdown-card > .wrapper  {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
  padding: 10px   ;
}

.user-mobile-dropdown-card > a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
}

.user-mobile-dropdown-card > .wrapper > a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  gap: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.user-mobile-dropdown-card > a > h3 {
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffcd00;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.user-mobile-dropdown-card > .wrapper > a > span {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat;
  color: rgba(0, 0, 0, 0.6);
}

.user-mobile-dropdown-card > .wrapper > button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: transparent;
  color:rgba(0, 0, 0, 0.6);
  font-family: Montserrat;
  cursor: pointer;
  border: none;
  gap: 10px;
  font-size: 16px;
}

.user-mobile-dropdown-card > button {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: transparent;
  color: #d0300e;
  font-family: Montserrat;
  font-weight: 600;
  cursor: pointer;
  border: none;
  padding-left: 10px;
}

}

@media screen and (max-width: 650px) {
  .rear-search-input {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px;
  }

  .rear-search-input > form {
    width: 100%;
  }

  .search-suggestions {
    padding: 0px;
  }

  .search-suggestion-card {
    width: 100%;
  }


.toast {
  width: 100%;
}

.user-mobile-drop {
  width: 100%;
  height: 100vh;
  top: 150px;
  left: 0%;
  position: absolute;
  padding: 20px 10px;
  background-color:white;
}

.user-mobile-dropdown-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.user-mobile-dropdown-card > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.user-mobile-dropdown-card > span > h3 {
  font-family: Montserrat;
  font-weight: 600;
}



.user-mobile-dropdown-card > .wrapper  {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
}

.user-mobile-dropdown-card > a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
}

.user-mobile-dropdown-card > .wrapper > a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  gap: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.user-mobile-dropdown-card > a > h3 {
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffcd00;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.user-mobile-dropdown-card > .wrapper > a > span {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat;
  color: rgba(0, 0, 0, 0.6);
}

.user-mobile-dropdown-card > button {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: transparent;
  color: #d0300e;
  font-family: Montserrat;
  font-weight: 600;
  cursor: pointer;
  border: none;
}
}

.help-center-switch {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border-top: 0.5px solid rgba(32, 32, 32, 0.4);
  border-bottom: 0.5px solid rgba(32, 32, 32, 0.4);
  padding: 15px 0px;
}

.help-center-switch > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-center-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.help-center-card > a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
}

.help-center-switch > span > h3 {
  font-family: Montserrat;
  font-weight: 400;
}

.help-center-line {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.help-center-line > a {
  text-decoration: none;
  width: 100%;
  color: #000;
  font-family: Montserrat;
  font-size: 13.5px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  gap: 5px;
  background: #ffcd00;
}

