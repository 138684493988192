* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.all-special-product-con {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 90px 20px 20px 20px;
}

.all-special-product-con > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.all-special-product-con > span > a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
}

.all-special-product-con > span > p {
  font-family: Montserrat;
  color: #d0300e;
  font-size: 16px;
}

.all-special-product-con > h3 {
  font-family: Montserrat;
  font-size: 26px;
}

.all-special-product-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.all-special-product-info {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  text-decoration: none;
  box-shadow: 0px 0px 2px lightgray;
  background-color: white;
  padding: 15px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.all-special-product-info:hover {
  transform: translateY(-3px);
  box-shadow: 0px 3px 3px lightgray;
  transition: 0.3s ease-in-out;
}

.all-special-product-info:hover .image {
  scale: 1.1;
  transition: 0.3s ease-in-out;
}

.all-special-product-info > p {
  font-family: Montserrat;
  color: #000;
}

.all-special-product-info > h3 {
  font-family: Montserrat;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.all-special-product-info > .image {
  width: 100%;
  height: 200px;
  transition: 0.3s ease-in-out;
}

.all-special-product-info > .image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.all-special-product-info > .btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-special-product-info > .btns > button {
  width: 90%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  color: white;
  border: none;
  cursor: pointer;
  background-color: #ffcd00;
  display: none;
}

.all-special-product-info:hover .btns > button {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toast {
  width: 28%;
}



@media screen and (max-width: 768px) {
  .all-special-product-con {
    padding: 160px 10px 10px 10px;
  }

  .all-special-product-card {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .all-special-product-info > .btns > button {
    display: block;
  }

  .toast {
width: 100%;
  }
}


@media screen and (max-width: 650px) {
  .all-special-product-con {
    padding: 160px 10px 10px 10px;
  }
  .all-special-product-card {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .all-special-product-info > .btns > button {
    display: block;
  }

  .all-special-product-con > h3  {
    font-size: 14px;
  }

  .toast {
    width: 100%;
  }
}
