* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.all-product-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding:80px 20px 20px 20px;
  gap: 20px;
  background-color: whitesmoke;
}

.all-product-detail > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  box-shadow: 0px 0px 0px lightgray;
  background-color: white;
}

.all-product-detail > .wrapper > .image {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.all-product-detail > .wrapper > .image > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.all-product-detail > .wrapper > .image > span > .top-image {
  width: 90%;
  height: 400px;
}

.all-product-detail > .wrapper > .image > span > .top-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cascaded-images-container {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.cascaded-images-container > img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border:1px solid #ffcd00;
}



.all-product-info {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
}

.all-product-info > .wrapper {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.all-product-info > .wrapper > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 44px;
  font-weight: 600;
}
.all-product-info > .wrapper > h4 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

.all-product-info > .wrapper > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3rem;
}

.all-product-info > .wrapper > button {
  width: 433px;
  padding: 10px 30px;
  display: flex;
  justify-content:center;
  align-items: center;
  border-radius: 40px;
  background: #d0300e;
  border: none;
  color: white;
  font-size: 16px;
  font-family: Montserrat;
  cursor: pointer;
}

.all-product-detail > .wrapper > .image > .friend {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
}

.all-product-detail > .wrapper > .image > .friend > p {
  font-family: Montserrat;

}

.all-product-detail > .wrapper > .image > .friend > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.all-product-detail > .wrapper > .image > .friend > span > a {
  text-decoration: none;
  color: #000;
}

.all-product-detail > .wrapper > .image > .friend > span > a > .fa-brands {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border:1px solid #E0E0E0;
}

.all-product-detail > .wrapper > .image > .friend > span > a > .fa-brands:hover {
  color:#ffcd00;
}



@media screen and (max-width:768px) {
  .all-product-detail {
    padding:160px 10px 10px 10px ;
  }
  
  .all-product-detail > .wrapper {
    flex-direction: column;
  }

  .all-product-info {
    width: 100%;
    flex-direction: column;
  }

  .all-product-info > .wrapper {
    width: 100%;

  }

  .all-product-info > .wrapper > h3 {
    font-size: 20px;
  }

  .all-product-detail > .wrapper > .image {
    width: 100%;
  }
  
  .all-product-detail > .wrapper > .image > span {
    flex-direction: column-reverse;

  }

  .all-product-detail > .wrapper > .image > span > .top-image {
    width: 100%;
    height: 200px;
  }

  .cascaded-images-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    overflow-x: scroll;
    gap: 10px;

  }

  .all-product-info {
    width: 100%;
  }

  .all-product-info > .wrapper > button{
    width: 100%;
  }
}


@media screen and (max-width:650px) {
  .all-product-detail {
    padding:160px 10px 10px 10px ;
  }
  
  .all-product-detail > .wrapper {
    flex-direction: column;
  }

  .all-product-info {
    width: 100%;
    flex-direction: column;
  }

  .all-product-info > .wrapper {
    width: 100%;

  }

  .all-product-info > .wrapper > h3 {
    font-size: 20px;
  }

  .all-product-detail > .wrapper > .image {
    width: 100%;
  }
  
  .all-product-detail > .wrapper > .image > span {
    flex-direction: column-reverse;

  }

  .all-product-detail > .wrapper > .image > span > .top-image {
    width: 100%;
    height: 200px;
  }

  .cascaded-images-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    overflow-x: scroll;
    gap: 10px;

  }

  .all-product-info {
    width: 100%;
  }

  .all-product-info > .wrapper > button{
    width: 100%;
  }


.all-product-detail > .wrapper > .image > .friend {
  border-top: 0.5px solid rgba(32, 32, 32, 0.4);
  border-bottom: 0.5px solid rgba(32, 32, 32, 0.4);
  padding: 8px 0px;
  margin-top: 15px;
}
}

.description {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  border-top: 0.5px solid rgba(32, 32, 32, 0.4);
  border-bottom: 0.5px solid rgba(32, 32, 32, 0.4);
}

.description > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.description > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3rem;
}

.shipping {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 10px ;
  box-shadow: 0px 0px 5px #e0e0e0;
}

.shipping > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shipping > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0px;
}

.shipping > .shipping-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.shipping > .shipping-wrapper > .fa-solid {
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 2px;
  color: #ffcd00;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shipping > .shipping-wrapper > .info {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.shipping > .shipping-wrapper > .info > h3 {
  font-size: 14px;
  font-family: Montserrat;
  color: #000;
}

.shipping > .shipping-wrapper > .info > p {
  font-size: 14px;
  font-family: Montserrat;
  color: #000;
}

.shipping > .shipping-wrapper > .info > a {
  font-size: 14px;
  font-family: Montserrat;
  color: #284697;
  text-decoration: none;
}

.shipping > .shipping-wrapper > .info > a:hover {
  color: #d0300e;
}

.shipping > .shipping-wrapper > .info > button {
  font-size: 14px;
  font-family: Montserrat;
  color: #284697;
  outline: none;
  border: none;
  cursor: pointer;
  background: transparent;
}

.shipping > .shipping-wrapper > .info > button:hover {
  color: #d0300e;
}

.toast {
  width: 28%;
  font-family: Montserrat;
}

@media screen and (max-width:768px) {
  .description {
    width: 100%;
  }

  .shipping {
    width: 100%;
  }

  .toast {
    width: 100%;
  } 
}

@media screen and (max-width:650px) {
  .description {
    width: 100%;
  }

  .shipping {
    width: 100%;
  }

  .toast {
    width: 100%;
  }
}

.customer-review {
  width: 100%;
  height: 300px;
  padding: 20px;
  gap: 20px;
  box-shadow: 0px 0px 0px lightgray;
  background-color: white;
}

.customer-review > h3 {
  font-size: 14px;
  font-family: Montserrat;
  color: #000;
}

.shipping-detail-overlay {
  width:  100% ;
  height: 100%;
  left: 0%;
  position: fixed;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(18, 18, 18, 0.50);
}

.shipping-detail-overlay-card {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 5px;
  background-color:white;
}

.shipping-detail-overlay-card > h3 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  color:grey;
}
.shipping-detail-overlay-card > h4 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  color:grey;
}
.shipping-detail-overlay-card > p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
}

.shipping-detail-overlay-card > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.shipping-detail-overlay-card > span > h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}
.shipping-detail-overlay-card > span > button {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  outline: none;
  border: none;
}

.shipping-detail-overlay-card > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shipping-detail-overlay-card > .wrapper > p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
}
.shipping-detail-overlay-card > .wrapper > h3 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
}

@media screen and (max-width:650px) {
  .shipping-detail-overlay-card {
    width: 95%;
  }
}




