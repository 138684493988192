* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  .international-shipping {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 40px 310px;
  }

  .international-shipping > h3 {
    font-family: Montserrat;
    font-size: 40px;
    color: #000;
  }

  @media screen and (max-width:768px) {
    .international-shipping {
        padding: 10px;
    }
  }
  @media screen and (max-width:650px) {
    .international-shipping {
        padding: 10px;
    }

    .international-shipping > h3 {
      font-size: 16px;
    }
  }