* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.charter-service {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 100px;
}

.charter-service > h3 {
  font-family: Montserrat;
  color: #d0300e;
  font-size: 24px;
}
.charter-service > p {
  width: 50%;
  font-family: Montserrat;
  color: #000;
  font-size: 16px;
  text-align: center;
  line-height: 1.5rem;
  margin-top: 10px;
}

.charter-service > form {
  width: 62%;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 30px;
  margin: 50px 0px;
  gap: 5px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}


.charter-service > form > select {
  width: 100%;
  outline: none;
  font-family: Montserrat;
  font-size:18px;
  font-weight: 400;
  color: #000;
  border: none;
}

.charter-service > form > button {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d0300e;
  border: none;
  cursor: pointer;
}

.charter-service > form > .btn > button > svg {
  width: 18px;
  height: 18px;
}

.charter-category {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.charter-category > h3 {
  font-family: Montserrat;
  font-size: 20px;
}

.charter-category > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.charter-category > span > a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  border-radius: 10px;
  border: 0.5px solid #000;
}

.charter-category > span > a > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.charter-category > span > a > h3 {
  width: 100%;
  font-family: Montserrat;
  font-size: 16px;
  color: #000;
  background-color: whitesmoke;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px 0px;
}

@media screen and (max-width:768px) {
    .charter-service {
        padding: 10px;
      }
    
      .charter-service > p {
        width: 100%;
      }
    
      .charter-service > form {
        width: 100%;
      }

      .charter-category > span {
        gap: 10px;
      }
}

@media screen and (max-width: 650px) {
  .charter-service {
    padding: 10px;
  }

  .charter-service > p {
    width: 100%;
  }

  .charter-service > form {
    width: 100%;
    border-radius: 10px;
  }

  .charter-category > span {
    gap: 10px;
    flex-direction: column;
  }

}

.search-charter-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  top: 0%;
  left: 0%;
  position: fixed;
  z-index:101;
  padding: 50px 20px;
  background: rgba(32, 32, 32, 0.50);
}

.search-charter-overlay > span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding:0px 20px;
}

.search-charter-overlay > span > button {
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 30px;
}

.search-charter-overlay > .card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 20px 0px;
}

.charter-search-results {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  background: white;
  padding: 20px;
}

.charter-search-results > .charter-result-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
  gap: 15px;
}

.charter-search-results > .charter-result-item > p {
  color: #000;
  font-family: Montserrat;
}
.charter-search-results > .charter-result-item > h4 {
  color: #000;
  font-family: Montserrat;
}

.charter-search-results > .charter-result-item > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.search-charter-overlay > .wrapper {
  width: 100%;
  background-color: white;
  padding: 20px;
}

@media screen and (max-width:896px) {
  .charter-search-results {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
  }

  .search-charter-overlay {
    overflow-y: scroll;
    padding: 10px;
  }

  .search-charter-overlay > .wrapper {
    padding: 10px;
  }
}


