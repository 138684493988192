* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.product-category-mothers {
  width: 100%;
  padding:70px 20px;
}
.product-category-mother {
  width: 100%;
  height: 100vh;
  padding:0px 20px;
}

.category-product-infos {
  width: 100%;
  margin-top: 60px;
}

.product-category-mothers > .heading {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 0px;
  gap: 30px;
}

.product-category-mothers > .heading > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}


.product-category-mothers > .heading > .wrapper > a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
}
.product-category-mothers > .heading > .wrapper > p {
  text-decoration: none;
  color: #D0300E;
  font-family: Montserrat;
}

.product-category-mother > .heading > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-category-mothers > .heading > span > h3 {
  font-family: Montserrat;
  color: #284697;
}

.product-category-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 20px;
}

.category-product-info {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px 0px;
  gap: 20px;
  box-shadow: 0px 0px 2px lightgray;
  background-color:white;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  border-radius: 3px;
}

.category-product-info:hover {
  transform: translateY(-5px);
  box-shadow: 0px 3px 3px lightgray;
  transition: 0.3s ease-in-out;
}


.category-product-info:hover .image {
  scale: 1.1;
  transition: 0.3s ease-in-out;
}

.category-product-info > .image {
  text-decoration: none;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.category-product-info > .image > img {
  width: 90%;
  height: 250px;
  object-fit: cover;
}

.category-product-info > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 15px;
  gap: 10px;
}

.category-product-info > span > h3 {
font-family: Montserrat;
font-size: 14px;
color: #000;
}
.category-product-info > span > p {
font-family: Montserrat;
font-size: 14px;
color: #000;
}
.category-product-info > p {
font-family: Montserrat;
font-size: 14px;
color: #000;
padding: 0px 15px;
}

.category-product-info > .btnn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.category-product-info > .btnn > button {
  width: 90%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  color: white;
  border: none;
  cursor: pointer;
  background-color:  #FFCD00;
  display: none;
}

.category-product-info:hover .btnn > button {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toast {
  width: 28%;
}

.loading-more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width:896px) {
  .product-category-mothers {
    padding: 160px 10px 10px 10px ;
  }

  .product-category-mother {
    width: 100%;
    height: fit-content;
    padding: 10px;
  }

  .category-product-infos {
    margin-top: 160px;
    width: 95%;
  }

  .product-category-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }

  .category-product-info > .btnn > button {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toast {
    width: 100%;
  }
}




