* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.support-message-container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.support-message-container > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.support-message-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.public-msg-table {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}
.public-msg-table > .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.public-msg-table > .header > span {
  display: flex;
  gap: 5px;
}

.public-msg-table > .header > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.public-msg-table > .header > span > input {
  margin-left: 20px;
}

.public-msg-table > .header > span > .icon {
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;

}

th {
  border-bottom: 1px solid #e0e0e0;
  background: #f5f5f5;
  font-family: Montserrat;
  color: #202020;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  padding: 15px;
}

td {
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding: 15px;
  text-align: start;
}

td > button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: green;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
}

td > a {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.overlay-msg,
.delete-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0%;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 101;
}

.overlay-msg > .card {
  width: 850px;
  flex-shrink: 0;
  background: #fff;
}

.overlay-msg > .card > .header {
  height: 85px;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  font-family: Montserrat;
}

.overlay-msg > .card > .content-box {
  width: 100%;
  padding: 20px 30px;
}

.overlay-msg > .card > .content-box > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
}

.overlay-msg > .card > .btn {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.overlay-msg > .card > .btn > button {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #d0300e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-weight: 700;
}

.delete-overlay > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.delete-overlay > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.delete-overlay > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.delete-overlay > .card > .btn {
  display: flex;
  align-items: center;
  gap: 15px;
}

.delete-overlay > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.delete-overlay > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: #d0310e;
  font-weight: 700;
}

.closemsg-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 101;
}

.closemsg-overlay > .card {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 35px;
  padding: 40px;
  border-radius: 20px;
  background-color: white;
}

.closemsg-overlay > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.closemsg-overlay > .card > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.closemsg-overlay > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.closemsg-overlay > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: #d0310e;
  font-weight: 700;
}
