* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.login {
  width: 93px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 38px;
  border: 1px solid black;
  font-weight: bold;
  color: black;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}

.log-in-form {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.log-in-form > .card-1 {
  width: 50%;
  height: 100vh;
  background-position: center;
  background-repeat: no repeat;
  background-size: cover;
  background-image: url("https://res.cloudinary.com/dmk6amr96/image/upload/v1739442108/GetPaidStock.com_-67a4db21c26e8_1_nsscal.jpg");
}

.log-in-form > .card-1 > .login-image-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.3);
  padding: 100px 0px 0px 50px;
}

.log-in-form > .card-1 > .login-image-overlay > p {
  color: #ffffff;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 42px;
  line-height: 55.02px;
  /* letter-spacing: -2%; */
  width: 512px;
}

.log-in-form > .card-2 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 150px 0px 150px;
}
.log-in-form > .card-2 > h3 {
  color: #1f4798;
  font-family: Strike;
  font-size: 26px;
  font-weight: 700;
  margin: 30px 0px;
}

.log-in-form > .card-2 > form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.log-in-form > .card-2 > form > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.log-in-form > .card-2 > form > p > span {
  color: #d0310e;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
}
.log-in-form > .card-2 > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.log-in-form > .card-2 > form > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.pass {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
}

.log-in-form > .card-2 > form  > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
}

.log-in-form > .card-2 > form > a {
  width: 100%;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: #d0310e;
  font-family: Montserrat;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}

.log-in-form > .card-2 > form > button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin: 5px 0px;
  border-radius: 80px;
  background: #d0300e;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 700;
}

.log-in-form > .card-2 > form > button:disabled {
  background-color: #b5b5b5;
  cursor: not-allowed;
}

.login-token-request {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.login-token-request > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
}

.login-token-request > button {
  background: transparent;
  border: none;
  color: #d0310e;
  font-family: Montserrat;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .login {
    display: none;
  }

  .log-in-form {
    flex-direction: column;
  }

  
  .log-in-form > .card-1 {
    width: 100%;
    display: none;
   }
   
 .log-in-form > .card-1 > .login-image-overlay {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
 }

 .log-in-form > .card-1 > .login-image-overlay > p {
  width: 100%;
  font-size: 40px;
  line-height: 2.9rem;
  font-family: Strike;
 }

 .log-in-form > .card-2 {
  width: 100%;
  padding: 10px;
 }
}

@media screen and (max-width: 650px) {
  .login {
    display: none;
  }

  .log-in-form {
    flex-direction: column;
  }

  
 .log-in-form > .card-1 {
  width: 100%;
  display: none;
 }

 .log-in-form > .card-1 > .login-image-overlay {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
 }

 .log-in-form > .card-1 > .login-image-overlay > p {
  width: 100%;
  font-size: 20px;
  line-height: 1.9rem;
  font-family: Strike;
 }

 
 .log-in-form > .card-2 {
  width: 100%;
  padding: 10px;
 }
  .log-in-form > .card-2 > form > button {
    margin-top: 30px;
  }
}
