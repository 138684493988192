* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }


  .logistic-package-receipt {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  

  .logistic-package-receipt > .header {
    width: 100%;
    height: 100px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 358px;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  
  

  .logistic-package-receipt > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  

  .logistic-package-receipt > .header > .in {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  

  .logistic-package-receipt > .header > .in > span {
    display: flex;
    gap: 10px;
    width: 280px;
    height: 44px;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(32, 32, 32, 0.6);
    padding: 20px;
  }
  

  .logistic-package-receipt > .header > .in > span > input {
    width: 100%;
    border: none;
    outline: none;
    font-family: Montserrat;
  }


  .logistic-package-receipt > .header > .in > .btn {
    display: flex;
    gap: 10px;
  }
   


  .logistic-package-receipt > .header > .in > button {
    height: 43px;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 66px;
    background:#284697;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    border: none;
  }
  

  .logistic-package-receipt > .package-receipt-table {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  
  .logistic-package-receipt > .package-receipt-table > table {
    width: 100%;
  }
  
  .logistic-package-receipt > .package-receipt-table > table > thead > tr > th {
    color: #202020;
    font-size: 12px;
    font-weight: 600;
    text-align: start;
    font-family: Montserrat;
    padding: 10px 3px;
  }
  
  .logistic-package-receipt > .package-receipt-table > table > tbody > tr > td {
    color: #202020;
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-align: start;
    padding: 10px 3px;
  }
  
  .logistic-package-receipt > .package-receipt-table
    > table
    > tbody
    > tr
    > td
    > .action {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .logistic-package-receipt > .package-receipt-table
    > table
    > tbody
    > tr
    > td
    > .action
    > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  

  .loading-more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .receipt-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: rgba(18, 18, 18, 0.5);
  }

  .receipt-overlay > .receipt-details {
    display: flex;
    width: 500px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    background: #fff;
  }
  
  .receipt-overlay > .receipt-details > h2 {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 800;
  }
  
  .receipt-overlay > .receipt-details > p {
    color: #202020;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 118.243%;
  }
  
 
  
  /* .logistic-delete-broadcast-message > .wrapper > span > button:nth-child(1) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    cursor: pointer;
    font-weight: 700;
  }
  
  .logistic-delete-broadcast-message > .wrapper > span > button:nth-child(2) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #189b48;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  } */

 
  
