*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

@font-face {
    font-family: Strike;
    src: url('/public/ProtestStrike-Regular.ttf');
  }
  
  @font-face {
    font-family: Montserrat;
    src: url('/public/Montserrat-VariableFont_wght.ttf');
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url('/public/MontserratAlternates-Regular.ttf');
  }
  

  .not-found-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 90px 50px;
  }
  
  .not-found-container h1 {
    font-size: 100px;
    color: #d0300e;
    margin: 0;
    font-family: Strike;
  }
  
  .not-found-container h2 {
    font-size: 36px;
    color: #333;
    font-family: Montserrat;
  }
  
  .not-found-container p {
    font-size: 18px;
    color: #666;
  }

  .not-found-container > button{
    padding: 10px 30px;
    border: 1px solid #666;
    color: #333;
    cursor: pointer;
    outline: none;
    font-family: Montserrat;
  }

  @media screen and (max-width:896px) {
    .not-found-container h1 {
        font-size: 30px;
    }

    .not-found-container h2 {
        font-size: 20px;
    }

    .not-found-container p {
        text-align: center;
        line-height: 1.4rem;
    }
  }
  