* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.business-details {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: red; */
}

.business-details > .card-1 {
  width: 50%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://res.cloudinary.com/dmk6amr96/image/upload/v1739442108/GetPaidStock.com_-67a4db21c26e8_1_nsscal.jpg");
}

.business-details > .card-1 > .login-image-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.3);
  padding: 100px 0px 0px 50px;
}

.business-details > .card-1 > .login-image-overlay > p {
  color: #ffffff;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 42px;
  line-height: 55.02px;
  width: 512px;
}

.business-details > .card-2 {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 100px 0px 100px;
}

.business-details > .card-2  > p {
    color: #1f4798;
    font-size: 24px;
    font-family: Strike;
    font-weight: 400;
    margin: 30px 0px;
}

.business-details > .card-2 > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.business-details > .card-2 > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
}

.business-details > .card-2 > span .icon {
  font-size: 24px;
}

.business-details > .card-2 > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.business-details > .card-2 > form > .input-combination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.business-details > .card-2 > form > .input-combination > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.business-details > .card-2 > form > .input-combination > span  > label {
  color: #1f4798;
  font-size: 14px;
  font-family: Strike;
  font-weight: 400;
}

.business-details > .card-2 > form > .input-combination > span  > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  font-family: Montserrat;
  color: #202020;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
}

.business-details > .card-2 > form  > button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    margin: 5px 0px;
    border-radius: 80px;
    background: #d0300e;
    cursor: pointer;
    color: #fff;
    font-family: Montserrat Alternates;
    font-size: 16px;
    font-weight: 700;
}

.business-details > .card-2 > form > button:disabled {
    background-color: #f36c4d;
    cursor: not-allowed;
  }


  @media screen and (max-width:896px) {
    
.business-details > .card-1 {
    display: none;
}


.business-details > .card-2 {
    width: 100%;
    min-height: 100vh;
    padding: 10px;
}

.business-details > .card-2 > form > .input-combination {
    flex-direction: column;
}
}