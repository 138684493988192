* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.track-your-order {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 90px 0px 0px 0px;
}

.track-your-order > img {
  width: 150px;
  height: 50px;
  object-fit: cover;
  margin: 40px 0px;
}

.track-your-order > h3 {
  font-family: Montserrat;
  font-size: 24px;
  color:  #284697;
}

.track-your-order > form {
  width: 50%;
  height: 50px;
  display: flex;
  padding:10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  border: 1px solid #e0e0e0;
  background: #fefefe;
  margin-top: 50px;
}

.track-your-order form > input {
  width: 100%;
  height: 98%;
  border: none;
  outline: none;
  background: transparent;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.track-your-order form > button {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #d0300e;
  border: none;
  cursor: pointer;
}

@media screen and (max-width:896px) {
  .track-your-order {
    padding: 20px 10px 0px 10px;
  }

  .track-your-order > form {
    width: 100%;
  }
}

