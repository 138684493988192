* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.merchant-account {
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 80px 100px 50px 100px;
  border-bottom: 1px solid #e0e0e0;
}

.merchant-account > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.merchant-account-card {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 0px;
}

.merchant-account-card > .wrapper {
  width: 885px;
  min-height: 90vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* background-color: pink; */
}

.merchant-account-card > .wrapper > span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0px;
}

.merchant-account-card > .wrapper > span > button {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
  border: none;
}

.merchant-account-card > .wrapper > span > button:focus {
  color: #d00e0e;
}

@media screen and (max-width:768px) {
  .merchant-account {
    padding:80px 30px 35px 10px;
  }

  .merchant-account-card {
    padding: 10px;
  }

  .merchant-account-card > .wrapper {
   width: 100%;
  }
  .merchant-account-card > .wrapper > span {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-bottom:1px solid #e0e0e0 ;
    padding-bottom: 10px;
    margin: 10px 0px;
  }
}
@media screen and (max-width:650px) {
  .merchant-account {
    padding:80px 30px 35px 10px;
  }

  .merchant-account-card {
    padding: 10px;
  }

  .merchant-account-card > .wrapper {
   width: 100%;
  }
  .merchant-account-card > .wrapper > span {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-bottom:1px solid #e0e0e0 ;
    padding-bottom: 10px;
    margin: 10px 0px;
  }
}

.merchant-account-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.merchant-account-box > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merchant-account-box > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.merchant-logout-button {
  width: 100%;
  display: flex;
}

.merchant-logout-button > button {
  color: #ab0808;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  background: transparent;
}

.merchant-business-info-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.merchant-business-info-card > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.merchant-business-info-card > form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  margin-top: 30px;
  column-gap: 10px;
  row-gap: 10px;
}

.merchant-business-info-card > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.merchant-business-info-card > form > span > label {
  color: rgba(32, 32, 32, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.merchant-business-info-card > form > span > input {
  width: 320px;
  height: 42px;
  padding: 0px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: transparent;
  outline: none;
  border: 1px solid #e0e0e0;
  color: rgba(32, 32, 32, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}

.merchant-business-info-card > form > button {
  width: 320px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 66px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  margin-top: 22px;
}

.merchant-business-info-card > form > button:disabled {
  background-color: #f36c4d;
  cursor: not-allowed;
}

.merchant-password-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.merchant-password-card > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.merchant-password-card > form {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.merchant-password-card > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.merchant-password-card > form > span > label {
  color: rgba(32, 32, 32, 0.6); 
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}

 .merchant-pass {
width: 100%;
height: 45px;
display: flex;
align-items: center;
border: 1px solid #e0e0e0;
border-radius: 20px;
padding: 10px;
margin: 5px 0px;
}

 .merchant-pass > input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: none;
  color: rgba(32, 32, 32, 0.6);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}

.merchant-password-card > form > button {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 66px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  margin-top: 22px;
}

.merchant-password-card > form > button:disabled {
  background-color: #f36c4d;
  cursor: not-allowed;
}

@media screen and (max-width:768px) {
  .merchant-password-card > form {
    width: 100%;
  }
}
@media screen and (max-width:650px) {
  .merchant-password-card > form {
    width: 100%;
  }
}
