* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }

  .all-local-shipping-cost {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .all-local-shipping-cost > .header {
    width: 100%;
    height: 100px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 358px;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  
  
  .all-local-shipping-cost > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  
  .all-local-shipping-cost > .header > .in {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .all-local-shipping-cost > .header > .in > span {
    display: flex;
    gap: 10px;
    width: 280px;
    height: 44px;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(32, 32, 32, 0.6);
    padding: 20px;
  }
  
  .all-local-shipping-cost > .header > .in > span > input {
    width: 100%;
    border: none;
    outline: none;
    font-family: Montserrat;
  }

  .all-local-shipping-cost > .header > .in > .btn {
    display: flex;
    gap: 10px;
  }
  
  .all-local-shipping-cost > .header > .in > .btn > button:nth-child(1) {
    height: 43px;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 66px;
    background: #d0300e;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    border: none;
  }

  

  .all-local-shipping-cost > .header > .in > .btn > button:nth-child(2) {
    height: 43px;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 66px;
    background:#284697;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    border: none;
  }
  
  .all-local-shipping-cost > .shipping-cost-table {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  
  .all-local-shipping-cost > .shipping-cost-table > table {
    width: 100%;
  }
  
  .all-local-shipping-cost > .shipping-cost-table > table > thead > tr > th {
    color: #202020;
    font-size: 12px;
    font-weight: 600;
    text-align: start;
    font-family: Montserrat;
    padding: 10px 3px;
  }
  
  .all-local-shipping-cost > .shipping-cost-table > table > tbody > tr > td {
    color: #202020;
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-align: start;
    padding: 10px 3px;
  }
  
  .all-local-shipping-cost > .shipping-cost-table
    > table
    > tbody
    > tr
    > td
    > .action {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .all-local-shipping-cost > .shipping-cost-table
    > table
    > tbody
    > tr
    > td
    > .action
    > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .local-shipping-cost-delete-over-container {
  position: fixed;
  height: 100%;
  top: 0%;
  right: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 100;
}

.local-shipping-cost-delete-over-container > .delete-card {
  width: 30%;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.local-shipping-cost-delete-over-container> .delete-card > span {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.local-shipping-cost-delete-over-container > .delete-card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.local-shipping-cost-delete-over-container > .delete-card > .btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.local-shipping-cost-delete-over-container > .delete-card > .btn > button:nth-child(1) {
  width: 120px;
  height: 40px;
  text-align: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.local-shipping-cost-delete-over-container > .delete-card > .btn > button:nth-child(2) {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border: none;
  cursor: pointer;
}


.local-shipping-cost-edits-over-container {
  position: fixed;
  height: 100%;
  top: 0%;
  right: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 100;
}


.local-shipping-cost-edits-over-container > .card {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 10px 20px;
}


.local-shipping-cost-edits-over-container> .card > label {
  width: 320px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.local-shipping-cost-edits-over-container > .card > label > input {
  display: flex;
  width: 320px;
  height: 42px;
  padding: 0px 10px;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  outline: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.local-shipping-cost-edits-over-container > .card > .btn {
  display: flex;
  height: 60px;
  align-items: flex-end;
  gap: 20px;
}


.local-shipping-cost-edits-over-container > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}


.local-shipping-cost-edits-over-container > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-weight: 700;
}
  