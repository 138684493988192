* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }

.register-freight-container {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.register-freight-container  > .header {
    width: 100%;
    display: flex;
    padding: 20px;
    gap: 10px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  
  .register-freight-container > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  
  .register-freight-container  > .header > .icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .register-freight-container  > .manager-register-form {
    width: 100%;
    padding: 20px;
  }
  
 .manager-register-form > form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 20px;
  }
  
  .manager-register-form > form > label {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .manager-register-form > form > label > input {
    width: 320px;
    height: 42px;
    padding-left: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid #e0e0e0;
    outline: none;
  }
  .manager-register-form > form > label > .inner {
    display: flex;
  }
  
  .manager-register-form> form > label > .inner select {
    height: 42px;
    border: 1px solid #e0e0e0;
    color: #202020;
    font-family: Montserrat;
  }
  .manager-register-form > form > label > .inner > input {
    width: 260px;
    height: 42px;
    padding-left: 10px;
    align-items: center;
    gap: 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid #e0e0e0;
    outline: none;
  }
  
  .manager-register-form > form > label > select {
    width: 320px;
    height: 42px;
    padding-left: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid #e0e0e0;
    outline: none;
    color: #202020;
    font-family: Montserrat;
  }

  
  .manager-register-form > form > button {
    width: 320px;
    height: 43px;
    align-items: center;
    gap: 10px;
    border-radius: 66px;
    background: #284697;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    border: none;
    cursor: pointer;
  }
  
  .manager-register-form > form > button:disabled {
    background-color: #4c73dc;
    cursor: not-allowed;
  }
  
  .manager-register-form> form > .error-message {
    color: #d0300e;
    font-family: Montserrat;
    font-size: 20px;
  }
  
  .manager-register-form > form > .success-message {
    color: green;
    font-family: Montserrat;
    font-size: 20px;
  }
  