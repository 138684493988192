* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  list-style-type: none;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.admin-chat-container {
  padding-top: 100px;
  width: 81.9%;
  min-height: 100vh;
  margin-left: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.admin-chat-container > .header {
  display: flex;
  width: 100%;
  height: 80px;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.admin-chat-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.admin-chat-container > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
  padding: 0px 20px;
}

.admin-chat-container > .wrapper > .over-view-box {
  width: 204px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.admin-chat-container > .wrapper > .over-view-box > h3 {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
}
.admin-chat-container > .wrapper > .over-view-box > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
}

.recent-message {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 40px;
}

.recent-message > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0px  20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.recent-message > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
}

.recent-message > span > a {
  text-decoration: none;
  color: #d0300e;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.recent-message-card {
  width: 100%;
  padding: 0px 20px;
}
