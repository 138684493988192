* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.shipping-detail-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding:90px 100px 40px 100px;
  gap: 20px;
}

.shipping-detail-wrapper > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.shipping-detail-wrapper > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.shipping-details-icon {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.shipping-details-icon > p {
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}
.shipping-details-icon > h3 {
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}
.shipping-details-icon > a {
  color:#284697;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}

.shipping-details-icon > a:hover {
  color:  #d0300e;
}

.shipping-span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.shipping-details-form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

.shipping-details-form > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  padding: 30px 20px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.shipping-details-form > form > .wrapper {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.shipping-details-form > form > .wrapper > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.shipping-details-form > form > .wrapper > span > input {
  width: 100%;
  height: 70px;
  border-radius: 5px;
  border: 1px solid black;
  background: transparent;
  outline: none;
  font-size: 15px;
  font-family: Montserrat;
  padding-left: 10px;
}

.shipping-details-form > form > .wrapper > span > label {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}
.shipping-details-form > form > .wrapper > .input-market-combination > span > label {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}

.input-market-combination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.input-market-combination > span {
  width: 100%;
}

.input-market-combination > span > select {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid black;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  padding: 7px;
}

.input-market-combination > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid black;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  padding-left: 10px;
}

.payment-together {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-payment-wrapper {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.cart-list-payment {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.cart-card-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-check-payment {
  width: 100%;
}

.cart-payment-con {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.cart-payment-span-1 {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.cart-payment-span-1 > img {
  width: 50px;
  height: 50px;
}

.cart-payment-span-1 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
}

.cart-payment-span-2 {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.cart-payment-span-2 > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.cart-payment-sumarry {
  width: 100%;
}

.order-payment-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.order-payment-card > span {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

.order-payment-card > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.order-payment-card > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}

.payment-container {
  width: 100%;
}

.payment-together > button {
  width: 100%;
  height: 50px;
  border-radius: 60px;
  background: #b5b5b5;
  border: none;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width:768px) {
  .shipping-detail-wrapper {
    padding:165px 10px 20px 10px;
    height: fit-content;
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .shipping-detail-wrapper {
    padding:165px 10px 20px 10px;
    height: fit-content;
    flex-direction: column;
  }

  .shipping-span {
    flex-direction: column;
  }

  .payment-together {
    width: 100%;
  }

  .cart-payment-wrapper {
    width: 100%;
  }

  .shipping-details-form {
    width: 100%;
    height: fit-content;
  }
  
  .shipping-details-form > form {
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }

  .shipping-details-form > form > .wrapper {
    width: 100%;
  }

  .shipping-details-icon {
    padding: 0px;
  }
}


.shipping-payment-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  flex-direction: column;
  background-color:  rgba(0, 0, 0, 0.5);
  top:0%;
  z-index: 101;
  padding: 100px;
}

.shipping-payment-overlay > .card {
  width: 400px;
  height: 300px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
