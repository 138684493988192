* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super-admin-broadcast {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.super-admin-broadcast > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.super-admin-broadcast > .header > .icon{
    cursor: pointer;
}

.super-admin-broadcast > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.super-admin-broadcast > .broadcast-card {
  width: 70%;
  margin-top: 30px;
}

.super-admin-broadcast > .broadcast-card > p {
    font-family: Montserrat;
    color: green;
}

.super-admin-broadcast > .broadcast-card  > .error-message {
    font-family: Montserrat;
    color: red;
}

.super-admin-broadcast > .broadcast-card > textarea {
  width: 100%;
  min-height: 300px;
  padding: 15px;
  outline: none;
  font-family: Montserrat;
  border-radius: 10px;
  border: 1px solid rgba(32, 32, 32, 0.40);
}

.super-admin-broadcast > .broadcast-card > button {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #d0300e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.super-admin-broadcast > .broadcast-card > button:disabled{
    background: #e55f41;
    cursor: not-allowed;
}
