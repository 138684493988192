* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  list-style-type: none;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.admin-chat-container {
  padding-top: 100px;
  width: 81.9%;
  min-height: 100vh;
  margin-left: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.admin-chat-container > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.admin-chat-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.admin-chat-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
}

.admin-chat-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.admin-chat-info > li {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin-chat-info > li > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.admin-chat-info > li > span > p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.admin-chat-info > li > p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7rem;
}

.admin-chat-info > li > .button-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.admin-chat-info > li > .button-card > button {
  padding: 10px;
  border: none;
  display: flex;
  width: 100px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 38px;
  font-family: Montserrat;
  color: #fff;
  background: #d0300e;
  cursor: pointer;
}

.delete-broadcast-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 0%;
  position: fixed;
  background: rgba(32, 32, 32, 0.7);
  z-index: 101  ;
}

.delete-broadcast-message > .wrapper {
  width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
  gap: 15px;
  background: #fff;
}

.delete-broadcast-message > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.delete-broadcast-message > .wrapper > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5rem;
}

.delete-broadcast-message > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.delete-broadcast-message > .wrapper > span > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.delete-broadcast-message > .wrapper > span > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: #d0310e;
  font-weight: 700;
}
