* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.suscriber {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 40px 100px;
}

.suscriber > h3 {
  width: 35%;
  font-family: Montserrat;
  color: #d0300e;
  font-size: 24px;
  text-align: center;
}
.suscriber > p {
  font-family: Montserrat;
  color: #000;
  font-size: 16px;
  text-align: center;
  line-height: 1.5rem;
  margin-top: 10px;
}

.suscriber > form {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
  gap: 20px;
}

.suscriber > form > .suscriber-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.suscriber > form > .passport {
  width: 150px;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(45, 69, 150, 0.8);
  background: rgba(233, 244, 255, 0.7);
  position: relative;
  height: 150px;
  overflow: hidden;
}

.suscriber > form .passport input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.suscriber > form .passport-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.suscriber > form > .passport > svg {
  font-size: 40px;
}

.suscriber > form > .passport > h3 {
  font-family: Montserrat;
  font-size: 11px;
}
.suscriber > form > .passport > p {
  font-family: Montserrat;
  font-size: 9px;
}

.suscriber > form > .suscriber-card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.suscriber > form > .suscriber-card > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.suscriber > form > .suscriber-card > span > input {
  width: 100%;
  height: 50px;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.suscriber > form > button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin: 5px 0px;
  border-radius: 80px;
  background: #d0300e;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 896px) {
  .suscriber {
    padding: 10px;
  }

  .suscriber > h3 {
    width: 100%;
    font-size: 16px;
  }

  .suscriber > form {
    width: 100%;
  }

  .suscriber > form > .suscriber-card > span > label {
    font-size: 12px;
  }
}
