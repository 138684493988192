* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.sort-center-pkg {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.sort-center-pkg > .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  gap: 358px;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.sort-center-pkg > .header > .in {
  display: flex;
  align-items: center;
  gap: 15px;
}

.sort-center-pkg > .header > .in > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.sort-center-pkg > .header > .in > button {
  display: flex;
  padding: 6px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  cursor: pointer;
}

.sort-center-pkg > .header > span {
  display: flex;
  width: 280px;
  height: 44px;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
  gap: 10px;
}

.sort-center-pkg > .header span > input {
  width: 100%;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  background: #f9f9f9;
}

.sort-center-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assigned-sort-center {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.assigned-sort-center > .header {
  display: flex;
  justify-content: space-between;
  width: 81.9%;
  height: 100px;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  position: fixed;
  top: 10%;
  right: 0%;
}

.assigned-sort-center > .header  .inner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.assigned-sort-center > .header > .inner > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.assigned-sort-center > .header > button {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  border: none;
}


.assigned-sort-center > .task-container {
  width: 100%;
 padding: 100px 20px 0px 20px;
}


.sortcenter-assigned-over {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(18, 18, 18, 0.5);
  z-index: 100;
}

.sortcenter-assigned-over > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}


.sortcenter-assigned-over > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}


.sortcenter-assigned-over > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}


.sortcenter-assigned-over > .card > .btn {
  display: flex;
  gap: 20px;
}

.sortcenter-assigned-over > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
}

.sortcenter-assigned-over> .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #189b48;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}


