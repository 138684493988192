
* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
    list-style-type: none;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

.logistic-broadcast-msg {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f9f9f9;
  }
  
  .logistic-broadcast-msg > .header {
    display: flex;
    width: 100%;
    height: 100px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  
  .logistic-broadcast-msg > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }

  .logistic-delete-broadcast-message {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: rgba(18, 18, 18, 0.5);
  }
  .logistic-delete-broadcast-message > .wrapper {
    display: flex;
    padding: 25px 50px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    background: #fff;
  }
  
  .logistic-delete-broadcast-message > .wrapper > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 800;
  }
  
  .logistic-delete-broadcast-message > .wrapper > p {
    color: #202020;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 118.243%;
  }
  
  .logistic-delete-broadcast-message > .wrapper > span  {
    display: flex;
    gap: 20px;
  }
  
  .logistic-delete-broadcast-message > .wrapper > span > button:nth-child(1) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    cursor: pointer;
    font-weight: 700;
  }
  
  .logistic-delete-broadcast-message > .wrapper > span > button:nth-child(2) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #189b48;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }

 
  
