* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.charters {
  width: 81.9%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 20px;
  flex-direction: column;
  margin-left: 260px;
  background-color: whitesmoke;
}

.charters > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.charters > .wrapper > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.charters > .wrapper > form {
  width: 280px;
  height: 44px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  gap: 5px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.charters > .wrapper > form > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-align: start;
  color: rgba(32, 32, 32, 0.7);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  background: transparent;
}

.charters > .wrapper > form > button {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
}

.charter-mothers {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}

.charter-mothers > table {
  width: 100%;
  border-collapse: collapse;
  text-align: start;
}

th {
  border-bottom: 1px solid #e0e0e0;
  background: #f5f5f5;
  font-family: Montserrat;
  color: #202020;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  padding: 15px;
}

td {
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding: 15px;
  text-align: start;
}

td > span {
  color: #ffcd00;
}
td > .card {
  color: green;
}
td > .start {
  color: #284697;
}

td > button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #284697;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartered-vehicle-detail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.5);
  padding: 209px 100px 100px 100px;
  position: absolute;
  overflow-y: scroll;
  left: 0%;
  top: 0%;
  z-index: 100;
}

.chartered-vehicle-detail > .wrapper {
  width: 81.9%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  margin-left: 260px;
  gap: 20px;
}

.chartered-vehicle-detail > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.3);
  background: #fff;
  padding-bottom: 20px;
}

.chartered-vehicle-detail > .wrapper > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.chartered-vehicle-detail > .wrapper > span > button {
  border: none;
  background: transparent;
  outline: none;
}

.chartered-vehicle-detail-span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
}

.chartered-vehicle-detail-span > .card-1 {
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.chartered-vehicle-detail-span > .card-1 > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.chartered-vehicle-detail-span > .card-1 > .wrapper > img {
  width: 150px;
  height: 170px;
  object-fit: cover;
}

.chartered-vehicle-detail-span > .card-1 > .wrapper > .inner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.chartered-vehicle-detail-span > .card-1 > .wrapper > .inner > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
}
.chartered-vehicle-detail-span > .card-1 > .wrapper > .inner > h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 800;
}

.chartered-vehicle-detail-span > .card-1 > .wrapper > .inner > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.chartered-vehicle-detail-span > .card-1 > .wrapper > .inner > span > .box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.chartered-vehicle-detail-span > .card-1 > .wrapper > .inner > span > .box > p {
  color: rgba(32, 32, 32, 0.9);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}
.chartered-vehicle-detail-span
  > .card-1
  > .wrapper
  > .inner
  > span
  > .box
  > h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.chartered-vehicle-detail-span > .card-1 > .description-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.chartered-vehicle-detail-span > .card-1 > .description-detail > h3 {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}
.chartered-vehicle-detail-span > .card-1 > .description-detail > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 132.5%;
}

.chartered-vehicle-detail-span > .card-1 > .btn {
  width: 100%;
  display: flex;
  gap: 50px;
}

.chartered-vehicle-detail-span > .card-1 > .btn > button:nth-child(1) {
  width: 250px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 20px;
}
.chartered-vehicle-detail-span > .card-1 > .btn > button:nth-child(2) {
  width: 250px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-radius: 5px;
  background: #189b48;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  margin-top: 20px;
}

.chartered-vehicle-detail-span > .card-1 > .merchant-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.chartered-vehicle-detail-span > .card-1 > .merchant-detail > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}

.chartered-vehicle-detail-span > .card-1 > .merchant-detail > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chartered-vehicle-detail-span > .card-1 > .merchant-detail > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.chartered-vehicle-detail-span > .card-2 {
  width: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.chartered-vehicle-detail-span > .card-2 > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}
.chartered-vehicle-detail-span > .card-2 > .wrapper > h3 {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.chartered-vehicle-detail-span > .card-2 > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.chartered-vehicle-detail-span > .card-2 > .wrapper > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}
.chartered-vehicle-detail-span > .card-2 > .wrapper > span > h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
}

.charters > .pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.charters > .pagination > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.charters > .pagination > .btn > button {
  background: transparent;
  border: 1px solid black;
  outline: none;
  cursor: pointer;
  font-family: Montserrat;
  padding: 5px;
  border-radius: 3px;
}
.charters > .pagination > span {
  font-family: Montserrat;
}

.reset-status-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 209px 100px 100px 100px;
  position: fixed;
  overflow-y: scroll;
  left: 0%;
  top: 0%;
  z-index: 100;
}

.reset-status-overlay > .card {
  width: 350px;
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.reset-status-overlay > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.reset-status-overlay > .card > .btn {
  width: 100%;
  display: flex;
  gap: 10px;
}

.reset-status-overlay > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
}


.reset-status-overlay > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background: #d0310e;
  color: white;
}


.admin-order {
width:  81.9% ;
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
margin-left: 260px;
padding: 100px 20px;

}

.admin-order > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.admin-order > .wrapper > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.admin-order > .admin-mother {
  width: 100%;
  margin-top: 20px;
}

td > .vehicle-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.admin-vehicle-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.5);
  padding: 50px 100px 100px 100px;
  position: fixed;
  overflow-y: scroll;
  left: 0%;
  top: 0%;
  z-index: 100;
}

.admin-vehicle-overlay > .wrapper {
  width: 81.9%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  margin-left: 260px;
  gap: 20px;
}

.admin-vehicle-overlay > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.admin-vehicle-overlay > .wrapper > span > button {
  border: none;
  background: transparent;
  outline: none;
}

.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail {
width: 100%;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
gap: 15px;
}

.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail > img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail > .admin-vehicle-span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;

}


.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail > .admin-vehicle-span > .card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail > .admin-vehicle-span > .card > h3 {
font-family: Montserrat;
}

.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail > .admin-vehicle-span > .card > h4 {
font-family: Montserrat;
}


.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail > .admin-vehicle-span > .card > p {
font-family: Montserrat;
}


.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail  > p {
font-family: Montserrat;
line-height: 1.3rem;
}

.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail  > h3 {
font-family: Montserrat;
margin-top: 20px;
}
.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail  > button {
font-family: Montserrat;
margin-top:10px;
border: none;
background: #d0310e;
color: white;
padding:5px  10px;
border-radius: 5px;
}

.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail > .admin-vehicle-span > .card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.admin-vehicle-overlay > .wrapper > .admin-vehicle-detail > .admin-vehicle-span > .card > span > p {
  font-family: Montserrat;
}

.admin-order-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 100px 100px 100px;
  position: fixed;
  overflow-y: scroll;
  left: 0%;
  top: 0%;
  z-index: 101;
}

.admin-order-overlay > span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.admin-order-overlay  > span > button {
  border: none;
  background: transparent;
  outline: none;
  color: white;
}

.admin-order-overlay > .wrapper {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  margin: auto;
  border-radius: 20px;
}

.admin-order-overlay > .wrapper > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.admin-order-overlay > .wrapper > form > .combination {
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
gap: 10px;
} 

.admin-order-overlay > .wrapper > form > .combination > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.admin-order-overlay > .wrapper > form > .combination > span > select {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.admin-order-overlay > .wrapper > form > .combination > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.admin-order-overlay > .wrapper > form > .combination > span > input {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding-left: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.admin-order-overlay > .wrapper > form > button {
  display: flex;
  width: 250px;
  height: 43px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  background: #d0300e;
  border-radius: 5px;
}

.log-in-form > form > button:disabled {
  background-color: #B5B5B5;
  cursor: not-allowed;
}

.charter-overview {
  width: 81.9%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 20px;
  flex-direction: column;
  margin-left: 260px;
  background-color: whitesmoke;
}

.charter-overview > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.charter-overview > .wrapper > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}


.charter-overview-mothers {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}

.charter-overview-mothers > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.charter-overview-mothers > .wrapper > .card {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.50);
}

.charter-overview-mothers > .wrapper > .card > p { 
font-family: Montserrat;
text-align: center;
}

.charter-overview-mothers > .wrapper > .card > h3 { 
font-family: Montserrat;
text-align: center;
}

.charter-overview-mothers > .revenue {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.charter-overview-mothers > .revenue > h3 {
  font-family: Montserrat;
  font-size: 16px;
}


