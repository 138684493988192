* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super_admin_overview_container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}




.super_admin_overview_container > .header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  top: 80px;
  right: 0%;
  background-color: white;
  padding: 0px 20px 0px 20px;
}

.super_admin_overview_container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.super_admin_overview_container > .header > span {
  display: flex;
  align-items: center;
  border-radius: 38px;
  padding: 10px;
  border-radius: 38px;
  width: 342px;
  height: 50px;
  border: 1px solid #bcbcbc;
}

.super_admin_overview_container > .header > span > input {
  width: 100%;
  border: none;
  padding-left: 10px;
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 123.023%;
  outline: none;
}

.super_admin_overview_container > .header > span > .rd {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d0300e;
  cursor: pointer;
}

.super_admin_overview_container > .card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 20px;
}

.super_admin_overview_container > .card-container > .box {
  width: 204.2px;
  height: 110.62px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.super_admin_overview_container > .card-container > .box > h2 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.super_admin_overview_container > .card-container > .box > span {
  color: rgba(32, 32, 32, 0.6);
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
}

.super_admin_overview_container > .card-container > .box > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  line-height: 39px;
}

.super_admin_overview_container > .card-container > .box > .in {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.super_admin_overview_container > .card-container > .box > .in > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  line-height: 39px;
}

.super_admin_overview_container > .card-container > .box > .in > a {
  color: #1f4798;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-decoration-line: underline;
}

.super_admin_overview_container > .packages {
  width: 100%;
  padding: 20px;
}

.super_admin_overview_container > .packages > .pkgs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.super_admin_overview_container > .packages > .pkgs > h2 {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.super_admin_overview_container > .packages > .pkgs > a {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  padding: 10px;
  background: #d0300e;
  border-radius: 5px;
}

.packages-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  gap: 15px;
}

.pg-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
}

.pg-table > p {
  text-align: start;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  width: 170px;
}

.pg-table > a {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}


.super-admin-revenue {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.super-admin-revenue > h2 {
  font-family: Montserrat;
  color: #284697;
  font-weight: 600;
}

.super-admin-revenue > .wrapper {
  width: 100%;
  gap: 20px;
}

.super-admin-revenue > .wrapper > .box {
  width:100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: whitesmoke;
  padding: 20px;
}


.super-admin-revenue > .wrapper > .box > h3 {
  font-family: Strike;
  font-weight: 400;
  font-size: 14px;
}
.super-admin-revenue > .wrapper > .box > input {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  height: 40px;
  border-radius: 5px;
}


.super-admin-revenue > .wrapper > .box > h2 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.super-admin-revenue > .wrapper > .box > .user-metrics {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 10px;
}
.super-admin-revenue > .wrapper > .box > .user-metrics > span > h4 {
  color: #202020;
  font-family: Strike;
  font-size: 14px;
  font-weight: 400;
}

.super-admin-revenue > .wrapper > .box > .user-metrics > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.super-admin-revenue > .wrapper > .box > .user-metrics > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.super-admin-revenue > .wrapper > .box > .user-metrics > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}


.tracking-card {
  position: fixed;
  top:0%;
  right: 0%;
  background: rgba(32, 32, 32, 0.5);
  padding: 20px 20px 65px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  z-index: 101;
  overflow-y: scroll;
}

.tracking-card > .card {
  display: flex;
  width: 970px;
  padding: 19px;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  border-radius: 20px;
  background: #fff;
}

.tracking-card > .card > .track-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.tracking-card > .card > .track-error > p {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.tracking-card > .card > .track-error > button {
  width: 100px;
  height: 40px;
  background-color: #d0300e;
  color: white;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Montserrat;
  border-radius: 5px;
}

.tracking-card > .card > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.tracking-card > .card > span > button {
  width: 100px;
  height: 40px;
  background-color: #d0300e;
  color: white;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Montserrat;
  border-radius: 5px;
}

.tracking-card > .card > span > h1 {
  color: #202020;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 600;
  padding: 30px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.tracking-card > .card > .del {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tracking-card > .card > .del > p {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.tracking-card > .card > .del > .in {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.tracking-card > .card > .del > .in > P {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.tracking-card > .card > .del > .in > span {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.tracking-card > .card > .tracking-his {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tracking-card > .card > .tracking-his > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.tracking-card > .card > .tracking-his > .way {
  display: flex;
  gap: 100px;
}

.tracking-card > .card > .tracking-his > .way > .in {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tracking-card > .card > .tracking-his > .way > .in > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.tracking-card > .card > .tracking-his > .way > .in > span {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.tracking-card > .card > .status {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tracking-card > .card > .status > .inner{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tracking-card > .card > .status > .inner > .in {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tracking-card > .card > .status > .inner > .in > p {
  color: #0DBA1E;
  font-family: Montserrat;
font-size: 18px;
font-weight: 700;
}

.tracking-card > .card > .status > .inner > .in >  .up {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tracking-card > .card > .status > .inner > .in > .up span {
  color: rgba(32, 32, 32, 0.90);
font-family: Montserrat;
font-size: 18px;
font-weight: 700;
}

.tracking-card > .card > .status > .inner > .in > .up > p {
  color: rgba(32, 32, 32, 0.90);
  font-family: Montserrat;
font-size: 14px;
font-weight: 400;
}



.tracking-card > .card > .status > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.tracking-card > .card > .del > .status-history {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 20px;
}

.tracking-card > .card > .del > .status-history > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.tracking-card > .card > .del > .status-history > .info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.tracking-card > .card > .del > .status-history > .info > li {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.tracking-card > .card > .del > .status-history > .info > li > .wrapper {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
}

.tracking-card > .card > .del > .status-history > .info > li > .wrapper > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;

}
.tracking-card > .card > .del > .status-history > .info > li > h3 {
  color: #202020;
  font-family: Strike;
  font-size: 16px;
  font-weight: 400;
}

.tracking-card > .card > .del > .status-history > .info > li > p > .tracking-icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}
.tracking-card > .card > .del > .status-history > .info > li > p > .delivered-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
  color: #0DBA1E;
}
.tracking-card > .card > .del > .status-history > .info > li > p > .delivery-failed-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
  color: #d0300e ;
}


