* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.accepted-assignment {
  width: 400px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;
}

.accepted-assignment > span {
  width: 100%;
  height: 52px;
  display: flex;
  padding: 16px 20px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #bcbcbc;
}

.accepted-assignment > span > input {
  width: 100%;
  padding-left: 10px;
  border: none;
  outline: none;
  color: var(--Text-Secondary, #757575);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.accepted-assignment > .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid #E0E0E0;
background: #FFF;
}

.accepted-assignment > .card > .accepted {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
}

.accepted-assignment > .card > .accepted > h2 {
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
}
.accepted-assignment > .card > .accepted > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}

.accepted-assignment > .card > .accepted > span,
.accepted-assignment > .card > .accepted > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

@media screen and (max-width:768px) {
    .accepted-assignment {
        width: 100%;
        padding-top: 100px;
    }
}
