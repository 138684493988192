* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }

  .super_admin_create-cost {
    padding: 100px 0px 50px 0px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
  }
  
  .super_admin_create-cost > .header {
    width: 100%;
    height: 100px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  
  
  .super_admin_create-cost > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }

  .super_admin_create-cost > form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    padding: 0px 20px;
  }

  .super_admin_create-cost > form > .wrapper {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    column-gap: 20px;
    row-gap: 20px;
  }

  .super_admin_create-cost > form > .wrapper > span  {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
  }
  .super_admin_create-cost > form > .wrapper > span > label  {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  }
  
  .super_admin_create-cost > form > .wrapper > span > input  {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  padding-left: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  outline: none;
  }
  .super_admin_create-cost > form > .wrapper > span > select  {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  outline: none;
  }


  .super_admin_create-cost> form > button {
    width: 60%;
    height: 43px;
    align-items: center;
    gap: 10px;
    border-radius: 66px;
    background: #284697;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    border: none;
    cursor: pointer;
  }
  
  .super_admin_create-cost > form > button:disabled {
    background-color: #4c73dc;
    cursor: not-allowed;
  }
  
  .super_admin_create-cost > form > .error-message {
    color: #d0300e;
    font-family: Montserrat;
    font-size: 20px;
  }
  
  .super_admin_create-cost> form > .success-message {
    color: green;
    font-family: Montserrat;
    font-size: 20px;
  }
  