* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }

  .charter-detail-mother {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .charter-detail-mother > .vehicle {
    width: 100%;
    height: 580px;
  }

  .charter-detail-mother > .vehicle > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .vehicle-detail {
    width: 70%;
    padding: 40px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
  }

  .vehicle-detail > .vehicle-card-1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  .vehicle-detail > .vehicle-card-1 > h3 {
    font-family: Montserrat;
    color: #000;
    font-size: 18px;
  }

  .vehicle-detail > .vehicle-card-1 > p {
    font-family: Montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }

  .vehicle-detail > .vehicle-card-1 > p > span {
    font-weight: 600;
  }

  .vehicle-detail > .vehicle-card-1 > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }


  .vehicle-detail > .vehicle-card-1 > span > p {
    font-family: Montserrat;
  }

  .vehicle-detail > .vehicle-card-1 > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .vehicle-detail > .vehicle-card-1 > .wrapper > .card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .vehicle-detail > .vehicle-card-1 > .wrapper > .card > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
  }

  .vehicle-detail > .vehicle-card-1 > .wrapper > .card > span > p {
    font-family: Montserrat;
  }

  .vehicle-detail > .vehicle-card-1 >  .vehicle-span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 40px;
    gap: 15px;
 }

 .vehicle-detail > .vehicle-card-1 >  .vehicle-span > h3 {
    font-family: Montserrat;
    font-size: 16px;
    color: #000;
    font-weight: 600;
 }

 .vehicle-detail > .vehicle-card-1 >  .vehicle-span > p {
    font-family: Montserrat;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    line-height: 1.3rem;
 }

 .vehicle-detail > .vehicle-card-1 >  .vehicle-span > a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
  
 }

 
 .vehicle-detail > .vehicle-card-2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
 }

 .vehicle-detail > .vehicle-card-2 > span {
    width: 100%;
    display: flex;  
    align-items: center;
    border-bottom: 0.5px solid #000;
    padding-bottom: 10px;
 }

 .vehicle-detail > .vehicle-card-2 > span > h3 {
    font-family: Montserrat;
    font-size: 20px;
 }

 @media screen and (max-width:768px) {
    .vehicle-detail {
        width: 100%;
        flex-direction: column;
        padding:20px 10px;
        gap: 20px;
    }

    .vehicle-detail > .vehicle-card-1 >  .vehicle-span  {
        margin-top: 20px ;
    }
 }

 @media screen and (max-width:650px) {
    .vehicle-detail {
        width: 100%;
        flex-direction: column;
        padding:20px 10px;
        gap: 20px;
    }

    .vehicle-detail > .vehicle-card-1 >  .vehicle-span  {
        margin-top: 20px ;
    }
 }

 .vehicle-card-2 > form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
 }

 .vehicle-card-2 > form > span {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
  }
  .vehicle-card-2 > form > .charter-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
  }
  
  .vehicle-card-2 > form > .charter-wrapper > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .vehicle-card-2 > form > span > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .vehicle-card-2 > form > span > select {
    width: 100%;
    outline: none;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    color: #000;
    height: 30px;
    border: 0.5px solid #000;
    border-radius: 5px;
  }
  
  .vehicle-card-2 > form > .charter-wrapper > .vehicle-combination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .vehicle-card-2 > form > .charter-wrapper > .vehicle-combination > input {
    width: 50%;
    height: 30px;
    border: 0.5px solid #000;
    border-radius: 5px;
    padding: 0px 5px;
    outline: none;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;

  }
  
  .vehicle-card-2 > form > span > .vehicle-combinations {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .vehicle-card-2 > form > span > .vehicle-combinations > input {
    width: 50%;
    height: 30px;
    outline: none;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    border: 0.5px solid #000;
    border-radius: 5px;
    padding: 0px 5px;
  }

  .vehicle-card-2 > form > button {
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    background-color: #d0300e;
  }

  .charter-detail-mother > .review {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding-bottom:30px ;
  }

  .charter-detail-mother > .review > h3 {
    font-family: Montserrat;
  }

  .image-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;

  }
  
  .vehicle:hover .image-navigation {
    width: 100%;
    display: block;
    display: flex;
    justify-content: space-between;
  }
  
  .prev-image,
  .next-image {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 50%;
    color: black;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .prev-image {
    position: absolute;
    left: 100px;
  }
  
  .next-image {
    position: absolute;
    right: 100px;
  }
  

  
  @media screen and (max-width:896px) {
    .charter-detail-mother > .review {
        width: 100%;
        padding: 10px;
    }

    .image-navigation {
      display: block;
    }

    .prev-image {
      left: 20px;
    }
    
    .next-image {
      right: 20px;
    }
  }


  














