* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }

  .store-product {
    width: 81.9%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding-top: 100px;
    margin-left: 260px;
  }
  
  .store-product > .header {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  

  .store-product > .header > h3 {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  
  .store-product > .product-list {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(5, 2fr);
    gap: 20px;
  }

  .store-product > .product-list > .product {
    width: 100%;
    height: 356px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .store-product > .product-list > .product > img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .store-product > .product-list > .product > .card {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .store-product > .product-list > .product > .card > h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 135%;
  }
  
  .store-product > .product-list > .product > .card > p {
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 135%;
  }
  
  .store-product > .product-list > .product > .card > span {
    color: #284697;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
  }
  