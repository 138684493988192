* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.about-mother-container {
  width: 100%;
  height: 650px;
}

.about-slider-container {
  width: 100%;
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-slider-container > .slides {
  width: 100%;
}

.about-slider-1 {
  width: 100%;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/PFyXwPr/Property-1-Frame-1171276905-2x.png");
}

.about-slider-2 {
  width: 100%;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/55LSxfb/Property-1-Frame-1171278174-2x.png");
}

.about-slider-3 {
  width: 100%;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/mh1Kqfr/Property-1-Frame-1171278175-2x.png");
}

.about-slider-4 {
  width: 100%;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/0B2mKq5/Property-1-Frame-1171278176-2x.png");
}

.about-card-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 235px;
}

.about-card-1 > h3 {
  color: #202020;
  text-align: center;
  font-family: Strike;
  font-size: 38px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 50px;
}

.about-card-1 > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
}

@media screen and (max-width:768px) {

  .about-card-1 {
    padding: 50px 16px;
  }

  .about-mother-container {
    height: fit-content;
  }

  .about-slider-container {
    height: 320px;
  }

  .about-slider-1 {
    width: 100%;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://i.ibb.co/F0vYkHD/picture-2.png");
  }

  .about-slider-2 {
    width: 100%;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://i.ibb.co/yFZLnk2/Property-1-Frame-1171278179-2x.png");
  }

  .about-slider-3 {
    width: 100%;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://i.ibb.co/b6xB6jm/Property-1-Frame-1171278180-2x-1.png");
  }

  .about-slider-4 {
    width: 100%;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://i.ibb.co/LxHbRX1/Property-1-Frame-1171278181-2x-1.png");
  }
}

@media screen and (max-width: 650px) {
  .about-mother-container {
    height: fit-content;
  }

  .about-slider-container {
    height: 320px;
  }
  .about-slider-1 {
    width: 100%;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://i.ibb.co/F0vYkHD/picture-2.png");
  }

  .about-slider-2 {
    width: 100%;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://i.ibb.co/yFZLnk2/Property-1-Frame-1171278179-2x.png");
  }

  .about-slider-3 {
    width: 100%;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://i.ibb.co/b6xB6jm/Property-1-Frame-1171278180-2x-1.png");
  }

  .about-slider-4 {
    width: 100%;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://i.ibb.co/LxHbRX1/Property-1-Frame-1171278181-2x-1.png");
  }

  .about-card-1 {
    padding: 50px 16px;
  }

  .about-card-1 > h3 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .about-card-1 > p {
    width: 100%;
    color: rgba(32, 32, 32, 0.8);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 143.3%;
  }
}

.about-card-2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 150px 100px;
  flex-direction: column;
  gap: 10px;
  background-color: #ffcd00;
}

.about-card-2 > h3 {
  color: #284697;
  text-align: center;
  font-family: Montserrat;
  font-size: 38px;
  font-weight: 700;
}

.about-card-2 > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.about-card-2 > .wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
}

.about-card-2 > .wrapper > .team-image {
  width: 395px;
}

.about-card-2 > .wrapper > .team-image > h3 {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  margin: 20px 0px 0px 10px;
}

.about-card-2 > .wrapper > .team-image > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  margin: 5px 0px 0px 10px;
}

.about-card-2 > .wrapper > .team-image > img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  background-color: white;
}

@media screen and (max-width:768px) {
  .about-card-2 {
    padding: 100px 10px;
  }

  .about-card-2 > .wrapper {
    gap: 10px;
  }

  .about-card-2 > .wrapper > .team-image {
    width: 100%;
  }

  .about-card-2 > .wrapper > .team-image > img {
    height: 300px;
  }
  
}

@media screen and (max-width: 650px) {
  .about-card-2 {
    padding: 100px 10px;
  }

  .about-card-2 > p {
    font-size: 12px;
  }

  .about-card-2 > .wrapper {
    flex-direction: column;
  }

  .about-card-2 > .wrapper > .team-image {
    width: 100%;
  }
}

.about-card-3 {
  width: 100%;
  display: flex;
  padding: 150px 70px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.about-card-3 > h3 {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 50px;
}

.about-card-3 > .wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
}

.about-card-3 > .wrapper > .about-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.about-card-3 > .wrapper > .about-box > h3 {
  width: 393px;
  color: #202020;
  font-family: Montserrat Alternates;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.about-card-3 > .wrapper > .about-box > P {
  width: 393px;
  color: #202020;
  text-align: justify;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
}

@media screen and (max-width:768px) {
  .about-card-3 {
    padding: 80px 16px;
    display: flex;
    align-items: flex-start;
  }

  .about-card-3 > .wrapper {
    flex-direction: column;
    gap: 40px;
  }

  .about-card-3 > .wrapper > .about-box > h3 {
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .about-card-3 > .wrapper > .about-box > p {
    width: 100%;
    font-size: 12px;
    line-height: 17px;
  }
}

@media screen and (max-width: 650px) {
  .about-card-3 {
    padding: 80px 16px;
    display: flex;
    align-items: flex-start;
  }

  .about-card-3 > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 22px;
    margin-bottom: 30px;
  }

  .about-card-3 > .wrapper {
    flex-direction: column;
    gap: 40px;
  }

  .about-card-3 > .wrapper > .about-box > h3 {
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .about-card-3 > .wrapper > .about-box > p {
    width: 100%;
    font-size: 12px;
    line-height: 17px;
  }
}
