* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.logistics-create-category {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.logistics-create-category > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.logistics-create-category > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistics-create-category > .header > button {
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  outline: none;
  cursor: pointer;
}

.logsitcs-create-category {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(32, 32, 32, 0.5);
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 101;
  overflow: auto;
}

.logsitcs-create-category > form {
  width: 600px;
  border-radius: 20px;
  background: #fff;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.logsitcs-create-category > form > p {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  width: 100%;
  text-align: center;
}

.logsitcs-create-category > form > label {
  width: 100%;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-family: Montserrat;
  color: #202020;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.logsitcs-create-category > form > label > input {
  width: 500px;
  height: 44px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}

.logsitcs-create-category > form > .btn {
  width: 100%;
  padding: 0px 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.logsitcs-create-category > form > .btn > button:nth-child(1) {
  width: 100px;
  height: 44px;
  padding: 10px;
  border-radius: 35px;
  background: #fff;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border: none;
  cursor: pointer;
}

.logsitcs-create-category > form > .btn > button:nth-child(2) {
  width: 100px;
  height: 44px;
  padding: 10px;
  border-radius: 35px;
  border-radius: 35px;
  background: #b5b5b5;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border: none;
  cursor: pointer;
}

.logsitcs-create-category > form > .btn > button:nth-child(2):disabled {
  cursor: not-allowed;
  background-color: #527bec;
}

.logsitcs-create-category > form > span {
  color: green;
  font-family: Montserrat;
}


.description-fields {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.description-fields > .wrapper {
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
  gap: 5px;
}

.description-fields > .wrapper > input {
  width: 100%;
  height: 44px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding-left: 20px;
  outline: none;
  font-family: Montserrat;
}

.description-fields > .wrapper > button {
  width: fit-content;
  height: 44px;
  padding: 5px 10px;
  border: 1px solid #d0310e ;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  
}

.description-fields > .wrapper > button:hover {
  background:#d0310e;
  color: #fff;

}

.button-add{
  color: #fff;
  font-family: Strike;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  border: none;
  cursor: pointer;
  background-color: #284697;
  width: 83%;
  height: 44px;
  border-radius: 30px;
}

.update-button-add{
  color: #fff;
  font-family: Strike;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  border: none;
  cursor: pointer;
  background-color: #284697;
  width: 100%;
  height: 44px;
  border-radius: 30px;
}

.logistic-user-category {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.logistic-user-category > p {
  color: var(--Black, #000);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  margin: 5px 0px;
}

.logistic-user-category > .category-item {
  width: 100%;
  display: flex;
  padding: 13px 0px;
  border-bottom: 1px solid #e0e0e0;
  background: #fdfdfd;
  justify-content: space-between;
  align-items: center;
}

.logistic-user-category > .category-item > .card {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.logistic-user-category > .category-item > .card > p:nth-child(1) {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}

.logistic-user-category > .category-item > .card > p:nth-child(2) {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.logistic-user-category > .category-item > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logistic-user-category > .category-item > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.logistic-user-category > .category-item > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.logs-deleted-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(32, 32, 32, 0.5);
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 101;
}

.logs-deleted-container > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.logs-deleted-container > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.logs-deleted-container > .card > p {
  width: 144px;
  height: 27px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.logs-deleted-container > .card > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logs-deleted-container > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.logs-deleted-container > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.logs-update-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(32, 32, 32, 0.5);
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 101;
}

.logs-update-container > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.logs-update-container > .card > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}

.logs-update-container > .card > input {
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  width: 100%;
  height: 44px;
  padding-left: 20px;
  font-family: Montserrat;
  outline: none;
}

.logs-update-container > .card > .btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.logs-update-container > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.logs-update-container > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: transparent;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.logs-update-container > .card > .btn > button:nth-child(2):hover {
  background: #284697;
  color: #fff;
  border: none;
}


