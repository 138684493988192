* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  
.charter-nav {
    width: 260px;
    height: 1024px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 28px;
    padding: 15px;
    position: fixed;
    overflow: auto;
    z-index: 100;
    background: #ffcd00;
  }
  
  .charter-logo {
    width: 100%;
  }
  
  .charter-logo > img {
    width: 174px;
    height: 64px;
    object-fit: cover;
  }
  
  .charter-mobile-link {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  
  .charter-mobile-link > a {
    text-decoration: none;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    padding: 10px;
  }

  .menu-icon {
    display: none;
  }

  @media screen and (max-width:896px) {
    .charter-nav {
      width: 100%;
      height: 100px; 
      display: flex;
      position: fixed; 
      z-index: 100; 
      top: 0%;
      overflow:visible;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    
    .charter-mobile-link {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 20px;
      top: 100px;
      transform: translateX(-100%);
      transition: 0.3s ease-in-out;
      padding: 10px;
      position: absolute;
      left: 0%;
      background-color: white;
    }

    .charter-mobile-link.mobile.active {
      transform: translateX(0%);
      transition: 0.3s ease-in-out;
    }

    .menu-icon {
      display: block;
    }
  }
  
  