* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.vehicles {
  width: 81.9%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 20px 40px 20px;
  flex-direction: column;
  margin-left: 260px;
  background-color: whitesmoke;
}

.vehicles > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.vehicles > .wrapper > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.vehicles > .wrapper > span {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.vehicles > .wrapper > span > a {
  text-decoration: none;
  color: #fff;
  display: flex;
  gap: 3px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  height: 43px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #d0300e;
}

.vehicles > .wrapper > span > form {
  width: 280px;
  height: 44px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  gap: 5px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.vehicles > .wrapper > span > form > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-align: start;
  color: rgba(32, 32, 32, 0.7);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  background: transparent;
}

.vehicles > .wrapper > span > form > button {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  background: transparent;
}

.vehicles-mother {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
}

.vehicles-mother > .car-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 10px;
}

.vehicles-mother > .car-card > img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.vehicles-mother > .car-card > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}


.vehicles-mother > .car-card > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.vehicles-mother > .car-card > .wrapper > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.vehicles-mother > .car-card > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.vehicles-mother > .car-card > .wrapper > h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.vehicles-mother > .car-card > .wrapper > .car-span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:10px ;
}

.vehicles-mother > .car-card > .wrapper > .car-span >  h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.vehicles-mother > .car-card > .wrapper > .car-span > a {
  text-decoration: none;
  color: #202020;
}



.pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.pagination > span {
  font-family: Montserrat;
}

.pagination > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


.pagination > .btn > button {
  background: transparent;
  border: 1px solid black;
  outline: none;
  cursor: pointer;
  font-family: Montserrat;
  padding: 5px;
  border-radius: 3px;
}

@media screen and (max-width: 896px) {
  .vehicles {
    width: 100%;
    padding:120px 10px 20px 10px;
    margin: 0%;
  }

  .vehicles > .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .vehicles > .wrapper > span {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .vehicles > .wrapper > span > form {
    width: 100%;
  }

  .vehicles > .wrapper > span > a {
    width: 100%;
  }

  .vehicles-mother  {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .vehicles-mother > .car-card {
    width: 100%;
  }
}

.add-vehicle {
  width: 81.9%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 20px;
  flex-direction: column;
  margin-left: 260px;
  background-color: whitesmoke;
}

.add-vehicle > h3 {
  width: 100%;
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.add-vehicle > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  margin-top: 20px;
  padding: 15px;
  gap: 30px;
}

.add-vehicle > .wrapper > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.add-vehicle > .wrapper > form > .card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.3);
  padding-bottom: 15px;
}

.add-vehicle > .wrapper > form > .card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.add-vehicle > .wrapper > form > .card > span > h3 {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.add-vehicle > .wrapper > form > .card > span > a {
  text-decoration: none;
  color: rgba(32, 32, 32, 0.9);
}

.add-vehicle > .wrapper > form > .card > button {
  width: 100px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: #d0310e;
  border: none;
  outline: none;
  font-family: Montserrat;
  color: white;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.vehicle-image {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.vehicle-image > h3 {
  width: 100%;
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.vehicle-image-span {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
  margin: 20px 0px;
}

.vehicle-image-card {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(45, 69, 150, 0.8);
  background: rgba(233, 244, 255, 0.7);
}

.vehicle-image-card > svg {
  font-size: 40px;
}

.vehicle-image-card > label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vehicle-image-card > label > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
}

.vehicle-image-span > img {
  width: 150px;
  height: 100%;
  object-fit: contain;
}

.vehicle-image-span > button {
  display: flex;
  height: 43px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  background: #d0300e;
}

.vehicle-input-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.vehicle-input-detail > h3 {
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.4px;
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.vehicle-input-detail > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin: 20px 0px;
}

.vehicle-input-detail > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.vehicle-input-detail > .wrapper > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.vehicle-input-detail > .wrapper > span > select {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.vehicle-input-detail > .wrapper > span > input {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding-left: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

 .description-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

 .description-card > h3 {
    width: 100%;
    color: rgba(32, 32, 32, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.4px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
}

 .description-card > textarea {
    width: 100%;
    height: 90px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
}

.vehicle-price {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }


 .vehicle-price > h3 {
    width: 100%;
    color: rgba(32, 32, 32, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.4px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
}


 .vehicle-price > span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;

}
  
 .vehicle-price > span > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }

  .vehicle-price > span > input {
    width: 32%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding-left: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
