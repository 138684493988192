* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.home-account-container {
  width: 100%;
  padding: 40px 70px;
  display: flex;
}

.home-account-container > .my-assignment {
  width: 400px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;
}

.my-assignment > span {
  width: 100%;
  height: 52px;
  display: flex;
  padding: 16px 20px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #bcbcbc;
}

.my-assignment > span > input {
  width: 100%;
  padding-left: 10px;
  border: none;
  outline: none;
  color: var(--Text-Secondary, #757575);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.my-assignment > .assignment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.my-assignment > .assignment > p {
  color: #000;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
}

.my-assignment > .assignment > .assignment-card {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
  width: 100%;
}

.assignment > .assignment-card > h2 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.assignment > .assignment-card > .in {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assignment > .assignment-card > .in > p {
  color: #000;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
}

.assignment > .assignment-card > .in > span {
  color: #202020;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
}

.assignment > .assignment-card > .inner {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.assignment > .assignment-card > .inner > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
}

.assignment > .assignment-card > .inner > span {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.assignment > .assignment-card > .inner > span > p {
  color: #202020;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
}

.assignment > .assignment-card > p {
  color: #202020;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 600;
}

.assignment > .assignment-card > .inner > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}

.assignment > .assignment-card > .btn {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
}

.assignment > .assignment-card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.assignment > .assignment-card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #189b48;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.assignment > .assignment-card > .btn > button:disabled {
  background-color: #bcbcbc;
  cursor: not-allowed;
  color: white;
  border: none;
}

.accept-over-con {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 60px;
  background: rgba(32, 32, 32, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept-over-con > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.15);
}

.accept-over-con > .card > p {
  color: #d0300e;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
}

.accept-over-con > .card .box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  border-radius: 50%;
}

.accept-over-con > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.accept-over-con > .card > p {
  width: 144px;
  height: 27px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.accept-over-con > .card > .btn {
  display: flex;
  gap: 10px;
  align-items: center;
}

.accept-over-con > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.accept-over-con > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #189b48;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .home-account-container {
    flex-direction: column;
    gap: 40px;  
  }
  .home-account-container > .my-assignment {
    min-width: 100%;
    padding-top: 100px;
  }

  .home-account-container {
    padding: 20px 0px;
  }

  .my-assignment > .assignment > .assignment-card {
    width: 100%;
  }

  .my-assignment > span {
    width: 100%;
  }
}
