* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.logistic-rider-driver {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.logistic-rider-driver > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.logistic-rider-driver > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistic-rider-driver > .header > span {
  display: flex;
  width: 280px;
  height: 44px;
  padding: 20px;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.logistic-rider-driver > .header > span > input {
  width: 100%;
  border: none;
  outline: none;
  background-color: #f9f9f9;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.logistic-rider-driver > .rider > .rider-by-branch {
  padding: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 30px;
}

.logistic-rider-driver > .rider > .rider-by-branch > .card {
  display: flex;
  width: 346px;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #b5b5b5;
  background: #fff;
}

.logistic-rider-driver > .rider > .rider-by-branch > .card > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.logistic-rider-driver > .rider > .rider-by-branch > .card > span {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.logistic-rider-driver > .rider > .rider-by-branch > .card > p {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.logistic-rider-driver > .rider > .rider-by-branch > .card > svg {
  width: 15px;
  height: 15px;
}
