* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.track-package-container {
  padding-top: 100px;
  width: 81.9%;
  min-height: 100vh;
  margin-left: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.track-package-container > .header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.track-package-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.track-package-container > .header > form {
  width: 280px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  gap: 5px;
  border-radius: 38px;
  background: #fff;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.track-package-container > .header > form > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.6);
  font-family: Montserrat;
  background: transparent;
}
::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.track-package-container > .header > form > button {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  border-radius: 50%;
  background: #d0300e;
  border: none;
  color: #fff;
  cursor: pointer;
}

.track-package-container > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 20px;
  border-radius: 10px;
  background: #fff;
  margin-top: 40px;
  gap: 20px;
}

.track-package-container > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.track-package-container > .wrapper > span > h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
}

.sender-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.sender-card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.sender-card > span > h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.sender-card > .sender-card-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.sender-card > .sender-card-box > .package-span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.receiver-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.receiver-card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.receiver-card > span > h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.receiver-card > .receiver-card-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.receiver-card > .receiver-card-box > .package-span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.package-info-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.package-info-card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.package-info-card > span > h3 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.package-info-card > .package-info-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
}

.package-info-card > .package-info-box > .package-span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.package-span > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.package-span > span > h3 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}
.package-span > span > p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}


 .status-history {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 20px;
  margin: 20px 0px;
}



 .status-history > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}
 .status-history > .info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

 .status-history > .info > li {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}


.status-history > .info > li > p{
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;

}

 .status-history > .info > li > .wrapper {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
}

 .status-history > .info > li > .wrapper > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;

}
 .status-history > .info > li > .wrapper > h3 {
  color: #202020;
  font-family: Strike;
  font-size: 16px;
  font-weight: 400;
}

 .status-history > .info > li > p > .tracking-icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}

 .status-history > .info > li > p > .delivered-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
  color: #0DBA1E;
}

 .status-history > .info > li > p > .delivery-failed-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
  color: #d0300e ;
}


