* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.merchant-details-container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.merchant-details-container > .header {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merchant-details-container > .header > span {
  display: flex;
  gap: 10px;
  align-items: center;
}

.merchant-details-container > .header > .btn {
  display: flex;
  gap: 10px;
}

.merchant-details-container > .header > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #189b48;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  border: none;
}

.merchant-details-container > .header > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  border: none;
}
.merchant-details-container > .header > .btn > button:nth-child(3) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #df8a09;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  border: none;
}

.merchant-details-container > .header > .btn > button:nth-child(4) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.merchant-details-container > .header > span h1 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.merchant-details-container > .header > span .icon {
  cursor: pointer;
  font-size: 30px;
}

.merchant-details-container > .card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 40px;
  padding: 20px;
}

.merchant-details-container > .card > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.merchant-details-container > .card > span > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.merchant-details-container > .card > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.merchant-details-container > .business-info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.merchant-details-container > .business-info > .in {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.merchant-details-container > .business-info > .in > h1 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.merchant-details-container > .business-info > .card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 40px;
  padding: 20px;
}

.merchant-details-container > .business-info > .card > span {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.merchant-details-container > .business-info > .card > span > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.merchant-details-container > .business-info > .card > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.verifyOverlay,
.revokeOverlay,
.rejectOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  right: 0%;
  background: rgba(32, 32, 32, 0.7);
  z-index: 100;
}

.verifyOverlay > .card,
.revokeOverlay > .card,
.rejectOverlay > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.verifyOverlay > .card > h1,
.revokeOverlay > .card > h1,
.rejectOverlay > .card > h1 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.verifyOverlay > .card > p,
.revokeOverlay > .card > p,
.rejectOverlay > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.verifyOverlay > .card > .btn,
.revokeOverlay > .card > .btn,
.rejectOverlay > .card > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.verifyOverlay > .card > .btn > button:nth-child(1),
.revokeOverlay > .card  > .btn > button:nth-child(1),
.rejectOverlay > .card > .btn >  button:nth-child(1){
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.verifyOverlay > .card > .btn > button:nth-child(2), 
.revokeOverlay > .card  > .btn > button:nth-child(2),
.rejectOverlay > .card > .btn >  button:nth-child(2) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #189b48;
    color: #fff;
    font-family: Montserrat;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    border: none;
}  
