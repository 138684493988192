* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.merchant-overview-container {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 120px 100px;
  background: #ffcd00;
}

.merchant-overview-container > h3 {
  color: #202020;
  font-family: Montserrat Alternates;
  font-size: 32px;
  font-weight: 700;
}
.merchant-overview-container > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.merchant-overview-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 0px 100px;
}

.merchant-overview-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  padding: 20px;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 3px 3px lightgrey;
  background: #fbfbfb;
  transform: translateY(-50px);
}

.merchant-overview-box > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
}

.merchant-overview-line {
  width: 100%;
  height: 0.5px;
  background: #121212;
}

.merchant-overview-box > h3 {
  color: #121212;
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
}

.merchant-overview-graph {
  width: 85%;
  height: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px 20px;
  margin: 10px auto;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.merchant-overview-graph > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.merchant-overview-graph > p {
  color: #4f4f4f;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 400;
}

.merchant-recent-order {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 100px;
  margin-top: 20px;

}

.merchant-recent-order > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  margin: 5px 0px;
}

.merchant-recent-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
}

.merchant-recent-container > table {
    width: 100%;
}



.merchant-recent-container > table > tr > td {
    border-bottom: 1px solid #e0e0e0;
    background: #fff;
    color: #202020;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    padding: 15px;
    text-align: start;
}

.merchant-recent-container > table > tr > img {
    width: 50px;
    height: 50px;
}
