* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super_admin_blog_container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.super_admin_blog_container > .header {
  display: flex;
  width: 100%;
  height: 90px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  top: 80px;
  right: 0%;
  background-color: white;
}

.super_admin_blog_container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.super_admin_blog_container > .header > span {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 66px;
  border: 1px solid #121212;
  background: #fff;
  cursor: pointer;
}

.super_admin_blog_container > .header > span > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.super_admin_blog_container > .blogs-container {
  width: 100%;
  padding: 20px;
  min-height: 70vh;
  overflow-y: scroll;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.blogs-container > .blog-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blogs-container > .blog-item > .wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 730px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 30px;
}

.blogs-container > .blog-item > .wrapper > .btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

.blogs-container > .blog-item > .wrapper > .btn > button:nth-child(1) {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: white;
}
.blogs-container > .blog-item > .wrapper > .btn > button:nth-child(2) {
  color: #ee1a30;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: white;
}

.blogs-container > .blog-item > .wrapper > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.blogs-container > .blog-item > .wrapper > h2 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 133.523%;
}

.blogs-container > .blog-item > .wrapper > span {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 133.523%;
}

.blogs-container > .blog-item > img {
  width: 180px;
  height: 180px;
  object-fit: contain;
}

.blog-edit-over-container {
  position: fixed;
  height: 100%;
  top: 0%;
  right: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 100;
}

.blog-edit-over-container > .delete-card {
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.blog-edit-over-container > .delete-card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 118.243%;
}

.blog-edit-over-container > .delete-card > input {
  display: flex;
  width: 100%;
  height: 42px;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  border: 1px solid #e0e0e0;
  outline: none;
}

.blog-edit-over-container > .delete-card > .btn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog-edit-over-container > .delete-card > .btn > button:nth-child(1) {
  width: 120px;
  height: 40px;
  text-align: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.blog-edit-over-container > .delete-card > .btn > button:nth-child(2) {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border: none;
  cursor: pointer;
}

.blog-delete-over-container {
  position: fixed;
  height: 100%;
  top: 0%;
  right: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 100;
}

.blog-delete-over-container  > .delete-card {
  width: 30%;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.blog-delete-over-container > .delete-card > span {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.blog-delete-over-container > .delete-card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.blog-delete-over-container > .delete-card > .btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.blog-delete-over-container > .delete-card > .btn > button:nth-child(1) {
  width: 120px;
  height: 40px;
  text-align: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.blog-delete-over-container > .delete-card > .btn > button:nth-child(2) {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border: none;
  cursor: pointer;
}
