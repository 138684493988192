* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  .selected-truck-details {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .truck-details-images {
    width: 100%;
    height: 580px;
  }

  .selected-truck-details > .truck-details-images > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .image-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;

  }
  
  .truck-details-images:hover .image-navigation {
    width: 100%;
    display: block;
    display: flex;
    justify-content: space-between;
  }
  
  .prev-image,
  .next-image {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 50%;
    color: black;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .prev-image {
    position: absolute;
    left: 100px;
  }
  
  .next-image {
    position: absolute;
    right: 100px;
  }

  .selected-truck-details > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    padding: 50px 100px;
  }

  .selected-truck-details > .wrapper > .selected-truck-detail-card-1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  .selected-truck-details > .wrapper > .selected-truck-detail-card-1 > h3 {
    font-family: Montserrat;
    color: #202020;
    font-weight: 400;
  }
  .selected-truck-details > .wrapper > .selected-truck-detail-card-1 > h4 {
    font-family: Montserrat;
    color: #202020;
    font-weight: 600;
  }
  .selected-truck-details > .wrapper > .selected-truck-detail-card-1 > p {
    font-family: Montserrat;
    color: #202020;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .selected-truck-details > .wrapper > .selected-truck-detail-card-2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  .selected-truck-details > .wrapper > .selected-truck-detail-card-2 > form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    
  }
  .selected-truck-details > .wrapper > .selected-truck-detail-card-2 > form > .selected-truck-combination {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  .selected-truck-details > .wrapper > .selected-truck-detail-card-2 > form > .selected-truck-combination > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .selected-truck-details > .wrapper > .selected-truck-detail-card-2 > form > .selected-truck-combination > span > label {
        color: #202020;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
  }
  .selected-truck-details > .wrapper > .selected-truck-detail-card-2 > form > .selected-truck-combination > span > input {
    width: 100%;
    height: 45px;
    background: transparent;
    outline: none;
    margin: 5px 0px;
    padding-left: 10px;
    border: 1px solid #e0e0e0;
    font-family: Montserrat;
    border-radius: 5px;
  }


  .selected-truck-details > .wrapper > .selected-truck-detail-card-2 > form > button {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: #d0300e;
  border-radius: 38px;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 30px;
    
  }

  .freight-success-msg {
    position: fixed;
    top: 0%;
    left: 0%;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(32, 32, 32, 0.5);
    padding: 50px 30px;
    z-index: 101;
  }
  
  .freight-success-msg > .card {
    width: 500px;
    display: flex;
    padding: 25px 50px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    background: #fff;
  }
  
  .freight-success-msg > .card > h3 {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  }
  
  .freight-success-msg > .card > p {
    color: #334155;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 400;
    line-height: 148%;
    margin-top: 10px;
  }
  
  .freight-success-msg > .card > .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    padding: 10px 0px;
  }
  
  .freight-success-msg > .card > .wrapper > h3 {
  color: #334155;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 148%;
  }
  .freight-success-msg > .card > .wrapper > p {
  color: #334155;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 148%;
  }
  .freight-success-msg > .card > .wrapper > h4 {
    color:  #334155;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    line-height: 148%; 
    margin-top: 30px;
  }
  .freight-success-msg > .card > .wrapper > button {
    display: flex;
    width: 100px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid  #D0300E ;
    background: #FFF;
    color:  #D0300E;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    margin-top: 30px;
  }



  @media screen and (max-width:896px) {
    .selected-truck-details > .wrapper {
        flex-direction: column;
        padding: 10px;
    }

    .image-navigation {
      display: block;
    }

    .prev-image {
        position: absolute;
        left: 10px;
      }
      
      .next-image {
        position: absolute;
        right: 10px;
      }

      .freight-success-msg {
        padding: 10px;
      }
  
      .frieght-success-msg > .card {
        width: 100%;
        padding: 10px;
      }
  }

