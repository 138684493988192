* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }


  .super-admin-investor {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .super-admin-investor > .header {
    width: 100%;
    height: 100px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 358px;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  
  
  .super-admin-investor > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  
  .super-admin-investor > .header > .in {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .super-admin-investor > .header > .in > span {
    display: flex;
    gap: 10px;
    width: 280px;
    height: 44px;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(32, 32, 32, 0.6);
    padding: 20px;
  }
  
  .super-admin-investor > .header > .in > span > input {
    width: 100%;
    border: none;
    outline: none;
    font-family: Montserrat;
  }

  .super-admin-investor > .header > .in > .btn {
    display: flex;
    gap: 10px;
  }
   

  .super-admin-investor > .header > .in > button {
    height: 43px;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 66px;
    background:#284697;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    border: none;
  }
  
  .super-admin-investor > .stex-investor-table {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  
   .super-admin-investor > .stex-investor-table > table {
    width: 100%;
  }
  
   .super-admin-investor > .stex-investor-table > table > thead > tr > th {
    color: #202020;
    font-size: 12px;
    font-weight: 600;
    text-align: start;
    font-family: Montserrat;
    padding: 10px 3px;
  }
  
  .super-admin-investor > .stex-investor-table > table > tbody > tr > td {
    color: #202020;
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-align: start;
    padding: 10px 3px;
  }
  
  .super-admin-investor > .stex-investor-table
    > table
    > tbody
    > tr
    > td
    > .action {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
 .super-admin-investor > .stex-investor-table
    > table
    > tbody
    > tr
    > td
    > .action
    > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  