* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super-admin-change-pass {
  padding-top: 100px;
  width: 81.9%;
  height: 100vh;
  margin-left: 260px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
}

.super-admin-change-pass > .header {
  display: flex;
  gap: 20px;
  width: 100%;
  height: 70px;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.super-admin-change-pass > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.super-admin-change-pass > .header > .icon {
  cursor: pointer;
}

.super-admin-change-pass > .form-setting {
  width: 885px;
  border-radius: 20px;
  background: #fff;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-left: 10px;
}

.super-admin-change-pass > .form-setting > label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.super-admin-change-pass > .form-setting > label .inner {
  width: 385px;
  height: 50px;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  display: flex;
  justify-content: space-between;
}

.super-admin-change-pass > .form-setting > label .inner > input {
  width: 100%;
  border: none;
  outline: none;
  padding: 20px;
  height: 40px;
}

.super-admin-change-pass > .form-setting > label .inner > .iconp {
  margin-right: 20px;
  cursor: pointer;
}

.super-admin-change-pass > .form-setting > button {
  display: flex;
  width: 385px;
  height: 50px;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 38px;
  background: #d0300e;
  border: none;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.023%;
}

.super-admin-change-pass > .form-setting > button:disabled {
  background: #e25b3c;
  cursor: not-allowed;
}

.super-admin-change-pass > .error-message {
    color: #d0300e;
    font-family: Montserrat;
}
.super-admin-change-pass > .success-message {
    color: green;
    font-family: Montserrat;
}


@media screen and (max-width:768px) {
  .super-admin-change-pass {
    margin: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }

  .super-admin-change-pass > .form-setting {
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 15px;
  }

  .super-admin-change-pass > .form-setting > label {
    width: 100%;
  }

  .super-admin-change-pass > .form-setting > label .inner {
    width: 100%;
  }
  .super-admin-change-pass > .form-setting > button {
    width: 100%;
  }
}
