* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  .charter-setting {
    width: 81.9%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 120px 20px;
    flex-direction: column;
    margin-left: 260px;
    background-color: whitesmoke;
  }
  
  .charter-setting  > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 15px;
    flex-direction: column;
  }
  
  .charter-setting  > .wrapper > h3 {
    width: 100%;
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }

  .charter-setting  > .wrapper > .charter-account {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 10px;
    border:  1px solid #E0E0E0;
  }

  .charter-setting  > .wrapper > .charter-account > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .charter-setting  > .wrapper > .charter-account > span > p { 
    color: rgba(32, 32, 32, 0.9);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }

  .charter-password {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .charter-password > a {
    text-decoration: none;
    font-family: Montserrat;
    font-size: 14px;
    color: #284697;
  }


.charter-account-managements {
    width: 81.9%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 260px;
    padding: 100px 20px;
    background-color: white;
  }
  
  .charter-account-managements > h3 {
    color: #284697;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.2px;
  }
  
  .charter-password-forms {
    width: 60%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }
  
  .charter-password-forms > form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 20px;
    gap: 20px;
    padding: 20px;
    background: #f9f9f9;
  }
  
  .charter-password-forms > form > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  
  .charter-password-forms > form > .wrapper > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }
  
  .charter-password-forms > form span {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .charter-password-forms > form span > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .charter-password-forms > form > span > .pass {
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
  }
  
  .charter-password-forms > form > button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
    border-radius: 38px;
    background: #d0300e;
    cursor: pointer;
    border: none;
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
  }