* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.footer-container {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: #ffcd00;
}

.footer-container > .wrapper {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-box {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;  
}


.footer-box > footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: Montserrat;
}

.quick-link {
    width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.quick-line{
  width: 0.8px;
  height: 16px;
  border: 0.8px solid black;
}

.quick-link > a {
  text-decoration: none;
  color: #121212;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.quick-link > a > svg {
  width: 24px;
  height: 24px;
}

.footer-span{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-span > p{
color: rgba(32, 32, 32, 0.80);
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: none;
}

.footer-span > span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.footer-mobile{
  display: none;
}

@media screen and (max-width:768px) {
  .footer-container{
    height: fit-content;
  }

  .footer-container > .wrapper {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding: 30px 10px;
  }

  .footer-box{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

    .footer-box:nth-child(2){
    display: none;
  }

  .footer-mobile{
    width: 100%;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px ;
  } 

  .quick-link{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    margin-top: 10px;
  }

  .footer-span > span {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 50px;
  }

   .footer-mobile{
    width: 100%;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px ;
  }

  .footer-mobile > footer{
    width: 100%;
    font-family:  Montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width:650px) {
.footer-container{
  height: fit-content;
}

  .footer-container > .wrapper {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding: 30px 10px;
  }

  .footer-box{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-box:nth-child(2){
    display: none;
  }

  .footer-span > p{
    display: block;
  }

  .quick-link{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }

  .quick-line{
    display: none;
  }

  .footer-span > span {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 50px;
  }

  .footer-mobile{
    width: 100%;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px ;
  }



  .footer-mobile > footer{
    width: 100%;
    font-family:  Montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

}

.scroll-to-top{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#ffcd00;
  color: white;
  bottom: 20px;
  right: 20px;
  position: fixed;
}

.scroll-to-top:hover{
  background-color: white;
  color:#d0300e;
  box-shadow: 0px 2px 5px lightgray;
}

