* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }


  .super-admin-investor {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .super-admin-investor > .header {
    width: 100%;
    height: 100px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 358px;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  
  
  .super-admin-investor > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  
  .super-admin-investor > .header > .in {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .super-admin-investor > .header > .in > span {
    display: flex;
    gap: 10px;
    width: 280px;
    height: 44px;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(32, 32, 32, 0.6);
    padding: 20px;
  }
  
  .super-admin-investor > .header > .in > span > input {
    width: 100%;
    border: none;
    outline: none;
    font-family: Montserrat;
  }

  .super-admin-investor > .header > .in > .btn {
    display: flex;
    gap: 10px;
  }
   

  .super-admin-investor > .header > .in > button {
    height: 43px;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 66px;
    background:#284697;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    border: none;
  }
  
  .super-admin-investor > .stex-investor-table {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  
   .super-admin-investor > .stex-investor-table > table {
    width: 100%;
  }
  
   .super-admin-investor > .stex-investor-table > table > thead > tr > th {
    color: #202020;
    font-size: 12px;
    font-weight: 600;
    text-align: start;
    font-family: Montserrat;
    padding: 10px 3px;
  }
  
  .super-admin-investor > .stex-investor-table > table > tbody > tr > td {
    color: #202020;
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-align: start;
    padding: 10px 3px;
  }

  .super-admin-investor > .stex-investor-table > table > tbody > tr > td > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  
  .super-admin-investor > .stex-investor-table
    > table
    > tbody
    > tr
    > td
    > .action {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
 .super-admin-investor > .stex-investor-table
    > table
    > tbody
    > tr
    > td
    > .action
    > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }



  .delete-investor-overlay {
  position: fixed;
  height: 100%;
  top: 0%;
  right: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 100;
}

.delete-investor-overlay  > .delete-card {
  width: 30%;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.delete-investor-overlay  > .delete-card > span {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.delete-investor-overlay  > .delete-card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.delete-investor-overlay  > .delete-card > .btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.delete-investor-overlay  > .delete-card > .btn > button:nth-child(1) {
  width: 120px;
  height: 40px;
  text-align: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.delete-investor-overlay  > .delete-card > .btn > button:nth-child(2) {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border: none;
  cursor: pointer;
}


.investor-edit-overlay {
  position: fixed;
  height: 100%;
  top: 0%;
  right: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 100;
}
.investor-edit-overlay > .card {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 10px 20px;
}

.investor-edit-overlay > .card > label {
  width: 320px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.investor-edit-overlay > .card > label > input {
  display: flex;
  width: 320px;
  height: 42px;
  padding: 0px 10px;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  outline: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.investor-edit-overlay > .card > .btn {
  display: flex;
  height: 60px;
  align-items: flex-end;
  gap: 20px;
}

.investor-edit-overlay > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}

.investor-edit-overlay > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-weight: 700;
}

.investor-details-overlay {
  position: fixed;
  height: 100%;
  top: 0%;
  right: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 100;
}
  

.investor-details-overlay > .details-card {
  width: 900px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;

}

.investor-details-overlay > .details-card > span {
  width: 100%;
  display: flex;
  justify-content: space-between;

}

.investor-details-overlay > .details-card > span > h3 {
  font-family: Montserrat;
  font-weight: 400;
}
.investor-details-overlay > .details-card > span > button {
  font-family: Montserrat;
  padding: 2px;
}

.investor-details-overlay > .details-card > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  gap: 20px;
}

.investor-details-overlay > .details-card > .wrapper > img {
width:50% ;
height: 400px;
object-fit: cover;
}

.investor-details-overlay > .details-card > .wrapper > .investor-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.investor-details-overlay > .details-card > .wrapper > .investor-info > span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}


.investor-details-overlay > .details-card > .wrapper > .investor-info > h2 {
  font-family: Montserrat;
  font-weight: 400;
}
.investor-details-overlay > .details-card > .wrapper > .investor-info > span > p {
  font-family: Montserrat;
}
.investor-details-overlay > .details-card > .wrapper > .investor-info > span > h3 {
  font-family: Strike;
  font-weight: 600;
}


