* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.navbar-container-account {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  padding: 0px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0%;
  z-index: 100;
}

.navbar-container-account > .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-container-account > .logo > span {
  display: flex;
  align-items: center;
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 83.523%;
  gap: 5px;
}

.navbar-container-account > .logo > span > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 83.523%;
}

.navbar-container-account > .drop {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-container-account > .drop > .ic {
  display: flex;
  width: 45px;
  height: 45px;
  padding: 9.5px 9px 9.5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #e0e0e0;
}

.navbar-container-account > .drop > .arrow {
  cursor: pointer;
}

.navbar-container-account > .menubtn {
  display: none;
}

.navbar-container-account > .nav-links {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar-container-account > .menubtn {
    display: block;
  }

  .navbar-container-account > .logo > span {
    display: none;
  }

  .navbar-container-account {
    padding: 0px 15px;
    position: fixed;
    top: 0%;
  }

  .navbar-container-account > button {
    display: none;
  }

  .navbar-container-account > .drop {
    display: none;
  }

  .navbar-container-account > .nav-links {
    display: block;
    position: absolute;
    top: 80px;
    width: 100%;
    background-color: white;
    left: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    height: 100vh;
    padding: 10px;
    gap: 10px;
  }

  .navbar-container-account > .nav-links.active {
    left: -0;
  }

  .navbar-container-account > .nav-links > a {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
  }

  .navbar-container-account > .nav-links > button {
    background-color: transparent;
    border: none;
    color: #D0300E;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
}
