* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super-admin-post-container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.super-admin-post-container > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.super-admin-post-container > .header > span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.super-admin-post-container > .header > span > .icon {
  cursor: pointer;
}

.super-admin-post-container > .header > span > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.super-admin-post-container > .header > button {
  padding: 10px 20px;
  gap: 10px;
  border-radius: 66px;
  background-color: #d0300e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border: none;
}

.super-admin-post-container > .header > button:disabled {
  background-color: #eb5b3b;
  cursor: not-allowed;
}

.super-admin-post-container > .post-container {
  display: flex;
  width: 100%;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.super-admin-post-container > p {
  color: green;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.post-container > .image-box {
  display: flex;
  width: 566px;
  height: 160px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px dashed #284697;
  background: #f7f7f7;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
}

.blog-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.blog-details > input {
  display: flex;
  width: 566px;
  height: 40px;
  padding: 15px 0px;
  align-items: center;
  gap: 10px;
  font-family: Montserrat;
  background: #f7f7f7;
  padding-left: 10px;
  border: none;
  outline: none;
}

.blog-details > textarea {
  width: 566px;
  min-height: 70px;
  padding: 15px ;
  background: #f7f7f7;
  font-family: Montserrat;
}
