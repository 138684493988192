
* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }

.forget-password-form {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 30px 0px;
  }
  
  .forget-password-form > h3 {
    color: #1f4798;
    font-family: Strike;
    font-size: 26px;
    font-weight: 700;
    margin: 30px 0px;
  }
  
  .forget-password-form > form {
    width: 38%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .forget-password-form > form > span {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .forget-password-form > form > span > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }

  .forget-password-form > form > span > input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: transparent;
    outline: none;
    margin: 5px 0px;
    padding-left: 10px;
  }

  .forget-password-form > form > button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    margin: 5px 0px;
    border-radius: 80px;
    background: #d0300e;
    cursor: pointer;
    color: #fff;
    font-family: Montserrat ;
    font-size: 16px;
    font-weight: 700;
  }
  
  .forget-password-form > form > button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }

  @media screen and (max-width:768px) {
    .forget-password-form {
      padding: 10px;
    }

    .forget-password-form > form {
      width: 100%;
    }
  }
  @media screen and (max-width:650px) {
    .forget-password-form {
      padding: 10px;
    }

    .forget-password-form > form {
      width: 100%;
    }
  }