* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.blog-mother-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 100px;
  gap: 70px;
}

.blog-mother-card > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.blog-con-1 {
  width: 65%;
  height: 450px;
}

.blog-con-1 > img {
  width: 100%;
  height: 100%;
}

.blog-con-2 {
  width: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.blog-con-2 > h3 {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 135%;
}

.blog-con-2 > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 135%;
}

.blog-con-2 > p > a {
  text-decoration: none;
  color: #d0300e;
  font-family: Montserrat;
}

.blog-con-2 > h4 {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.blog-card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  column-gap: 40px;
  row-gap: 60px;
  padding: 30px 100px;
}

.blog-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.blog-info > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.blog-info > h3 {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 135%;
}
.blog-info > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 135%;
}

.blog-info > p > a {
  text-decoration: none;
  color: #d0300e;
  font-family: Montserrat;
}

.blog-info > h4 {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

@media screen and (max-width:768px) {
  .blog-mother-card {
    padding: 0px;
    gap: 20px;
  }

  .blog-mother-card > span {
    flex-direction: column;
  }

  .blog-con-1 {
    width: 100%;
  }
  .blog-con-2 {
    width: 100%;
    padding: 0px 10px;
  }

  .blog-card-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 3fr);
    column-gap: 10px;
    padding: 10px;
  }
  
}

@media screen and (max-width: 650px) {
  .blog-mother-card {
    padding: 0px;
    gap: 20px;
  }

  .blog-mother-card > span {
    flex-direction: column;
  }

  .blog-con-1 {
    width: 100%;
  }

  .blog-con-1 > img {
    object-fit: cover;
  }

  .blog-con-2 {
    width: 100%;
    padding: 0px 10px;
  }

  .blog-con-2 > h3 {
    font-size: 16px;
  }

  .blog-con-2 > p {
    font-size: 13px;
  }

  .blog-card-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding:20px 0px;
  }

  .blog-info {
    padding: 0px 10px;
    gap: 15px;
  }

  .blog-info > h3 {
    font-size: 16px;
  }
  .blog-info > p {
    font-size: 13px;
  }
}

.blog-detail-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 100px;
  gap: 100px;
}

.blog-detail-con-1 {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.blog-detail-con-1 > a {
  text-decoration: none;
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  
}

.blog-detail-con-1 > img {
  width: 100%;
  height: 406px;
  object-fit: cover;
}

.blog-detail-con-1 > h3 {
  width: 610px;
  color: #d0300e;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
}

.blog-detail-con-1 > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.blog-detail-con-2 {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-left: 1px solid #e0e0e0;
  background: #fff;
  padding: 0px 20px;
  gap: 30px;
}

.blog-detail-con-2 > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.other-blog-info {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 10px;
  border: 0.5px solid lightgray;
  text-decoration: none;
  border-radius: 5px;
}

.other-blog-info > span {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.other-blog-info > span > h3 {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}
.other-blog-info > span > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
}

.other-blog-info > img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

@media screen and (max-width:768px) {
  .blog-detail-container {
    flex-direction: column;
    padding: 10px;
    gap: 30px;
  }

  .blog-detail-con-1 {
    width: 100%;
  }

  .blog-detail-con-2 {
    width: 100%;
    border: none;
    padding: 0px;
  }
}

@media screen and (max-width:650px) {
  .blog-detail-container {
    flex-direction: column;
    padding: 10px;
    gap: 30px;
  }

  .blog-detail-con-1 {
    width: 100%;
  }

  .blog-detail-con-1 > h3 {
    width: 100%;
  }

  .blog-detail-con-2 {
    width: 100%;
    border: none;
    padding: 0px;
  }

  .other-blog-info > span {
    width: 100%;
  }
}
