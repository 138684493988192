* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.marketplace-admin-banner {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.marketplace-admin-banner > .header {
  width: 100%;
  position: fixed;
  top: 80px;
  right: 0%;
  padding:20px  20px 20px 280px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.marketplace-admin-banner > .header > h1 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.marketplace-admin-banner > .header > button {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  cursor: pointer;
}

.marketplace-admin-banner > .banner-container {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  gap: 20px;
  margin-top: 80px;
}

.marketplace-admin-banner > .banner-container > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.marketplace-admin-banner > .banner-container > .card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.marketplace-admin-banner > .banner-container > .card img {
  width: 100%;
  height: 220px;
}

.marketplace-admin-banner > .banner-container > .card > button {
  background-color: #d0300e;
  color: white;
  cursor: pointer;
  border: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  margin: 5px 0px;
}

.update-banner-overlay {
  position: fixed;
  top: 0%;
  right: 0%;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 323px 420px 324px 420px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(32, 32, 32, 0.5);
  z-index: 100;
}

.update-banner-overlay > .card {
  min-width: 600px;
  min-height: 377px;
  flex-shrink: 0;
  border-radius: 20px;
  padding: 30px;
  background: #fff;
}

.update-banner-overlay > .cards {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.update-banner-overlay > .cards > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.update-banner-overlay > .cards > p {
  width: 144px;
  height: 27px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.update-banner-overlay > .cards > .btn {
  display: flex;
  gap: 15px;
}

.update-banner-overlay > .cards > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.update-banner-overlay > .cards > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.update-banner-overlay > .cards > .btn > button:nth-child(2):disabled{
    background-color: lightgray;
    cursor: not-allowed;
}

.update-banner-overlay > .card > button {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.update-banner-overlay > .card > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.merchant-product-image {
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.merchant-product-image > h3 {
  width: 100%;
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.merchant-image {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
  margin: 20px 0px;
}

.merchant-image-card {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(45, 69, 150, 0.8);
  background: rgba(233, 244, 255, 0.7);
}

.merchant-image-card > svg {
  font-size: 40px;
}

.merchant-image-card > label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.merchant-image-card > label > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
}

.merchant-image > img {
  width: 150px;
  height: 100%;
  object-fit: contain;
}

.merchant-image > button {
  display: flex;
  height: 43px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  background: #d0300e;
}
