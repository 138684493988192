* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.logistictrip-container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.logistictrip-container > .header {
  display: flex;
  width:100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  right: 0%;
  top: 80px;
  background-color: white;
}

.logistictrip-container > .header >  .inner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logistictrip-container > .header >  .inner  > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistictrip-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistictrip-container > .header > button {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  border: none;
  cursor: pointer;
}

.logistictrip-container > .header > button:disabled{
    background-color: #bcbcbc;
    cursor: not-allowed;
}

.trip-dispatch {
  width: 100%;
  padding: 20px;
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.trip-dispatch > .dispatches {
  width: 360px;
  min-height: 570px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.trip-dispatch > .dispatches > span {
  width: 340px;
  display: flex;
  padding: 16px 20px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #bcbcbc;
  gap: 10px;
}

.trip-dispatch > .dispatches > span > input {
  color: #757575;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  outline: none;
  background: #f9f9f9;
  width: 100%;
}

.trip-dispatch > .dispatches > .card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.trip-dispatch > .dispatches > .card > p {
  padding: 5px 20px;
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.trip-dispatch > .dispatches > .card > .package-item {
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #e0e0e0;

}

.trip-dispatch > .dispatches > .card > .package-item .items {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.trip-dispatch > .dispatches > .card > .package-item .items > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.trip-dispatch > .dispatches > .card > .package-item > .items > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.trip-dispatch > .dispatches > .card > .package-item > button {
  display: flex;
  width: 80px;
  height: 38px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 0.5px solid #d0310e;
  background: #fff;
  color: #d0310e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.added-package {
  display: flex;
  flex-direction: column;
}

.added-package > .error-message {
  font-family: Montserrat;
  color: #d0310e;
}

.added-package > .card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 360px;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
}

.added-package > .card > .in {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.added-package > .card > .in > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.added-package > .card > .in > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.added-package > .card > button {
  display: flex;
  width: 80px;
  height: 38px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.trip-dispatch > .rider-con {
  width: 360px;
  display: flex;
  flex-direction: column;
}

.trip-dispatch > .rider-con > span {
  width: 340px;
  display: flex;
  padding: 16px 20px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #bcbcbc;
  gap: 10px;
  margin-bottom: 20px;
}

.trip-dispatch > .rider-con > span > input {
  color: #757575;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  outline: none;
  background: #f9f9f9;
  width: 100%;
}

.trip-dispatch > .rider-con > .rider-by-branch {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.trip-dispatch > .rider-con > .rider-by-branch > .card {
  display: flex;
  width: 346px;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid #284697;
}

.rider-by-branch > .card > .inner {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rider-by-branch > .card > .inner > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.rider-by-branch > .card > .inner > span {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.rider-by-branch > .card > .inner > p {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.rider-by-branch > .card > button {
  display: flex;
  width: 100px;
  height: 38px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  outline: none;
  cursor: pointer;
}

.trip-assigned-over {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(18, 18, 18, 0.5);
}
.trip-assigned-over > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.trip-assigned-over > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.trip-assigned-over > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.trip-assigned-over > .card > .btn {
  display: flex;
  gap: 20px;
}

.trip-assigned-over > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
}

.trip-assigned-over > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #189b48;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}
