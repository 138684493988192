* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.change-password-form {
  padding-top: 100px;
  width: 81.9%;
  height: 100vh;
  margin-left: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
  padding: 100px 20px;
}

.change-password-form > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  gap: 20px;
}

.change-password-form > form > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.change-password-form > form > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.change-password-form > form span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.change-password-form > form span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.change-password-form > form > span > .pass {
  width: 385px;
  height: 50px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.change-password-form > form > button {
  width: 385px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  border-radius: 38px;
  background: #d0300e;
  cursor: pointer;
  border: none;
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}
