* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.markeplace-store-container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.markeplace-store-container > .header {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.markeplace-store-container > .header > h1 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.markeplace-store-container > .header > .in {
  display: flex;
  gap: 30px;
  align-items: center;
}

.markeplace-store-container > .header > .in > button {
  display: flex;
  height: 43px;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  cursor: pointer;
}

.markeplace-store-container > .header > .in > span {
  display: flex;
  width: 280px;
  height: 44px;
  padding: 11px;
  align-items: center;
  border-radius: 30px;
  gap: 10px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.markeplace-store-container > .header > .in > span input {
  width: 100%;
  border: none;
  outline: none;
  background: #f9f9f9;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.markeplace-store-container > .merchant-table-markeplace {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-product-overlay {
  width: 81.9%;
  height: 100%;
  position: fixed;
  right: 0%;
  top: 10%;
  background: white;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.user-product-overlay > .header {
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 80px;
  right: 0;
  width: 81.9%;
  background-color: white;
}

.user-product-overlay > .header > button {
  display: flex;
  height: 43px;
  padding: 10px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  cursor: pointer;
}

.user-product-overlay > .product-list {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  gap: 20px;
  margin: 100px 0px;
}

.user-product-overlay > .product-list > .product {
  width: 100%;
  height: 356px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-product-overlay > .product-list > .product > img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.user-product-overlay > .product-list > .product > .card {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-product-overlay > .product-list > .product > .card > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 135%;
}

.user-product-overlay > .product-list > .product > .card > p {
  color: rgba(0, 0, 0, 0.7);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 135%;
}

.user-product-overlay > .product-list > .product > .card > span {
  color: #284697;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
}
