* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.support-nav {
  width: 260px;
  height: 1024px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 28px;
  padding: 15px;
  position: fixed;
  overflow: auto;
  z-index: 100;
  background: #ffcd00;
}

.support-logo {
  width: 100%;
}

.support-logo > img {
  width: 174px;
  height: 64px;
  object-fit: cover;
}

.support-mobile-link {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.support-mobile-link > a {
  text-decoration: none;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-family: Strike;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  padding: 10px;
  color: #284697;
}


.support-navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0%;
  right: 0%;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  height: 80px;
  padding: 0px 0px 0px 255px;
}

.support-navbar > h3 {
  width: 421px;
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;
}

.support-navbar > h3 > span {
  color: #284697;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

.user-combination {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 40px;
}

.user-combination > span {
  display: flex;
  gap: 10px;
  padding-right: 20px;
}

.user-combination > span {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  width: 45px;
  height: 45px;
  padding: 9.5px 9px 9.5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #e0e0e0;
}

