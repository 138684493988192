* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.shipping-form-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 40px 100px;
}

.shipping-form-container > h3 {
  color: #d0300e;
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 20px;
}

.shipping-form-container > P {
  width: 820px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

.shipping-line {
  width: 100%;
  height: 1px;
  background: rgba(32, 32, 32, 0.3);
  margin: 40px 0px;
}

.shipping-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.shipping-card > span {
  display: flex;
  gap: 34px;
}

.shipping-card > span > p {
  color: #b5b5b5;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.shipping-card > span > p > span {
  color: #d0300e;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.shipping-form-container > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shipping-form-container > span > p {
  color: #000;
  text-align: center;
  font-family: Montserrat Alternates;
  font-size: 20px;
  font-weight: 500;
  margin-top: 50px;
}

.shipping-form-container > form {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}

.shipping-combination {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.shipping-combination > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.shipping-combination > span > select {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-left: 10px;
  outline: none;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  color: #284697;
}

.shipping-combination > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.shipping-combination > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-left: 10px;
  outline: none;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.shipping-combination-address {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.shipping-combination-address > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.shipping-combination-address > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.shipping-combination-address > span > select {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  color: #284697;
  outline: none;
}

.shipping-date-time {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  margin-bottom: 30px;
}

.shipping-date-time > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.shipping-date-time > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.shipping-combination-address > span > input {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-left: 10px;
  outline: none;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.shipping-date-time > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-left: 10px;
  outline: none;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.entry-retun {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.entry-retun > svg {
  font-size: 20px;
}

.check-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0px;
}

.check-box > span {
  width: 100%;
  display: flex;
  gap: 5px;
}

.check-box > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.input-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 5px 0px;
}

.input-wrapper > input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  margin: 5px 0px;
  padding-left: 10px;
  border: 1px solid #e0e0e0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.zip-code {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #e0e0e0;
  border-top-left-radius: 5px;
  font-family: Montserrat;
  border-bottom-left-radius: 5px;
}

.shipping-form-container > form > span {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.shipping-form-container > form > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.shipping-form-container > form > span > select {
  width: 47.5%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-left: 10px;
  outline: none;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.shipping-form-container > form > button {
  width: 160px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: #d0300e;
  border-radius: 38px;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 30px;
}

.required-label::after {
  content: "*";
  color: red;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .shipping-form-container {
    padding: 10px;
  }

  .shipping-form-container > p {
    width: 100%;
  }

  .shipping-form-container > form {
    width: 98%;
    gap: 20px;
  }

  .shipping-combination {
    gap: 10px;
  }

  .shipping-combination-address {
    gap: 10px;
  }

  .shipping-date-time {
    gap: 10px;
  }
}

@media screen and (max-width: 650px) {
  .shipping-form-container {
    padding: 10px;
  }

  .shipping-form-container > h3 {
    font-size: 22px;
  }

  .shipping-form-container > p {
    width: 100%;
    color: #202020;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .shipping-card > svg {
    width: 100%;
    color: #b5b5b5;
    text-align: center;
    font-family: Montserrat;
    font-weight: 400;
    margin: 20px 0px;
  }

  .shipping-line {
    display: none;
  }

  .shipping-form-container > form {
    width: 100%;
    gap: 20px;
  }

  .shipping-combination {
    flex-direction: column;
    gap: 10px;
  }

  .shipping-combination-address {
    flex-direction: column;
    gap: 10px;
  }

  .shipping-date-time {
    flex-direction: column;
    gap: 10px;
  }

  .shipping-form-container > form > span > select {
    width: 100%;
  }

  .shipping-form-container > form > button {
    width: 100%;
  }

  .entry-retun {
    margin: 0px;
    height: 30px;
  }
}

.edit-box {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #284697;
  margin-top: 60px;
}

.edit-box > p {
  color: #000;
  text-align: center;
  font-family: Montserrat Alternates;
  font-size: 20px;
  font-weight: 500;
}

.edit-box > a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
}

.edit-box > a > p {
  color: #284697;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
}

.package-info {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  background: #f5f5f5;
  margin-top: 10px;
  padding: 15px;
}

.package-info > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.item-box {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #284697;
  margin-top: 60px;
}

.item-box > p {
  color: #000;
  text-align: center;
  font-family: Montserrat Alternates;
  font-size: 20px;
  font-weight: 500;
}

.item-box > a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #284697;
  text-decoration: none;
  font-family: Montserrat;
}

.summary-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
  gap: 20px;
}

.summary-info > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;
  margin-bottom: 20px;
}

.summary-info > .wrapper > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;
  padding: 0px 20px;
}

.summary-info > .wrapper > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}

.summary-info > .wrapper > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.summary-info > .delivery {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.summary-info > .delivery > h3 {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.estimate {
  width: 100%;
  height: 154px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  gap: 20px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.estimate > h3 {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #284697;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 700;
}

.estimate > span {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.estimate > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .package-info {
    width: 100%;
  }

  .edit-box {
    width: 100%;
  }
  .item-box {
    width: 100%;
  }

  .summary-info {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .package-info {
    width: 100%;
  }

  .edit-box {
    width: 100%;
  }
  .item-box {
    width: 100%;
  }

  .summary-info {
    width: 100%;
  }

  .summary-info > .delivery {
    padding: 10px;
  }

  .summary-info > .delivery > h3 {
    font-size: 20px;
    font-weight: 700;
  }

  .summary-info > .wrapper {
    gap: 5px;
    padding: 10px;
  }

  .summary-info > .wrapper > span {
    width: 100%;
    padding: 0%;
  }

  .summary-info > .wrapper > span > h3 {
    font-size: 12px;
    font-weight: 700;
  }

  .summary-info > .wrapper > span > p {
    font-size: 15px;
    font-weight: 400;
  }

  .estimate > h3 {
    font-size: 16px;
    font-weight: 700px;
    margin: 0px 10px;
  }

  .estimate > span {
    padding: 20px 10px;
  }
}

.package-receipt-card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 0px  100px 0px;
  position: fixed;
  overflow-y: scroll;
  background-color: #202020;
  top: 10%;
}

.package-receipt-wrapper-box {
  width: 595px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px 30px;
  background: #fff;
}

.package-receipt-wrapper-box > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 600;
  margin: 20px 0px;
}
.package-receipt-wrapper-box > p {
  color: #000;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 600;
  margin-bottom: 20px;
}

.logo-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-box {
  width: 80px;
  height: 29px;
}

.logo-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-card > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 400;
}

.package-receipt-span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  flex-direction: column;
}

.package-receipt-span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 800;
}

.package-receipt-span > img {
  width: 182px;
  height: 40px;
  object-fit: contain;
}

.package-receipt-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgba(32, 32, 32, 0.5);
  border-bottom: 1px solid rgba(32, 32, 32, 0.5);
  padding: 20px 0px;
  gap: 95px;
  margin: 30px 0px;
}

.package-recipt-con-1 {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
}

.package-recipt-con-1 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.package-recipt-con-1 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;
}

.package-recipt-con-1 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 6px;
  font-weight: 400;
}

.package-recipt-con-2 {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
}

.package-recipt-con-2 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.package-recipt-con-2 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;
}

.package-recipt-con-2 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 6px;
  font-weight: 400;
}

.package-recipt-con-3 {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.package-recipt-con-3 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.package-recipt-con-3 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;
}

.package-recipt-con-3 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 6px;
  font-weight: 400;
}

.package-trip-card {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 100px;
}

.package-trip-span-1 {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 50px;
  align-items: flex-end;
  justify-content: flex-end;
}

.package-trip-span-1 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}

.package-trip-span-2 {
  width: 40%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.package-trip-span-2 > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.package-trip-span-2 > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 400;
}
.package-trip-span-2 > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
}

.package-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
}

.package-info-wrapper-1 {
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.6);
}

.package-info-wrapper-1 > h3 {
  width: 76.42px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 600;
}

.package-info-wrapper-2 {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.6);
}

.package-info-wrapper-2 > h4 {
  width: 76.42px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 7px;
  font-weight: 700;
}
.package-info-wrapper-2 > p {
  width: 76.42px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 7px;
  font-weight: 400;
}

.package-support {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  margin: 20px 0px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.5);
}

.package-support > p {
  color: #000;
  font-family: Montserrat;
  font-size: 6px;
  font-weight: 400;
}

.package-condition {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.package-condition > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 700;
}

.package-condition > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.package-condition > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 5px;
  font-weight: 400;
}

.printing-box {
  display: flex;
  align-items: flex-start;
  width: 595px;
  max-width: 650px;
  margin: 10px 0px;
}

.printing-box > button {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.toast-container {
  width: 50%;
}

@media screen and (max-width: 650px) {
  .package-receipt-card {
    padding: 20px 20px 100px 20px ;
  }

  .package-receipt-wrapper-box {
    width: 100%;
    height: fit-content;
    padding: 10px;
  }

  .printing-box {
    width: 100%;
  }

  .package-receipt-info {
    flex-direction: column;
    gap: 20px;
  }

  .package-recipt-con-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .package-recipt-con-1 > span {
    width: 100%;
  }

  .package-recipt-con-2 {
    width: 100%;
  }

  .package-recipt-con-2 > span {
    width: 100%;
  }
  .package-recipt-con-3 {
    width: 100%;
  }

  .package-trip-card {
    width: 100%;
    gap: 20px;
    flex-direction: column;
  }

  .package-trip-span-1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .package-trip-span-2 {
    width: 100%;
  }
  .package-trip-span-2 > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .toast-container {
    width: 100%;
  }
  
}
