* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }


  .super_admin_creator_container {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
 
  .super_admin_creator_container > .header {
    display: flex;
    width: 100%;
    height: 100px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }
  

  .super_admin_creator_container > .header > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  

  .super_admin_creator_container > .header > span {
    display: flex;
    width: 280px;
    height: 44px;
    padding: 20px;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid rgba(32, 32, 32, 0.6);
  }


  .super_admin_creator_container > .header > span > input {
    width: 100%;
    font-family: Montserrat;
    font-size: 12px;
    border: none;
    outline: none;
  }
  
  
  .super_admin_creator_container > .header > button {
    height: 43px;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 66px;
    background: #d0300e;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    border: none;
    outline: none;
    cursor: pointer;
  }
  


  .super_admin_creator_container > .creator-packages-table {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .super_admin_creator_container > .creator-packages-table  > table {
    width: 100%;
  }
  

  .super_admin_creator_container > .creator-packages-table  > table > thead > tr > th {
    color: #202020;
    font-size: 12px;
    font-weight: 600;
    text-align: start;
    font-family: Montserrat;
    padding: 10px 3px;
  }

  .super_admin_creator_container > .creator-packages-table  > table > tbody > tr > td {
    color: #202020;
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-align: start;
    padding: 10px 3px;
  }

  .super_admin_creator_container > .creator-packages-table  > table > tbody > tr > td > button {
    background: transparent;
    font-family: Montserrat;
    color: #d0300e;
    border: none;
    cursor: pointer;
  }

  .creator-package-overlay {
    position: fixed;
    height: 100%;
    top: 0%;
    right: 0%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgba(32, 32, 32, 0.7);
    z-index: 100;
    padding: 20px;
  }

  .creator-package-overlay > .package-info {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .creator-package-overlay > .package-info  > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .creator-package-overlay > .package-info  > span > h3 {
font-family: Strike;
  }

  .creator-package-overlay > .package-info  > span > button {
    background: transparent;
    border: none;
    font-size: 24px;
  }
  