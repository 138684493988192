* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  .charterer-vehicle-detail {
    width: 87.5%;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 120px 100px;
    margin-left: 180px;
    gap: 30px;
  }
  
  .charterer-vehicle-con1 {
    width: 50%;
    height: 300px;
  }

 
  
  .charterer-vehicle-con1 > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .charterer-vehicle-con2 {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 0px 30px;
  }

  .charterer-vehicle-con2 > h3 {
    color: #284697;
    font-family: Montserrat;
    font-size: 34px;
    font-weight: 700;
  }
  
  .charterer-vehicle-con2 > h4 {
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
  }
  
 
  .charterer-vehicle-con2 > p {
    color: #121212;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5rem;
  }
  
  .charterer-vehicle-con2 > .btn {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  
  .charterer-vehicle-con2 > .btn > button:nth-child(1) {
    width: 120px;
    height: 40px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #284697;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }
  .charterer-vehicle-con2 > .btn > button:nth-child(2) {
    width: 120px;
    height: 40px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #d0310e;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }
 
  
  .charterer-vehicle-con2 > a {
    text-decoration: none;
    color: #000;
    font-family: Montserrat;
  }


  .vehicles-deletes {
    position: fixed;
    top: 0%;
    left: 0%;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(32, 32, 32, 0.5);
    z-index: 100;
  }
  
  .vehicle-deletes-card {
    width: 350px;
    height: 250px;
    display: flex;
    padding: 25px 50px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    background: #fff;
  }
  
  .vehicle-deletes-card > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 800;
  }
  .vehicle-deletes-card > p {
    color: #202020;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 118.243%;
  }
  
  .vehicle-deletes-card > .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .vehicle-deletes-card > .btn > button:nth-child(1) {
    width: 120px;
    height: 40px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
  }
  .vehicle-deletes-card > .btn > button:nth-child(2) {
    width: 120px;
    height: 40px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #d0310e;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border: none;
  }

  .vehicle-edit-overlay {
    position: fixed;
    top: 0%;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(32, 32, 32, 0.5);
    z-index: 100;
  }
  
  .vehicle-edit-overlay > .edit-overlay-card {
    width: 750px;
    display: flex;
    padding: 25px 20px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    background: #fff;
  }
  
  .vehicle-edit-overlay > .edit-overlay-card > h3 {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
  }
  
  .vehicle-edit-overlay > .edit-overlay-card > form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  
  .vehicle-edit-overlay > .edit-overlay-card > form > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  
  .vehicle-edit-overlay > .edit-overlay-card > form > span > label {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .vehicle-edit-overlay> .edit-overlay-card > form > span > input {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding-left: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .vehicle-edit-overlay> .edit-overlay-card > form > span > textarea {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding: 10px;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .vehicle-edit-overlay > .edit-overlay-card > form > .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .vehicle-edit-overlay > .edit-overlay-card > form > .btn > button:nth-child(1) {
    width: 100%;
    height: 45px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
  }
  .vehicle-edit-overlay > .edit-overlay-card > form > .btn > button:nth-child(2) {
    width: 100%;
    height: 45px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #d0310e;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border: none;
    margin-top: 20px;
  }

  .vehicle-assign-state {
    position: fixed;
    top: 0%;
    left: 0%;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(32, 32, 32, 0.5);
  }
  
  .vehicle-assign-state > .card {
    width: 450px;
    height: 250px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    background: #fff;
  }

  .vehicle-assign-state > .card > span {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .vehicle-assign-state > .card > span > button {
    background: transparent;
    border: none;
  }

  .vehicle-assign-state > .card > h3 {
    font-family: Montserrat;
  }

  .vehicle-assign-state > .card > select {
    width: 100%;
    height: 45px;
    border: 1px solid black;
    background: transparent;
    font-family: Montserrat;
    border-radius: 5px;
    padding: 10px;
  }

  .vehicle-assign-state > .card > button {
    background: #d0310e;
    font-family: Montserrat;
    padding:10px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    color: white;
  }

  .vehicle-assign-state > .card > form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .vehicle-assign-state > .card > form > select {
    width: 100%;
    height: 45px;
    border: 1px solid black;
    background: transparent;
    font-family: Montserrat;
    border-radius: 5px;
    padding: 10px;
  }

  .vehicle-assign-state > .card > form > button {
    background: #d0310e;
    font-family: Montserrat;
    padding:10px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    color: white;
  }
  