* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.logistic-pickups-details {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.logistic-pickups-details > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.logistic-pickups-details > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistic-pickups-details > .header > .icon {
  cursor: pointer;
}

.btn-ship {
  width: 100%;
  margin: 10px 0px;
  padding-left: 20px;
}

.btn-ship > button {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background: #d0310e;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.ship-delete {
  width: 81.9%;
  height: 100%;
  position: fixed;
  top: 0%;
  right: 0%;
  background: rgba(18, 18, 18, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ship-delete > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.ship-delete > .card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}

.ship-delete > .card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.ship-delete > .card > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ship-delete > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.ship-delete > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}
