* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.get-mother-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px 100px;
}

.get-mother-card > h3 {
  width: 50%;
  font-family: Strike;
  color: #284697;
  font-size: 2.5rem;
  text-align: center;
}

.get-mother-card > p {
  width: 45%;
  font-family: Montserrat;
  line-height: 1.5rem;
  text-align: center;
}

.get-mother-card > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.get-quote-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.get-quote-card > h2 {
  font-family: Strike;
  color: #d0300e;
  font-size: 2.5rem;
}

.get-quote-card > h3 {
  font-family: Montserrat ;
  color: #284697;
  font-size: 2rem;
  text-align: center;
}

@media screen and (max-width: 896px) {
  .get-mother-card {
    padding: 10px;
  }

  .get-mother-card > h3 {
    width: 100%;
    font-size: 1.5rem;
  }

  .get-mother-card > p {
    width: 100%;
  }

  .get-mother-card > span {
    flex-direction: column;
    gap: 20px;
  }

  .get-quote-card > h3 {
    font-size: 1.5rem;
  }
}

.get-quote-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 100px;
}

.get-form-container {
  width: 85%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 50px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.getquote-con {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.get-form-container > form > .input-combination {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.get-form-container > form > .input-combination > span {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.getquote-con > span {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.getquote-con > span > h3 {
  color: #d0300e;
  font-family: Montserrat Alternates;
  font-size: 38px;
  font-weight: 700;
}
.getquote-con > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.get-form-container > form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.get-form-container > form > .input-combination > span > label {
  width: 100%;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0px;
}

.get-form-container > form > .input-combination > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin: 10px 0px;
  outline: none;
  color: #b5b5b5;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  background-color: transparent;
}
.get-form-container > form > .input-combination > span > select {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin: 10px 0px;
  outline: none;
  color: #b5b5b5;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.get-form-container > form > button {
  width: 100%;
  height: 55px;
  border-radius: 30px;
  background: #d0300e;
  color: #fff;
  border: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  margin: 50px 0px;
  cursor: pointer;
}

@media screen and (max-width:896px) {
  .get-quote-wrapper {
    width: 100%;
    padding: 30px 10px;
  }

  .get-form-container > form > .input-combination {
    flex-direction: column;
    gap: 10px;
  }

  .get-form-container {
    width: 100%;
    padding: 10px;
  }

  .get-form-container > form > button {
    width: 100%;
    margin: 20px 0px;
  }
}

.get-quote-pop-card {
  width:100%;
  height: 100%;
  position: fixed;
  top: 0%;
  z-index: 100;
  padding: 120px 0px;
  background: rgba(18, 18, 18, 0.6);
  padding-bottom: 10px;
}

.get-quote-pop-wrapper {
  width: 580px;
  min-height: 510px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background: #fff;
  margin: auto;
  gap: 10px;
}

.get-quote-pop-wrapper > h3 {
  width: 420px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 700;
  margin-top: 30px;
}

.get-quote-pop-wrapper > p {
  width: 420px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 123.023%;
}


.get-quote-pop-wrapper > span {
  width: 100%;
  display: flex;
  padding: 0px 70px;
  justify-content: space-between;
  margin-top: 40px;
  /* background-color: red; */

}

.get-quote-pop-wrapper > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.get-quote-package {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 70px;
  /* background-color: green; */
}

.gets-quotes-packages {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 0px 70px;
  /* background-color: red; */
}

.gets-quotes-packages > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.gets-quotes-packages > h4 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.get-qoute-cost {
  width: 100%;
  height: 85px;
}

.get-qoute-cost > span {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 70px;
  justify-content: space-between;
  align-items: center;
  background: #FBFBFB;
}

.get-qoute-cost > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.get-quote-pop-wrapper > button {
  width: 150px;
  height: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  background: #d0300e;
  margin: 20px 0px;
}

.get-qoute-cost > .wrapper {
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 70px;
}

.get-qoute-cost > .wrapper > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.toast-container {
  width: 45%;
}


@media screen and (max-width:896px) {
  .get-quote-pop-card {
    width: 100%;
    padding: 20px 10px;
  }

  .get-quote-pop-wrapper {
    width: 100%;
  }

  .get-quote-pop-wrapper > h3 {
    width: 95%;
    text-align: center;
  }
  .get-quote-pop-wrapper > p {
    width: 95%;
    text-align: center;
  }

  .get-quote-pop-wrapper > span {
    padding: 0px 20px;
  }

  .get-qoute-cost > span {
    padding: 0px 20px;
  }

  .get-qoute-cost > span > h3 {
    font-size: 14px;
  }
  .get-qoute-cost > .wrapper {
    padding: 0px 20px;
  }

  .get-qoute-cost > .wrapper > h3 {
    font-size: 14px;
  }

  .gets-quotes-packages {
    padding: 0px 20px;
  }

  .toast-container {
    width: 100%;
  }
}
