* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

.custom-loader {
  margin: 20px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #284697);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 0.5s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
