* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.superadmin-token-container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.superadmin-token-container > .header {
  display: flex;
  width: 100%;
  height: 90px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  top: 80px;
  right: 0%;
  background-color: white;
}

.superadmin-token-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.superadmin-token-container > .header > span {
  display: flex;
  width: 280px;
  height: 44px;
  gap: 10px;
  padding: 12px;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.superadmin-token-container > .header > span > input {
  border: none;
  outline: none;
  width: 100%;
  font-family: Montserrat;
  color: rgba(32, 32, 32, 0.7);
  font-size: 12px;
  font-weight: 400;
}

.superadmin-token-container > .user-token-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
