*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }




