* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  

  .super-admin-viewall-package {
  width: 81.9%;
  padding-top: 100px;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  }

  .super-admin-viewall-package > .header {
    width: 100%;
    height: 100px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 358px;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  }

  .super-admin-viewall-package > .header > .back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
  
  
  .super-admin-viewall-package > .header > .back > p {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }
  
  .super-admin-viewall-package > .header > .in {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .super-admin-viewall-package > .header > .in > span {
    display: flex;
    gap: 10px;
    width: 280px;
    height: 44px;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(32, 32, 32, 0.6);
    padding: 20px;
  }
  
  .super-admin-viewall-package > .header > .in > span > input {
    width: 100%;
    border: none;
    outline: none;
    font-family: Montserrat;
  }

  .super-admin-viewall-package > .packages-table {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }