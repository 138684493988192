* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  
  .logistic-nav-container {
    display: flex;
    width: 260px;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: #ffcd00;
    flex-shrink: 0;
    position: fixed;
    padding: 10px 20px;
    overflow: auto;
    z-index: 100;
  }
  
  .logistic_admin_header {
    width: 100%;
    background-color: white;
    position: fixed;
    right: 0%;
    top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 20px 0px 280px;
    height: 80px;
    border-bottom: 1px solid #eeeeee;
    z-index: 100;
  }
  
  .logistic_admin_header > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
  }
  
  .logistic_admin_header > p > span {
    color: #284697;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  
  .logistic_admin_header > .side {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Montserrat;
    font-size: 18px;
  }
  
  .logistic_admin_header > .side > .round {
    display: flex;
    width: 45px;
    height: 45px;
    padding: 9.5px 9px 9.5px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 40px;
    border: 1px solid #e0e0e0;
  }
  
  .logistic-nav-container > .links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 600px;
    overflow-y: scroll;
  }
  
  .logistic-nav-container > .links > a {
    display: flex;
    width: 220px;
    height: 50px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    color:#284697;
    font-family: Strike;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
  }
  
  .logistic-nav-container > .links > a > .icon {
    width: 26px;
    height: 26px;
  }

  .admins-button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    min-height: 0;
    border: none;
    z-index: 101;
  }
  
  .admins-button > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .admins-button > span > .icon-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    gap: 10px;
  }
  
  .admins-button > span > .icon-card > h2 {
     color:#284697;
    font-family: Strike;
    font-size: 16px;
    font-weight: 600;
  }
  
  .admins-button > .admins-drop {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap:10px;
    border: none;
    max-height: 200px; 
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #fff transparent ;
  }
  
  
  /* For Webkit-based browsers (Chrome, Safari) */
  .admins-button > .admins-drop::-webkit-scrollbar {
    width: 2px;
  }
  
  .admins-button > .admins-drop::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .admins-button > .admins-drop::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 4px;
  }
  
  
  
  .admins-button > .admins-drop > a {
    display: flex;
    width: 200px;
    height: 50px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    color:#284697;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
  }
  
  .admins-button > .admins-drop > a > .icon {
    width: 26px;
    height: 26px;
  }
  
  
  