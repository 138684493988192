* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.market-overview-container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.market-overview-container > .header {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.market-overview-container > .header > h1 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.market-overview-container > .card-overview {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.market-overview-container > .card-overview .box {
  display: flex;
  width: 260px;
  height: 101px;
  padding: 15px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
}

.market-overview-container > .card-overview .box > p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.market-overview-container > .card-overview .box > span {
  color: #000;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 500;
  line-height: 39px;
}
