* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.passenger-detail-mother {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px;
}

.passenger-detail-mother > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

.passenger-detail-mother > .wrapper {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 104px;
  margin-top: 30px;
}

.passenger-detail-card-1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.passenger-detail-card-1 > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 400;
}
.passenger-detail-card-1 > p {
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}


@media screen and (max-width:896px) {
    .passenger-detail-mother {
        padding: 10px;
    }

    .passenger-detail-mother > p {
      text-align: center;
    }

    .passenger-detail-mother > .wrapper {
        width: 100%;
        flex-direction: column;
        gap: 20px;
        margin-top: 10px;
    }

    .passenger-detail-card-1 > p {
        text-align: center;
    }
}

.passenger-detail-card-1 > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.passenger-detail-card-1 > form > .passenger-detail-con {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 104px;
}

.passenger-detail-card-1 > form > .passenger-detail-con > .passenger-detail-span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}

.passenger-detail-card-1 > form > .passenger-detail-con > .passenger-detail-span > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.passenger-detail-card-1 > form > .passenger-detail-con > .passenger-detail-span > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.passenger-detail-card-1 > form > .passenger-detail-con > .passenger-detail-span > span > input {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.passenger-detail-card-1 > form > .passenger-detail-con > .passenger-detail-span > span > select {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #b5b5b5;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}


.passenger-detail-summary {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;   
    padding: 10px;
    background: #E1ECF9;
}

.passenger-detail-summary > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 400;
}

.passenger-detail-summary > .card {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}

.passenger-detail-summary > .card > img {
    width: 158px;
    height: 160px;
    object-fit: cover;
}

.passenger-detail-summary > .card > .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.passenger-detail-summary > .card > .wrapper > h3 {
color: #202020;
font-family: Montserrat;
font-size: 20px;
font-weight: 700;
}

.passenger-detail-summary > .card > .wrapper > span {
display: flex;
align-items: center;
gap: 5px;
}

.passenger-detail-summary > .card > .wrapper > span > p {
color: rgba(32, 32, 32, 0.80);
font-family: Montserrat;
font-size: 14px;
line-height: 24px; 
font-weight: 400;
}

.passenger-detail-summary > .card > .wrapper > span > p > span {
    font-weight: 600;
}

.passenger-detail-summary-card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}

.passenger-detail-summary-card > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.passenger-detail-summary-card > span > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
}
.passenger-detail-summary-card > span > h4 {
color: #202020;
font-family: Montserrat;
font-size: 16px;
font-weight: 400;
}
.passenger-detail-summary-card > span > h3 {
    color: #202020;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
}

.passenger-detail-summary-card > button {
width: 426px;
height: 50px;
padding: 10px;
gap: 10px;
border-radius: 20px;
background:#D0300E;
font-family: Montserrat;
color:white;
border: none;
outline: none;
display: flex;
justify-content: center;
align-items: center;
}



.passenger-detail-card-1 > form > .next-of-kin {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2,2fr);
  gap: 20px;
}

.passenger-detail-card-1 > form > .next-of-kin > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.passenger-detail-card-1 > form > .next-of-kin > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.passenger-detail-card-1 > form > .next-of-kin > span > input {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #b5b5b5;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.passenger-detail-card-1 > form > .next-of-kin > span > select {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}


.passenger-detail-card-1 > form > .term-conditon {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.passenger-detail-card-1 > form > .term-conditon > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
}
.passenger-detail-card-1 > form > .term-conditon > p {
  width: 460px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 136.413%;
}


@media screen and (max-width:896px) {
  .passenger-detail-summary {
    display: none;
  }
    .passenger-detail-card-1 > form > .passenger-detail-con {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .passenger-detail-summary-card > button {
        width: 100%;
    }

    .passenger-detail-card-1 > form > .term-conditon > p {
        width: 100%;
    }
}


.passenger-detail-card-2 {
  display: none;
}


.passenger-detail-card-2 > .detail-summary {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;   
  padding: 10px;
  background: #E1ECF9;
}

.passenger-detail-card-2 > .detail-summary > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 400;
}

.passenger-detail-card-2 > .detail-summary > .card {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.passenger-detail-card-2 > .detail-summary > .card > img {
  width: 158px;
  height: 160px;
  object-fit: cover;
}

.passenger-detail-card-2 > .detail-summary > .card > .wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.passenger-detail-card-2 > .detail-summary > .card > .wrapper > h3 {
color: #202020;
font-family: Montserrat;
font-size: 20px;
font-weight: 700;
}

.passenger-detail-card-2 > .detail-summary > .card > .wrapper > span {
display: flex;
align-items: center;
gap: 5px;
}

.passenger-detail-card-2 > .detail-summary > .card > .wrapper > span > p {
color: rgba(32, 32, 32, 0.80);
font-family: Montserrat;
font-size: 14px;
font-style: normal;
line-height: 24px; 
font-weight: 400;
}

.passenger-detail-card-2 > .detail-summary > .card > .wrapper > span > p > span {
  font-weight: 600;
}

.passenger-detail-card-2 > .detail-summary > .passenger-detail-summary-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.passenger-detail-card-2 > .detail-summary > .passenger-detail-summary-card > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.passenger-detail-card-2 > .detail-summary > .passenger-detail-summary-card > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; 
}
.passenger-detail-card-2 > .detail-summary > .passenger-detail-summary-card > span > h4 {
color: #202020;
font-family: Montserrat;
font-size: 16px;
font-weight: 400;
}
.passenger-detail-card-2 > .detail-summary > .passenger-detail-summary-card > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
}

.passenger-detail-card-2 > .detail-summary > .passenger-detail-summary-card > button {
width: 426px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
gap: 10px;
border-radius: 20px;
background:#D0300E;
font-family: Montserrat;
color:white;
border: none;
outline: none;
}

.charter-success-msg {
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.5);
  padding: 50px 30px;
  z-index: 101;
}

.charter-success-msg > .card {
  width: 500px;
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.charter-success-msg > .card > h3 {
color: #000;
text-align: center;
font-family: Montserrat;
font-size: 22px;
font-weight: 700;
}

.charter-success-msg > .card > p {
  color: #334155;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 148%;
  margin-top: 10px;
}

.charter-success-msg > .card > .wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  padding: 10px 0px;
}

.charter-success-msg > .card > .wrapper > h3 {
color: #334155;
text-align: center;
font-family: Montserrat;
font-size: 16px;
font-weight: 600;
line-height: 148%;
}
.charter-success-msg > .card > .wrapper > p {
color: #334155;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-weight: 400;
line-height: 148%;
}
.charter-success-msg > .card > .wrapper > h4 {
  color:  #334155;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 148%; 
  margin-top: 30px;
}
.charter-success-msg > .card > .wrapper > button {
  display: flex;
  width: 100px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid  #D0300E ;
  background: #FFF;
  color:  #D0300E;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  margin-top: 30px;
}



@media screen and (max-width:896px) {
   .passenger-detail-card-2 {
      width: 100%;
      display: block;
    }

    .passenger-detail-card-2 > .detail-summary > .card > .wrapper > span > p {
      font-size: 9px;
    }

    .passenger-detail-card-2 > .detail-summary >  .passenger-detail-summary-card > span > h3  {
      font-size: 16px;
    }

    .charter-success-msg {
    padding: 10px;
    }
    .charter-success-msg > .card {
      width: 100%;
    }

    .passenger-detail-card-2 > .detail-summary > .passenger-detail-summary-card > button {
      width: 100%;
    }

    .charter-success-msg {
      padding: 10px;
    }

    .charter-success-msg > .card {
      width: 100%;
      padding: 10px;
    }
}

