* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.merchant-product-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 100px;
  flex-direction: column;
}

.merchant-product-container > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merchant-product-container > .wrapper > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;

}

.merchant-product-container > .wrapper > span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.merchant-product-container > .wrapper > span > a {
  text-decoration: none;
  color: #fff;
  display: flex;
  gap: 3px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  height: 43px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #d0300e;
}

.merchant-product-container > .wrapper > span > form {
  width: 280px;
  height: 44px;
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 40px;
  gap: 5px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.merchant-product-container > .wrapper > span > form > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-align: start;
  color: rgba(32, 32, 32, 0.7);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  background: transparent;
}

.merchant-product-container > .wrapper > span > form > button {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  background: transparent;
}

@media screen and (max-width:768px) {
  .merchant-product-container {
    padding: 90px 10px;
  }
}

@media screen and (max-width: 650px) {
  .merchant-product-container {
    padding: 90px 10px;
  }

  .merchant-product-container > .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .merchant-product-container > .wrapper > span {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .merchant-product-container > .wrapper > span > form {
    width: 100%;
  }

  .merchant-product-container > .wrapper > span > a {
    width: 100%;
  }
}

.add-product-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 120px 100px;
}

.add-product-container > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.add-product-container > form > .wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0px;
}

.add-product-container > form > .wrapper > button {
  display: flex;
  height: 43px;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 30px;
  background: #284697;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.add-product-container > form > .wrapper > span {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
}

.add-product-container > form > .wrapper > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.add-product-container > form > .wrapper > span > a {
  text-decoration: none;
  color: #000;
  font-size: 24px;
}

.merchant-product-image {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.merchant-product-image > h3 {
  width: 100%;
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.merchant-images {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
  margin: 20px 0px;
}

.merchant-image-card {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(45, 69, 150, 0.8);
  background: rgba(233, 244, 255, 0.7);
}

.merchant-image-card > svg {
  font-size: 40px;
}

.merchant-image-card > label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.merchant-image-card > label > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
}

.merchant-images > img {
  width: 150px;
  height: 100%;
  object-fit: contain;
}

.merchant-images > button {
  display: flex;
  height: 43px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  background: #d0300e;
}

.add-product-container > form > .product-mother {
  width: 100%;
  display: flex;
  gap: 10px;
}

.add-product-container > form > .product-mother > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.add-product-container > form > .product-mother > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.add-product-container > form > .product-mother > span > input {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding-left: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  
}
.add-product-container > form > .product-mother > span > select {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.add-product-container > form > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.add-product-container > form > span > select {
  width: 32.7%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.add-product-container > form > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.merchant-product-description {
  width: 100%;
  display: flex;
  padding: 10px 0px;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.merchant-product-description > h3 {
  color: rgba(32, 32, 32, 0.5);
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
}

.add-product-container > form > textarea {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 10px;
  outline: none;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.product-combination {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.product-combination > span {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.product-combination > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.product-combination > span > input {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding-left: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.product-combination > span > select {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.merchant-product-tag-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.merchant-product-tag-detail > h3 {
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}


@media screen and (max-width: 896px) {
  .add-product-container {
    padding: 80px 10px;
  }

  .merchant-images {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
  }

  .add-product-container > form > .wrapper > span > h3 {
    font-size: 16px;
  }
  .add-product-container > form > .wrapper > span > a {
    font-size: 16px;
  }

  .add-product-container > form > .product-mother {
    flex-direction: column;
  }

  .product-combination {
    flex-direction: column;
  }

  .add-product-container > form > span > label {
    font-size: 14px;
  }

  .product-combination > span > label { 
    font-size: 12px;
  }

  .add-product-container > form > .product-mother > span > label {
    font-size: 12px;
  }
}

.product-merchant-overlay {
  position: fixed;
  top: 66px;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 323px 420px 324px 420px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(32, 32, 32, 0.5);
}

.product-merchant-overlay > .card {
  width: 600px;
  height: 177px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  background: #fff;
}

.product-merchant-overlay > .card > p {
  width: 100%;
  padding: 25px;
  background-color: green;
  color: white;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 400;
}

.product-merchant-overlay > .card > .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
}

.product-merchant-overlay > .card > .btn > button {
  padding: 10px 40px;
  background-color: #284697;
  color: white;
  cursor: pointer;
  border: none;
  font-family: Montserrat;
  border-radius: 10px;
}

@media screen and (max-width:768px) {
  .product-merchant-overlay {
    padding: 10px;
  }
}

@media screen and  (max-width:650px) {
  .product-merchant-overlay {
    padding: 10px;
  }

  .product-merchant-overlay > .card > p {
    text-align: center;
  }
}

/* .product-merchant-overlay > .error-card {
  width: 600px;
  height: 177px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background: #fff;
}

.product-merchant-overlay > .error-card > p {
  padding: 0px 10px;
  color: #000;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 400;
  margin: 10px 0px;
}

.product-merchant-overlay > .error-card > .error-tag {
  width: 100%;
  padding: 15px 10px;
  display: flex;
  align-items: flex-start;
  background-color: #d0300e;
}

.product-merchant-overlay > .error-card > .error-tag > h3 {
  color: white;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 400;
}

.product-merchant-overlay > .error-card > .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
}

.product-merchant-overlay > .error-card > .btn > button {
  padding: 10px 40px;
  background-color: #284697;
  color: white;
  cursor: pointer;
  border: none;
  font-family: Montserrat;
  border-radius: 10px;
  margin-top: 20px;
} */

.merchant-product-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px 0px;
}

.merchant-product-card > .product-card-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.merchant-product-card > .product-card-info > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.merchant-product-card > .product-card-info > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merchant-product-card > .product-card-info > span > a {
  text-decoration: none;
}

.merchant-product-card > .product-card-info > span > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}
.merchant-product-card > .product-card-info > h4 {
  color: #284697;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.merchant-product-card > .product-card-info > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

@media screen and (max-width: 650px) {
  .merchant-product-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.merchant-product-detail {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 120px 100px;
}

.merchant-product-con1 {
  width: 50%;
  height: 500px;
}

.merchant-product-con1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.merchant-product-con2 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 0px 30px;
}

.merchant-product-con2 > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 700;
}
.merchant-product-con2 > h4 {
  color: #284697;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.merchant-product-con2 > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5rem;
}

.merchant-product-con2 > .btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.merchant-product-con2 > .btn > button:nth-child(1) {
  width: 120px;
  height: 40px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #284697;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}
.merchant-product-con2 > .btn > button:nth-child(2) {
  width: 120px;
  height: 40px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.merchant-product-con2 > a {
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
}

@media screen and (max-width:768px) {
  .merchant-product-detail {
    padding: 80px 10px;
    flex-direction: column;
  }

  .merchant-product-con1 {
    width: 100%;
  }
  .merchant-product-con2 {
    width: 100%;
    padding: 10px 0px;
  }
}

@media screen and (max-width: 650px) {
  .merchant-product-detail {
    padding: 80px 10px;
    flex-direction: column;
  }

  .merchant-product-con1 {
    width: 100%;
  }
  .merchant-product-con2 {
    width: 100%;
    padding: 10px 0px;
  }
}

.product-deletes {
  position: fixed;
  top: 0%;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 323px 420px 324px 420px;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.5);
  z-index: 101;
}

.product-deletes-card {
  width: 350px;
  height: 250px;
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.product-deletes-card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
}
.product-deletes-card > p {
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.product-deletes-card > .btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.product-deletes-card > .btn > button:nth-child(1) {
  width: 120px;
  height: 40px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}
.product-deletes-card > .btn > button:nth-child(2) {
  width: 120px;
  height: 40px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
}

@media screen and (max-width:768px) {
  .product-deletes {
    padding: 10px;
  }
}

@media screen and (max-width: 650px) {
  .product-deletes {
    padding: 10px;
  }

  .product-deletes-card {
    width: 98%;
  }
}

.edit-overlay {
  position: fixed;
  top: 0%;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 323px 420px 324px 420px;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.5);
  z-index: 101;
}

.edit-overlay > .edit-overlay-card {
  width: 750px;
  display: flex;
  padding: 25px 20px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.edit-overlay > .edit-overlay-card > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.edit-overlay > .edit-overlay-card > form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.edit-overlay > .edit-overlay-card > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.edit-overlay > .edit-overlay-card > form > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.edit-overlay > .edit-overlay-card > form > span > input {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding-left: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.edit-overlay > .edit-overlay-card > form > span > textarea {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding: 10px;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.edit-overlay > .edit-overlay-card > form > .btn {
  width: 100%;
  display: flex;
  gap: 5px;
}

.edit-overlay > .edit-overlay-card > form > .btn > button:nth-child(1) {
  width: 100%;
  height: 45px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
}
.edit-overlay > .edit-overlay-card > form > .btn > button:nth-child(2) {
  width: 100%;
  height: 45px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .edit-overlay {
    padding: 10px;
  }
}
@media screen and (max-width: 650px) {
  .edit-overlay {
    padding: 10px;
  }
}

.size-card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.size-card > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5rem;
  border: 1px solid rgba(32, 32, 32, 0.5);
  padding: 5px;
}
