* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.merchant-admin-nav {
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 100px;
  gap: 50px;
  position: fixed;
  z-index: 100;
  top: 0%;
  background: #ffcd00;
}

.merchant-admin-nav > .navlink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.merchant-admin-nav > .navlink > a {
  text-decoration: none;
  color: #121212;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}

.merchant-admin-nav > a > img {
  width: 100px;
  height: 100%;
  object-fit: cover;
}

.merchant-admin-nav > .mobile-menu {
  display: none;
}



@media screen and (max-width:768px) {
  .merchant-admin-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
 
}

@media screen and (max-width:650px) {
  .merchant-admin-nav {
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }
  .merchant-admin-nav > .navlink {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    top: 66px;
    z-index: 100;
    position: absolute;
    padding: 10px;
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
    background-color: #ffcd00;
    border-top: 1px solid #121212;
  }

  .merchant-admin-nav > .navlink.mobile-link.active {
    transform: translateX(0%);
    transition: 0.3s ease-in-out;
  }

  .merchant-admin-nav > .mobile-menu {
    display: block;
  }
}