* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super_admin_chat_container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.super_admin_chat_container > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  top: 100px;
  right: 0%;
}

.super_admin_chat_container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 83.523%;
}

.super_admin_chat_container > .header > span {
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 66px;
  border: 1px solid #121212;
  background: #fff;
  cursor: pointer;
}

.super_admin_chat_container > .header > span > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.super_admin_chat_container > .wrapper {
  width: 81.9%;
  position: fixed;
  top: 200px;
  height: 575px;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
}

.super_admin_chat_container > .wrapper > .logs-chats-container {
  width: 400px;
  min-height: 400vh;
  overflow-y: scroll;
  background: #f9f9f9;
}

.super_admin_chat_container > .wrapper > .logs-chats-container > .inner {
  position: fixed;
  z-index: 50;
  width: 390px;
  background: #f9f9f9;
}

.super_admin_chat_container > .wrapper > .logs-chats-container > .inner > span {
  display: flex;
  width: 356px;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 100px;
  border: 1px solid #bcbcbc;
  margin: 10px auto;
  font-family: Montserrat;
}

.super_admin_chat_container > .wrapper > .logs-chats-container > .inner > span > input {
  width: 100%;
  border: none;
  outline: none;
  padding-left: 10px;
  background: #f9f9f9;
  font-family: Montserrat;
  color: #202020;
}

.super_admin_chat_container > .wrapper > .logs-chats-container > .chart-card {
  width: 356px;
  background-color: white;
  margin-top: 90px;
  transform: translateX(12px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Montserrat;
  cursor: pointer;
}

.super_admin_chat_container > .wrapper > .logs-chats-container > .chart-card > .card {
  width: 356px;
  height: 60px;
  flex-shrink: 0;
  border-bottom: 1px solid #bcbcbc;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 10px;
}

.super_admin_chat_container > .wrapper > .logs-chats-container > .chart-card > .card .box {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 48px;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.super_admin_chat_container > .wrapper > .chat {
  width: 780px;
  height: 575px;
  border-left: 1px solid #e0dfd8;
  background:whitesmoke;
  position: fixed;
  overflow-y: scroll;
  right: 0%;
}

.super_admin_chat_container > .wrapper > .chat > .selected-user-header {
  width: 100%;
  height: 88px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0efe8;
  background-color: white;
  position: fixed;
  z-index: 50;
}

.super_admin_chat_container > .wrapper > .chat > .selected-user-header > .upper {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}

.message-body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding:0px 20px 10px 20px;
  margin-top: 100px;
}

.message-body > .sent-message > p {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.5rem;
  font-weight: 400;
}

.chat > .msg {
  width: 100%;
  padding: 26px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-top: 1px solid #e0dfd8;
}

.chat > .msg > textarea {
  width: 100%;
  height: 200px;
  border: none;
  outline: none;
  padding: 20px;
  font-family: Montserrat;
  /* margin-top: 20px; */
}

.chat > .msg > button {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #d0300e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  cursor: pointer;
}
