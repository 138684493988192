* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  
  .market-nav-container {
    display: flex;
    width: 260px;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: #ffcd00;
    flex-shrink: 0;
    padding: 10px 20px;
    position: fixed;
    overflow: auto;
    z-index: 100;
  }
  
  .market_admin_header {
    width: 100%;
    background-color: white;
    position: fixed;
    right: 0%;
    top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 20px 0px 277px;
    height: 80px;
    border-bottom: 1px solid #eeeeee;
    z-index: 100;
  }
  
  .market_admin_header > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
  }
  
  .market_admin_header > p > span {
    color: #284697;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  
  .market_admin_header > .side {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Montserrat;
    font-size: 18px;
  }
  
  .market_admin_header > .side > .round {
    display: flex;
    width: 45px;
    height: 45px;
    padding: 9.5px 9px 9.5px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 40px;
    border: 1px solid #e0e0e0;
  }
  
  .market-nav-container > .links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 600px;
    overflow-y: scroll;
  }
  
  .market-nav-container > .links > a {
    display: flex;
    width: 220px;
    height: 50px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    text-decoration: none;
  }
  
  .market-nav-container > .links > a > .icon {
    width: 26px;
    height: 26px;
  }
  
  
  