* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.contact-mother {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.contact-mother > .wrapper {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.contact-mother > .wrapper > h3 {
  color: #202020;
  font-family: Strike;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 35px;
}

.contact-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.contact-card-1 {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.contact-card-con {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}

.contact-card-con > span {
  width: 100%;
  display: flex;
  gap: 20px;
}

.contact-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: whitesmoke;
  gap: 20px;
}

.contact-box > span > svg {
  background: none !important;
}

.contact-box > h3 {
  color: #202020;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 600;
}

.contact-box > .fixing > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
}

.contact-box > span {
  width: 100%;
  display: flex;
  height: 59px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid #E0E0E0;
  background: #FFF;
}

.contact-box > .fixing {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.contact-box span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
}

.contact-box > .fixing > a {
  text-decoration: none;
  color: #284697;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.contact-box > .fixing > h3 {
color: #202020;
font-family: Montserrat Alternates;
font-size: 16px;
font-style: normal;
font-weight: 600;

}

.rear-contact {
  display: none;
}

.contact-address {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  margin-bottom: 70px;
}

.contact-address > span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.contact-address > span > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
}

.contact-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  padding: 20px;
  gap: 50px;
}

.contact-wrapper-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: white;
}

.contact-wrapper-card > h3 {
  color: #202020;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 600;
}

.contact-wrapper-card > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 138.462%;
}

.contact-card-2 {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.contact-form > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5rem;
}

.contact-form > form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.contact-form > form > input {
  width: 100%;
  height: 40px;
  outline: none;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin: 10px 0px;
  padding-left: 10px;
}

.contact-form > form > textarea {
  width: 100%;
  height: 146px;
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.contact-form > form > button {
  color: #d0300e;
  font-family: Montserrat Alternates;
  font-size: 15px;
  font-weight: 700;
  margin: 10px 0px;
  cursor: pointer;
  background: transparent;
  border: none;
}
.contact-form > form > button:disabled {
  color: #f36c4d;
  cursor: not-allowed;
}

@media screen and (max-width:768px) {
  .contact-mother {
    padding: 10px;
  }

  .contact-mother > .wrapper {
    width: 100%;
  }

  .contact-card-1 {
    width: 100%;
  }

  .contact-container {
    flex-direction: column;
  }

  .contact-box {
    width: 100%;
  }

  .contact-card-2 {
    width: 100%;
  }

}

@media screen and (max-width: 650px) {
  .contact-mother {
    padding: 10px;
  }

  .contact-mother > .wrapper {
    width: 100%;
  }

  .contact-card-1 {
    width: 100%;
  }

  .contact-container {
    flex-direction: column;
  }

  .contact-card-con > span {
    flex-direction: column;
  }

  .contact-box {
    width: 100%;
  }

  .contact-wrapper {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }

  .contact-wrapper-card > p {
    color: #202020;
    font-size: 12px;
    font-weight: 400;
    line-height: 138.462%;
  }

  .contact-box > .fixing > a {
    width: 100%;
    font-size: 13px;
  }

  .contact-card-2 {
    width: 100%;
    display: none;
  }

  .rear-contact {
    width: 100%;
    display: block;
  }
}
