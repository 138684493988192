* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url(/public/ProtestStrike-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat;
    src: url(/public/Montserrat-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: Montserrat Alternates;
    src: url(/public/MontserratAlternates-Regular.ttf);
  }

  
  .return-policy {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 90px 20px;
  }

  .return-policy > h3 {
    font-family: Montserrat;
    font-size: 24px;
}

.return-policy > p {
      font-family: Montserrat;
      line-height: 1.3rem;
      margin-top: 20px;
  }

  .return-policy > .return-mother {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: auto;
    margin-top: 30px;
  }

  .return-policy > .return-mother > .switch {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .return-policy > .return-mother > .switch > button {
    background: transparent;
    border: 1px solid black;
    outline: none;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
  }

  
  .return-policy > .return-mother > .switch > button:focus {
    background-color: #ffcd00;
    border: none;
    
}



  .return-policy > .return-mother > .return-card {
    width: 100%;
  }


  .return-policy > .return-mother > .return-card > .return-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    margin-top: 30px;
  }


  .return-policy > .return-mother > .return-card > .return-info > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }


  .return-policy > .return-mother > .return-card > .return-info > .wrapper > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;

  }

  .return-policy > .return-mother > .return-card > .return-info > .wrapper > span > h3 {
    font-family: Montserrat;
    color: #000;
    font-size: 16px;
  }
  .return-policy > .return-mother > .return-card > .return-info > .wrapper > span > h4 {
    font-family: Montserrat;
    color: #000;
    font-size: 14px;
  }
  .return-policy > .return-mother > .return-card > .return-info > .wrapper > span > p {
    font-family: Montserrat;
    color: #000;
    font-size: 12px;
  }

  .return-policy > .return-mother > .return-card > .non-eleigible {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }


  .return-policy > .return-mother > .return-card > .non-eleigible > h3 {
    font-family: Montserrat;
  }

  .return-policy > .return-mother > .return-card > .non-eleigible > .card {
    width: 100%;
    display: flex;
    gap: 50px;
  }


  .return-policy > .return-mother > .return-card > .non-eleigible > .card > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .return-policy > .return-mother > .return-card > .non-eleigible > .card > .wrapper > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }

  .return-policy > .return-mother > .return-card > .non-eleigible > .card > .wrapper > span > p {
    width: 50%;
    font-family: Montserrat;
    font-size: 13px;
  }

  .return-policy > .return-mother > .return-card > .return-faq {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }

  .return-policy > .return-mother > .return-card > .return-faq > h3 {
    font-family: Montserrat;
  }

  .return-policy > .return-mother > .return-card > .return-faq > .card {
    width: 100%;
    display: flex;
    gap: 50px;
  }

  .return-policy > .return-mother > .return-card > .return-faq > .card > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
  }

  .return-policy > .return-mother > .return-card > .return-faq > .card > .wrapper > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }


  .return-policy > .return-mother > .return-card > .return-faq > .card > .wrapper > span > h3 {
    font-family: Montserrat;
    font-size: 12px;
  }
  .return-policy > .return-mother > .return-card > .return-faq > .card > .wrapper > span > p {
    font-family: Montserrat;
    font-size: 12px;
  }

  .return-policy > .return-mother > .return-card > .return-requirement {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }


  .return-policy > .return-mother > .return-card > .return-requirement .card {
    width: 100%;
  }

  .return-policy > .return-mother > .return-card > .return-requirement .card > table > tr > th {
    font-family: Montserrat;
    font-size: 10px;
  }


  @media screen and (max-width:896px) {
    .return-policy {
        padding:160px 10px 20px 10px;
    }

    .return-policy > h3 {
        text-align: center;
    }

    .return-policy > .return-mother {
        width: 100%;
    }

    .return-policy > .return-mother > .switch {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2,2fr);
    }

    .return-policy > .return-mother > .switch > button {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        border: 1px solid black;
    }
   
    .return-policy > .return-mother > .return-card > .return-info {
        gap: 20px;
    }

    .return-policy > .return-mother > .return-card > .non-eleigible > .card {
        gap: 20px;
    }

    .return-policy > .return-mother > .return-card > .non-eleigible > .card > .wrapper > span {
        gap: 15px;
    }

    .return-policy > .return-mother > .return-card > .non-eleigible > .card > .wrapper > span > p {
        width: 100%;
    }


  .return-policy > .return-mother > .return-card > .return-faq > .card {
    flex-direction: column;
    gap: 20px;
  }

  .return-policy > .return-mother > .return-card > .return-requirement .card {
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow-x: scroll;
  }

  }


