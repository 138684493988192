* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Montserrat Alternates;
  src: url(/public/MontserratAlternates-Regular.ttf);
}

.support-setting-container {
  padding-top: 100px;
  width: 81.9%;
  min-height: 100vh;
  margin-left: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.support-setting-container > .header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.support-setting-container > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.setting-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}

.setting-wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting-wrapper > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.setting-wrapper > span > a {
  color: #3156db;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.logout-button {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  margin-bottom: 20px;
}

.logout-button > button {
  color: #ab0808;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  background: transparent;
}
