* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url(/public/ProtestStrike-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Montserrat-VariableFont_wght.ttf);
}

.token-card {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 50px 0px;
}

.token-card > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}
.token-card > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.token-card > form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

.token-card > form > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.token-card > form > .token-inputs > span > input {
  width: 115px;
  height: 115px;
  margin: 5px;
  text-align: center;
  font-size: 30px;
  font-family: Montserrat;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.token-card > form > button {
  width: 520px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  background: #d0300e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  margin-top: 25px;
}

.token-card > form > p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
}


.token-card > form > .resend-btn > button  {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
  background: transparent;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #d0300e;
  font-weight: 600;
  cursor: pointer;
}



.token-card > form > .resend-btn > button > p {
  font-family: Montserrat;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}

.otp-requset {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0px;
}

.otp-requset > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.otp-requset > form {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.otp-requset > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.otp-requset > form > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.otp-requset > form > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  padding-left: 10px;
}

.otp-requset > form > button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin: 5px 0px;
  border-radius: 80px;
  background: #d0300e;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 700;
}

.sign-up-form > form > button:disabled {
  background-color: #f36c4d;
  cursor: not-allowed;
}

.resend-request-card {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 50px 0px;
}

.resend-request-card > h3 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
}

.resend-request-card > form {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.resend-request-card > form > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.resend-request-card > form > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.resend-request-card > form > span > input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  padding-left: 10px;
}

.resend-request-card > form > button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  margin: 5px 0px;
  border-radius: 80px;
  background: #d0300e;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat Alternates;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}

.sign-up-form > form > button:disabled {
  background-color: #f36c4d;
  cursor: not-allowed;
}

@media screen and (max-width: 650px) {
  .token-card {
    padding: 10px;
  }

  .token-card > form > .token-inputs > span > input {
    max-width: 50px;
    max-height: 50px;
  }

  .token-card > form > button {
    width: 100%;
  }

  .otp-requset {
    padding: 10px;
  }

  .otp-requset > form {
    width: 100%;
  }

  .resend-request-card {
    padding: 10px;
  }

  .resend-request-card > form {
    width: 100%;
  }
}

.one-time-verification {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 20px 10px;
}

.one-time-verification > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.one-time-verification > form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

.one-time-verification > form > span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  background-color: pink;
}

.one-time-verification > form > .token-inputs > span > input {
  width: 115px;
  height: 115px;
  margin: 5px;
  text-align: center;
  font-size: 30px;
  font-family: Montserrat;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.one-time-verification > form > button {
  width: 520px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  background: #d0300e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  margin-top: 25px;
}


.token-overlay {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top:80px;
  left: 0%;
  background-color: white;
}

.token-overlay > .card-1 {
  width: 30%;
  height: 100%;
}

.token-overlay > .card-1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.token-overlay > .card-2 {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
}

.token-overlay > .card-2 > h3 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  color: #284697;
}


.token-overlay > .card-2 > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding:20px;
  gap: 20px;
  background: #fff;
  margin-top: 40px;
  border-radius: 30px;
  box-shadow: 0px 9px 34px 0px rgba(0, 0, 0, 0.07);
}

.token-overlay > .card-2 > .wrapper > p {
  font-family: Montserrat;
}

.token-overlay > .card-2 > .wrapper > h4 {
  font-family: Montserrat;
  font-weight: 600;
}

.token-overlay > .card-2 > .wrapper > .btn > button {
  background: #d0300e;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: Montserrat;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
}

.token-overlay > .card-2 > .wrapper > .btn > button:disabled {
  cursor: not-allowed;
}

.token-overlay > .card-2 > .wrapper > button {
  border: 1px solid #E0E0E0;
  padding: 10px;
  margin-top: 10px;
  font-family: Montserrat;
  gap: 5px;
}

.one-time-verification > .card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.one-time-verification > .card > p {
  font-family: Montserrat;
}

.one-time-verification > .card > button {
  border: none;
  background: transparent;
  outline: none;
  font-family: Montserrat;
  color: #d0300e;
  cursor: pointer;
  font-size: 16px;
}

.one-time-verification > form > button:disabled {
  cursor: not-allowed;
}
.one-time-verification > .card > button:disabled {
  cursor: not-allowed;
}

@media screen and (max-width:896px) {
  .one-time-verification > form > .token-inputs > span > input {
    max-width: 40px;
    max-height: 50px;
  }

  .one-time-verification > form > button {
    width: 100%;
  }

  .one-time-verification > .card {
    flex-direction: column;
  }

  .one-time-verification > .card > p {
    text-align: center;
  }

  .token-overlay > .card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .token-overlay > .card > button {
    width: 100%;
  }

  .token-overlay > .card > p {
    text-align: center;
  }

  .token-overlay > .card-1 {
    display: none;
  }
  .token-overlay > .card-2 {
    width: 100%;
    padding: 10px;
  }

  .token-overlay > .card-2 > .wrapper {
    padding: 10px;
    border-radius: 10px;
  }
  
}
