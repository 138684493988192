* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.super_admin_dispatches_container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.super_admin_dispatches_container > .header {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.super_admin_dispatches_container > .header > .in {
  display: flex;
  gap: 10px;
}

.super_admin_dispatches_container > .header > .in > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}
.super_admin_dispatches_container > .header > .in > .icon {
  cursor: pointer;
}

.super_admin_dispatches_container > .header > span {
  display: flex;
  width: 280px;
  height: 44px;
  padding: 10px;
  gap: 10px;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
}

.super_admin_dispatches_container > .header > span > input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.super_admin_dispatches_container > .dispatch-table {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.super_admin_dispatches_container > .dispatch-table > table {
  width: 100%;
}

.super_admin_dispatches_container > .dispatch-table > table > thead > tr > th {
  text-align: start;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 3px;
}

.super_admin_dispatches_container > .dispatch-table > table > tbody > tr > td {
  padding: 10px 3px;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: start;
}

.super_admin_dispatches_container
  > .dispatch-table
  > table
  > tbody
  > tr
  > td
  > a {
  color: #d0300e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
}
