* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.logistic-active-dispatch {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.logistic-active-dispatch > .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 20px;
  align-items: center;
  gap: 358px;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.logistic-active-dispatch > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.logistic-active-dispatch > .header > span {
  display: flex;
  width: 280px;
  height: 44px;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-radius: 30px;
  border: 1px solid rgba(32, 32, 32, 0.6);
  gap: 10px;
}

.logistic-active-dispatch > .header span > input {
  width: 100%;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  background: #f9f9f9;
}

.logistic-active-dispatch > .dispatch-table {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  height: 600px;
  overflow-y: scroll;
  flex-direction: column;
}

.logistic-active-dispatch > .dispatch-table > table {
  width: 100%;
}

.logistic-active-dispatch > .dispatch-table > table > thead > tr > th {
  text-align: start;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  padding: 16px;
}

.logistic-active-dispatch > .dispatch-table > table > tbody > tr > td {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  text-align: start;
  height: 60px;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
}
