* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  
  .market_place_products {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f9f9f9;
  }
  
  .market_place_products > .header {
    width: 83.4%;
    padding: 20px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 80px;
    right: 0%;
    background-color: white;
  }
  
  
  .market_place_products > .header > h1 {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }



  .market_place_products > .header  > span {
    display: flex;
    width: 280px;
    height: 44px;
    padding: 11px;
    align-items: center;
    border-radius: 30px;
    gap: 10px;
    border: 1px solid rgba(32, 32, 32, 0.6);
  }
  
  .market_place_products > .header  > span input {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    color: rgba(32, 32, 32, 0.7);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;

  }


  .market_place_products > .products_table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
  }


  .products_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    z-index: 101;
    right: 0%;
    background-color: rgba(32, 32, 32, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .products_overlay > .card {
    padding: 50px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .products_overlay > .card > .btn {
    display: grid;
    grid-template-columns: repeat(2,2fr);
    gap: 15px;
  }

  .products_overlay > .card > .btn > button {
    display: flex;
    width: 170px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }

  .products_overlay > .card > button {
    display: flex;
    width: 170px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgb(246, 66, 66);
    background: #fefefe;
    background-color: rgb(246, 66, 66);
    font-family: Montserrat;
    color: white;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }