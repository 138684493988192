* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Strike;
    src: url("../../../public/ProtestStrike-Regular.ttf");
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }



.super_admin-create-investor {
    padding: 100px 0px 50px 0px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.super_admin-create-investor > .header {
  width: 100%;
  height: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}


.super_admin-create-investor  > .header > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}

.super_admin-create-investor  > form {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
  gap: 40px;
  padding:20px;
}

.super_admin-create-investor  > form > .suscriber-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
}

.super_admin-create-investor > form > .passport {
  width: 150px;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(45, 69, 150, 0.8);
  background: rgba(233, 244, 255, 0.7);
  position: relative;
  height: 150px;
  overflow: hidden;
}

.super_admin-create-investor  > form .passport input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.super_admin-create-investor  > form .passport-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.super_admin-create-investor  > form > .passport > svg {
  font-size: 40px;
}

.super_admin-create-investor  > form > .passport > h3 {
  font-family: Montserrat;
  font-size: 11px;
}
.super_admin-create-investor > form > .passport > p {
  font-family: Montserrat;
  font-size: 9px;
}

.super_admin-create-investor  > form > .suscriber-card > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.super_admin-create-investor  > form > .suscriber-card > span > label {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}
.super_admin-create-investor  > form > .suscriber-card > span > input {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  padding-left: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  outline: none;
}

.super_admin-create-investor  > form > .suscriber-card > button {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 80px;
  background:#d0310e;
  cursor: pointer;
  color: #fff;
  font-family: Montserrat Alternates;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
}

