@font-face {
  font-family: Montserrat;
  src: url("../../public//Montserrat-VariableFont_wght.ttf");
}

.image-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-icon > p {
  color: #284697;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
}
