* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  

.all-users-container {
    padding-top: 100px;
    width: 81.9%;
    margin-left: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f9f9f9;
  }
  
  .all-users-container > .header {
    width: 100%;
    display: flex;
    padding:20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(32, 32, 32, 0.4);
    background-color: white;
  }
  
  .all-users-container > .header > h1 {
    color: #284697;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 83.523%;
  }

  .all-users-container > .header > input {
    width: 300px;
    height: 50px;
    border: 1px solid black;
    background: transparent;
    outline: none;
    border-radius: 80px;
    padding: 10px;
    font-family: Montserrat;

  }


  .all-users-container > .user-table {
    margin-top: 20px;
  }

  .all-users-container
  > .user-table 
  > tbody
  > tr
  > td
  > .action {
  display: flex;
  align-items: center;
  gap: 20px;
}

.all-users-container
> .user-table 
  > tbody
  > tr
  > td
  > .action
  > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}



  .user-block-over-container {
    position: fixed;
    height: 100%;
    top: 0%;
    right: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(32, 32, 32, 0.7);
    z-index: 100;
  }
  
  .user-block-over-container > form {
    width: 45%;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .user-block-over-container > form > p {
    color: #202020;
    font-size: 16px;
    font-family: Montserrat;
  }
  
  .user-block-over-container > form > label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-family: Montserrat;
  }
  
  .user-block-over-container > form > label > input {
    display: flex;
    width: 100%;
    height: 42px;
    align-items: center;
    gap: 10px;
    border: 1px solid #e0e0e0;
    padding-left: 20px;
    outline: none;
    font-family: Montserrat;
  }
  
  .user-block-over-container > form > .btn {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .user-block-over-container > form > .btn > button:nth-child(1) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .user-block-over-container > form > .btn > button:nth-child(2) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    background: #d0310e;
    border: none;
    cursor: pointer;
  }
  
  .user-unblock-over-container {
    position: fixed;
    height: 100%;
    top: 0%;
    right: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(32, 32, 32, 0.7);
    z-index: 100;
  }
  .user-unblock-over-container > .inner {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .user-unblock-over-container > .inner > p {
    color: #202020;
    font-size: 16px;
    font-family: Montserrat;
  }
  
  .user-unblock-over-container > .inner > .btn {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .user-unblock-over-container > .inner > .btn > button:nth-child(1) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .user-unblock-over-container > .inner > .btn > button:nth-child(2) {
    display: flex;
    width: 120px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    background: #d0310e;
    border: none;
    cursor: pointer;
  }
  
  button:disabled {
    cursor: not-allowed;
  }
  
.user-delete-over-container {
    position: fixed;
    height: 100%;
    top: 0%;
    right: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(32, 32, 32, 0.7);
    z-index: 100;
  }
  
  .user-delete-over-container> .delete-card {
    width: 30%;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .user-delete-over-container > .delete-card > span {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 800;
  }
  
  .user-delete-over-container > .delete-card > p {
    color: #202020;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 118.243%;
  }
  
  .user-delete-over-container > .delete-card > .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .user-delete-over-container > .delete-card > .btn > button:nth-child(1) {
    width: 120px;
    height: 40px;
    text-align: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgba(32, 32, 32, 0.5);
    background: #fefefe;
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .user-delete-over-container > .delete-card > .btn > button:nth-child(2) {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background: #d0310e;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    border: none;
    cursor: pointer;
  }
  

