* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Strike;
  src: url("../../../public/ProtestStrike-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

.markplace-admin-order-container {
  padding-top: 100px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f9f9f9;
}

.markplace-admin-order-container > .header {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.markplace-admin-order-container > .header > h1 {
  color: #284697;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 83.523%;
}
